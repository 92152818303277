import React from 'react'



/*Component o Page*/
function Empty ({text}){
return(
<div>{text?text:'404 Ruta no encontrada'}</div>

)
    
}
export default Empty