import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DocumentPackingListAction,
        DocumentLocationAction,
        StorePackageLocationAction,
        ChangeStatusToDeliveryAction} from "../../redux/actions/documentPacking.actions";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import logo from "../../img/logo.png";
import { getName, close, authenticateUser, validateToken } from "../../utils/Commons.js";
import Navbar from "../../components/navbar"

/*Component o Page*/
function PackageLocation({ DocumentPackingListAction,
    response, responseLocations, DocumentLocationAction,
    StorePackageLocationAction,
    ChangeStatusToDeliveryAction, saveLocatioResponse }) {
  const [detailDocument, setDetailDocument] = useState();
  const [total, setTotal] = useState([]);
  const [locations, setLocations] = useState([]);
  const [preparationState, setPreparationState] = useState('packing')
  const [packageLocations, setPackageLocations] = useState({});
  
  const { id } = useParams();
  let history = useHistory();

  useEffect(() => {
    DocumentPackingListAction({ id });
    DocumentLocationAction({ id });
  }, []);


  // If user is already login in, verify if the user has permission in the Packing module.
  // If user is not loged in, validates its token and if user has permission in the Packing module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Packing')
    }else{
      validateToken(localStorage.getItem("token"),'Packing')
    }
  })

  useEffect(() => {
    if(!responseLocations || !responseLocations.attributes || !responseLocations.attributes.locations){
      return;
    }else{
      setLocations(responseLocations.attributes.locations);
    }
  }, [responseLocations]);

  useEffect(() => {
    if(!saveLocatioResponse || !saveLocatioResponse.attributes) {
      return
    }else {
      setPreparationState(saveLocatioResponse.attributes.aasm_state)
    }
  }, [saveLocatioResponse])

  useEffect(() => {
    if (!response || !response.attributes || !response.attributes.packages) {
      return;
    } else {
      setTotal(response.attributes);
      setDetailDocument(response.attributes.packages);
      setPreparationState(response.attributes.aasm_state)
    }
  }, [response]);

  useEffect(() => {
    if(preparationState === 'packed' ){ history.push('/pendingPackingList')}
  }, [preparationState])

  const onBack = () => {
    history.goBack();
  };

  const saveLocation = () => {
    storePackageLocation();
  }
  
  const toLogin = () => {
    localStorage.setItem("module", "Packing");
    history.push("/login");
  };

  const storePackageLocation = () => {

    if(Object.keys(packageLocations).length < Object.keys(detailDocument).length ) {return}

    Object.entries(packageLocations).forEach(([key, value]) => {
      let payload = {
        "data": {
          "id": id,
          "attributes": {
            "package_number": key,
            "location": value
          }
        }
      }
      StorePackageLocationAction(payload);
    });
    ChangeStatusToDeliveryAction(id);
  }

  // Set All Locations
  // 
  // Set the selected location to all packages
  // 
  // @returns [Null]
  const setAllLocations = (location) => {
    let newLocations = {}
    detailDocument.forEach((pack) => {
      newLocations[pack.package_number] = location
    })
    setPackageLocations(newLocations)
  }

  const packingListTable = (<table className="table table-location">
    <tbody>
      <tr>
        <td>Bulto</td>
        <td>Código de Barra</td>
        <td>Ubicación</td>
      </tr>

      {detailDocument
        ? detailDocument.map((pack, index) => {
            
              return (
                <tr key={pack.package_number}>
                  <td>{pack.package_number}</td>
                  <td>{pack.label_number}</td>
                  <td>
                    <select id={`PreparationLocation-${pack.package_number}`} className="form-control" value={packageLocations[pack.package_number]} onChange={e => setPackageLocations({...packageLocations,[pack.package_number]: e.currentTarget.value})}>
                      <option selected value={null}>Seleccione una ubicación...</option>
                      {locations ? (locations.map((location) => {
                        const option = <option value={location}>{location}</option>
                        return option;
                      })) : null}
                    </select>
                  </td>
                </tr>
              );
          })
        : null}
    </tbody>
  </table>
  )

  
  return (
    <div>
      <Navbar module="Packing" title="Packing" navBarName='Registro Ubicación'/>
      <Container className="body-height">
        <div className="row containerPackingButtons">
          <div className="col-12 mr-md-auto">
            <div className="row">
              <div className="col-2">
                <button className="btn btn-outline-primary btn-block" onClick={() => {onBack();}}>
                  Volver
                </button>
              </div>
              <div className="col-10 text-center">
                <h2>Registrar Ubicación</h2>
              </div>
            </div>
          </div>
        </div>
        
        <div ref={(el) => (PackageLocation = el)}>
          <Row>
            <Col sm={8}>
              <div style={{ margin: "20px" }}>
                <div>Nro. De Documento: {total ? total.document_number : null}</div>
                <div>Tipo de Documento: {total ? total.document_type : null}</div>
                <div>Razón Social: {total ? total.business_reason : null}</div>
                <div>Tipo de Despacho: {total ? total.delivery_type : null}</div>
                <div>Bodega: {total ? total.warehouse : 'Ninguna'}</div>
              </div>
            </Col>
            <Col sm={4} className='mt-auto'>
              <div className='mb-5'>
                <h4>Asignar todos los bultos a una ubicación:</h4>
                <select id={`AllSelector`} className="form-control" onChange={e => setAllLocations(e.currentTarget.value)}>
                      <option selected value={null}>Seleccione una ubicación...</option>
                      {locations ? (locations.map((location) => {
                        const option = <option value={location}>{location}</option>
                        return option;
                      })) : null}
                </select>
              </div>
            </Col>
            <Col className="divCenter" sm={12}>
              {!detailDocument ? 'Cargando' : packingListTable}
            </Col>
            <Col sm={12}>
              <div className='d-flex justify-content-end'>
                {!detailDocument ? null : <button onClick={() => { saveLocation()}} className="btn btn-primary text-right"> Guardar Ubicación </button>}
                
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

/*Methodo*/
const mapStateToProps = (state, props) => {
  return {
    response: state.documentPackingReducers.documentPackingListResponse.res,
    responseLocations: state.documentPackingReducers.documentLocationResponse.res,
    saveLocatioResponse: state.documentPackingReducers.documentChangeStatusToDeliveryResponse.res
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentPackingListAction,DocumentLocationAction,
  StorePackageLocationAction,
  ChangeStatusToDeliveryAction,
})(PackageLocation);
