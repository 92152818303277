import { call, put, takeEvery } from "redux-saga/effects";
import Constants from "../../utils/Constants";
import DocumentService from "../../API/services/DocumentAdminService";

function* documentList(action) {
  try {
    yield put({
      type: Constants.StartFetchDocumentList,
      payload: { 
        fetch: true
      }
    });
    
    const data = yield call(
      DocumentService.documentList,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentList,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentList,
      payload: { data, fetch: false }
    });

  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentList,
      payload: { data: e.message, fetch: false }
    });
  }
}

// Pending Reception List
// 
// @parameter action payload { document_type: selected_type}
// @note:
// Saga that triggers the events to change the state and change the view
// First, start the process sending fetch true to show the loading screen
// Then, call the service with the payload to get the document list by the filter
// If the service returns a error, send the error to the state
// If the service returns a success, send the success response to the state
// After that, send the fetch false to hide the loading screen
// 
// @returns Null
function* pendingReceptionList(action) {
  try {
    yield put({
      type: Constants.StartFetchPendingReceptionList,
      payload: { 
        fetch: true
      }
    });
    const data = yield call(
      DocumentService.pendingReceptionList,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchPendingReceptionList,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessFetchPendingReceptionList,
      payload: { data, fetch: false }
    });

  } catch (e) {
    yield put({
      type: Constants.FailFetchPendingReceptionList,
      payload: { data: e.message, fetch: false }
    });
  }
}

// Pending Reception Prioritization
// 
// @parameter action payload { id: document_id, priority: new_priority }
// @note:
// Saga that triggers the events to change the state and change the view
// First, start the process sending fetch true to show the loading screen
// Then, call the service with the payload to change the priority of the document
// If the service returns a error, send the error to the state
// If the service returns a success, send the success response to the state
// After that, send the fetch false to hide the loading screen
// 
// @returns Null
function* pendingReceptionPrioritization(action) {
  try {
    yield put({
      type: Constants.StartFetchPendingReceptionList,
      payload: {
        fetch: true
      }
    });

    const data = yield call(
      DocumentService.pendingReceptionPrioritization,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchPendingReceptionPrioritization,
        payload: { data, fetch: true }
      });
    }
    yield put({
      type: Constants.SuccessFetchPendingReceptionList,
      payload: { data, fetch: false }
    });

  } catch (e) {
    yield put({
      type: Constants.FailFetchPendingReceptionPrioritization,
      payload: e.message,
    });
  }
}

function* syncStock(action) {
  try {
    const data = yield call(DocumentService.syncStock, action.payload);
    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchSyncStockAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchSyncStockAction,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchSyncStockAction,
      payload: e.message,
    });
  }
}

function* documentFilter(action) {
  try {
    yield put({
      type: Constants.StartFetchDocumentFilter,
      payload: {
        fetch: true
      }
    });
    const data = yield call(DocumentService.documentFilter, action.payload);
    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentFilter,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentFilter,
      payload: { data, fetch: false }
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentFilter,
      payload: { data: e.message, fetch: false }
    });
  }
}

function* takenDocumentCount(action) {
  try {
    const data = yield call(DocumentService.takenDocumentCount, action.payload);

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchTakenDocumentCountAction,
        payload: data,
      });
    }

    yield put({
      type: Constants.SuccessFetchTakenDocumentCountAction,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchTakenDocumentCountAction,
      payload: e.message,
    });
  }
}

function* documentPrioritization(action) {
  try {
    yield put({
      type: Constants.StartFetchDocumentPrioritization,
      payload: {
        fetch: true
      }
    });

    const data = yield call(
      DocumentService.documentPrioritization,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentPrioritization,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentPrioritization,
      payload: { data, fetch: false }
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentPrioritization,
      payload: e.message,
    });
  }
}

function* releaseTakenDocument(action) {
  try {
    const data = yield call(
      DocumentService.releaseTakenDocuments,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchReleaseTakenDocumentAction,
        payload: data,
      });
    }

    yield put({
      type: Constants.SuccessFetchReleaseTakenDocumentAction,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchReleaseTakenDocumentAction,
      payload: e.message,
    });
  }
}

function* unselectTakenDocument(action) {
  try {

    yield put({
      type: Constants.StartFetchDocumentPrioritization,
      payload: {
        fetch: true
      }
    });

    const data2 = yield call(DocumentService.takenDocumentCount, action.payload);

    const data = yield call(
      DocumentService.unselectTakenDocuments,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentPrioritization,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentPrioritization,
      payload: { data, fetch: false }
    });

    if (data2.status !== 200) {
      return yield put({
        type: Constants.FailFetchTakenDocumentCountAction,
        payload: data2,
      });
    }

    yield put({
      type: Constants.SuccessFetchTakenDocumentCountAction,
      payload: data2,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentPrioritization,
      payload: e.message,
    });
  }
}

export function* documentAdminSaga() {
  yield takeEvery(Constants.documentListAction, documentList);
  yield takeEvery(Constants.pendingReceptionListAction, pendingReceptionList);
  yield takeEvery(Constants.documentFilterAction, documentFilter);
  yield takeEvery(Constants.syncStockAction, syncStock);
  yield takeEvery(Constants.takenDocumentCountAction, takenDocumentCount);
  yield takeEvery(Constants.releaseTakenDocumentAction, releaseTakenDocument);
  yield takeEvery(Constants.unselectTakenDocumentAction, unselectTakenDocument);
  yield takeEvery(Constants.documentPrioritizationAction,documentPrioritization);
  yield takeEvery(Constants.pendingReceptionPrioritizationAction, pendingReceptionPrioritization);
}
