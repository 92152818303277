import Constants from "../../utils/Constants";

export const ProductAction = (payload) => {
  return {
    type: Constants.productAction,
    payload,
  };
};

export const ChangeProductStatusAction = (payload) => {
  return {
    type: Constants.changeProductStatusAction,
    payload,
  };
};

export const SaveProductAction = (payload) => {
  return {
    type: Constants.saveProductAction,
    payload,
  };
};
