import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DocumentPendingAction,
  PendingDocumentSelectionAction,
} from "../../redux/actions/documentPicking.actions";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { getName, close } from "../../utils/Commons.js"
import logo from "../../img/logo.png";
import Navbar from "../../components/navbar"

/*Component o Page*/
function DocumentPending({
  DocumentPendingAction,
  PendingDocumentSelectionAction,
  response,
}) {
  const [documents, setDocuments] = useState([]);
  const [update, setUpdate] = useState(false);
  let history = useHistory();

  const toLogin = () => {
    localStorage.setItem("module", "Picking");
    history.push("/login");
  };

  useEffect(() => {
    DocumentPendingAction();
  }, []);

  useEffect(() => {
    if (update) {
      DocumentPendingAction();
      setUpdate(false);
    }

    setDocuments(response);
  }, [response]);

  const handleClick = (id) => {
    PendingDocumentSelectionAction(id);
    history.push(`/document/${id}`);
  };

  return (
    
      <Container>
        <Navbar title="Picking" module="Picking"/>
        <Row>
          <Col sm={12}>
            <div style={{ textAlign: "center" }}>
              <h2>Documentos Pendientes de Preparar</h2>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <div className="listData">
              <table className="tableListGrapper">
                <thead>
                  <tr>
                    <td>Doc. Origen</td>
                    <td>Cant. Líneas</td>
                    <td>Cant. Prod.</td>
                  </tr>
                </thead>
                <tbody>
                  {response
                    ? response.map((item, index) => {
                        return (
                          <tr
                            key={item.id}
                            onClick={() => handleClick(item.attributes.id)}
                          >
                            <td>{item.attributes.document_number}</td>
                            <td>{item.attributes.total_lines}</td>
                            <td>{item.attributes.total_products}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    
  );
}

/*Methodo*/
const mapStateToProps = (state, props) => {
  return {
    response: state.documentPickingReducers.documentPendingResponse.res,
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentPendingAction,
  PendingDocumentSelectionAction,
})(DocumentPending);
