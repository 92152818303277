import Constants from "../../utils/Constants";

export const DocumentToDeliverySearchAction = (payload) => {
  return {
    type: Constants.documentToDeliverySearchAction,
    payload,
  };
};

export const PreparationTransferSearchAction = (payload) => {
  return {
    type: Constants.preparationFilterAction,
    payload,
  }
}

export const PreparationMovementSearchAction = (payload) => {
  return {
    type: Constants.preparationMovementSearchAction,
    payload,
  }
}

export const DocumentCompletedSearch = (payload) => {
  return {
    type: Constants.documentCompletedSearch,
    payload,
  };
};

export const DocumentPackingComplete = (payload) => {
  return {
    type: Constants.documentPackingComplete,
    payload,
  };
};

export const DocumentDeliveryCompleteAction = (payload) => {
  return {
    type: Constants.documentDeliveryCompleteAction,
    payload
  }
}