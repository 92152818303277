import Constants from "./../../utils/Constants";

const loginState = {
  LoginResponse: {},
};

export default (state = loginState, action) => {
  switch (action.type) {
    case Constants.SuccessFetchLogin:
      return { ...state, LoginResponse: action.payload };

    case Constants.FailFetchLogin:
      return { ...state, LoginResponse: action.payload };

    default:
      return state;
  }
};
