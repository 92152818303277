import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { DocumentLabelAction } from "../../redux/actions/documentPacking.actions";
import Barcode from "react-barcode";
import logo from "../../img/logo_label.png";
import Moment from "moment";
import { getName, close, authenticateUser, validateToken } from "../../utils/Commons.js"
import Navbar from "../../components/navbar"
import { handlePrint } from "../../helpers/handlePrint";
import QRCode from 'react-qr-code';
/*Component o Page*/

function Label({ response, DocumentLabelAction }) {
  const { id } = useParams();
  let history = useHistory();
  const [dataLabel, setDataLabel] = useState([]);
  const [detailDocument, setDetailDocument] = useState([]);

  useEffect(() => {
    DocumentLabelAction({ id });
  }, []);

  // If user is already login in, verify if the user has permission in the Packing module.
  // If user is not loged in, validates its token and if user has permission in the Packing module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Packing')
    }else{
      validateToken(localStorage.getItem("token"),'Packing')
    }
  })


  useEffect(() => {
    if (!response || !response.attributes || !response.attributes.labels) {
      return;
    }
    setDetailDocument(response.attributes.labels);
  }, [response]);

  function getInitials(fullName) {
    if (fullName === null ) {return }
    const namesArray = fullName.split(" ");
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
    else
      return `${namesArray[0].charAt(0)}${namesArray[
        namesArray.length - 1
      ].charAt(0)}`;
  }
  function goPackingList() {
    history.push(`/documentPackingList/${id}`);
  }

  const onBack = () => {
    history.push(`/documentPacking/${id}`);
  };

  const toLogin = () => {
    localStorage.setItem("module", "Packing");
    history.push("/login");
  };

  return (
    <div>
      <Navbar module="Packing" title="Packing" navBarName='Etiquetado'/>
      <Container className='body-height containerMargin label-container'>
        <div className="hidden-print">
        </div>

        <div className="row hidden-print containerPackingButtons">
          <div className="col-12">
            <div className="row">
              <div className="col-2">
                <button className="btn btn-block btn-outline-primary" onClick={() => {onBack();}}>
                  Volver
                </button>
              </div>
              <div className="col-2">
                <button className="btn btn-block btn-primary"  onClick={(e) => {handlePrint(e)}}>
                  Imprimir
                </button>
              </div>
              <div className="col-5 text-center">
                <h2>Etiqueta</h2>
              </div>
                  
              <div className="col-3">
                <button className="btn btn-block btn-primary"  onClick={() => {goPackingList(response.attributes.id);}}>
                Ver packing list
                </button>
              </div>
            </div>
          </div>
        </div>

        <Row class='printView'>
          <Col class='card-container' sm={12}>
            {detailDocument
              ? detailDocument.map((itemPackage, indexPackage) => {
                  return (
                    <div className="card-label">
                      <div className="containerBarcode">
                        <div className="barcode">
                          <Barcode
                            format="CODE128"
                            renderer="img"
                            value={itemPackage.label_number}
                          />
                        </div>
                      </div>
                      <div className="containerText">
                        <div
                          className=""
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '10px',
                          }}
                        >
                          <div className="cardPack">
                            <div className="package-number">
                              Bulto
                              <br />
                              <div className="package-info mr-4">
                                {itemPackage.weight
                                  ? `${parseFloat(
                                      itemPackage.weight
                                    ).toFixed(2)} kg`
                                  : '0 kg'}
                                <br />
                                {itemPackage.packed_at
                                  ? Moment(
                                      itemPackage.packed_at
                                    ).format('DD-MM-yyyy')
                                  : null}
                              </div>
                            </div>
                            <div className="package-number">
                              {itemPackage.package_number} de{' '}
                              {itemPackage.total_packages}
                              <br />
                              <div className="package-info">
                                {itemPackage.volume
                                  ? `${parseFloat(
                                      itemPackage.volume
                                    ).toFixed(2)} M3`
                                  : '0 M3'}
                                <br />
                                {itemPackage.packed_at
                                  ? Moment(
                                      itemPackage.packed_at
                                    ).format('HH:mm:ss')
                                  : null}
                              </div>
                            </div>
                          </div>
                          <div>
                            <QRCode
                              size={256}
                              style={{
                                height: '80px',
                                maxWidth: '100%',
                                width: '100%',
                              }}
                              value={
                                window.location.origin.toString() +
                                `/documentPublicPackingListReadOnly/${id}`
                              }
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        </div>
                        <div className="cardText">
                          <span className="card-bold">
                            {' '}
                            {itemPackage.delivery_type}
                          </span>
                          <br />
                          <span className="card-bold">
                            {' '}
                            {`${
                              itemPackage.document_type
                            } #${parseInt(
                              itemPackage.document_number
                            )}`}
                          </span>
                          <br />
                          <span>{`${itemPackage.customer_name} - ${itemPackage.customer_email} - ${itemPackage.customer_phone} - ${itemPackage.delivery_address} - ${itemPackage.delivery_commune} - ${itemPackage.delivery_region}`}</span>
                          <br />
                          <span>{`${itemPackage.bussiness_reason} - ${itemPackage.business_tax_ref}`}</span>
                          <br />
                          <div className="cardBottomText">
                            <label>
                              Vend: {getInitials(itemPackage.seller)}
                            </label>
                            <label>
                              {' '}
                              Prep: {getInitials(itemPackage.picker)}
                            </label>
                            <label>
                              Emb: {getInitials(itemPackage.packer)}
                            </label>
                          </div>{' '}
                        </div>
                      </div>
                      <div className="containerLogo">
                        <img src={logo} alt="hbt logo" />
                        <label>hbt.cl</label>
                      </div>
                    </div>
                  );
                })
              : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    response: state.documentPackingReducers.documentLabelResponse.res,
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentLabelAction,
})(Label);
