import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DocumentPublicPackingListAction,
} from '../../redux/actions/documentPacking.actions';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import Navbar from '../../components/navbar';
import {
  authenticateUser,
  validateToken,
} from '../../utils/Commons.js';
import { handlePrint } from '../../helpers/handlePrint';

/*Component o Page*/
function PublicPackingListReadOnly({
  DocumentPublicPackingListAction,
  response,
}) {
  const [detailDocument, setDetailDocument] = useState();
  const [total, setTotal] = useState([]);

  const { id } = useParams();
  let history = useHistory();

  function goLabel() {
    history.push(`/labelReadOnly/${id}`);
  }

  // If user is already login in, verify if the user has permission in the Packing module.
  // If user is not loged in, validates its token and if user has permission in the Packing module.
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      authenticateUser('Packing');
    } else {
      validateToken(localStorage.getItem('token'), 'Packing');
    }
  });

  useEffect(() => {
    DocumentPublicPackingListAction({ id });
  }, []);

  useEffect(() => {
    if (
      !response ||
      !response.attributes ||
      !response.attributes.packages
    ) {
      return;
    } else {
      setTotal(response.attributes);
      setDetailDocument(response.attributes.packages);
    }
  }, [response]);

  const onBack = () => {
    history.push(`/documentPackingReadOnly/${id}`);
  };

  const toLocation = () => {
    history.push(`/packageLocation/${id}`);
  };

  console.log(window.location);

  return (
    <div>
      <Navbar
        module="Packing"
        title="Packing"
        navBarName="Lista packing"
      />
      <Container className="body-height">
        <div className="hidden-print">
          <div className="row hidden-print containerPackingButtons">
            <div className="col-12 mr-md-auto">
              <div className="row">
                <div className="col-2">
                  <button
                    className="btn btn-block btn-outline-primary"
                    onClick={() => {
                      onBack();
                    }}
                  >
                    Volver
                  </button>
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-block btn-primary"
                    onClick={(e) => {
                      handlePrint(e);
                    }}
                  >
                    Imprimir
                  </button>
                </div>
                <div className="col-5 text-center">
                  <h2>Packing List</h2>
                </div>

                <div className="col-3">
                  <button
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      goLabel(response.attributes.id);
                    }}
                  >
                    Ver etiqueta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="printView">
          <Row>
            <Col sm={12}>
              <div className="row" style={{ margin: '20px' }}>
                <div className="col-6">
                  <div>
                    Nro. De Documento:{' '}
                    {total ? total.document_number : null}
                  </div>
                  <div>
                    Tipo de Documento:{' '}
                    {total ? total.document_type : null}
                  </div>
                  <div>
                    Razón Social:{' '}
                    {total ? total.business_reason : null}
                  </div>
                  <div>
                    Tipo de Despacho:{' '}
                    {total ? total.delivery_type : null}{' '}
                  </div>
                </div>
                <div className="col-6">
                  <div>
                    Nro. De Preparación:{' '}
                    {total ? total.preparation_number : null}
                  </div>
                  <div>
                    Tipo de Preparación:{' '}
                    {total ? total.preparation_type : null}
                  </div>
                  <div>
                    RUT: {total ? total.business_tax_ref : null}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="divCenter" sm={12}>
              <div>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Bulto</td>
                      <td>Código de Barra</td>
                      <td>Código de Proveedor</td>
                      <td>Descripción</td>
                      <td>Cantidad</td>
                      <td>Peso</td>
                      <td>Volumen</td>
                    </tr>

                    {detailDocument
                      ? detailDocument.map((pack, index) => {
                          return pack.products.map(
                            (itemPack, indexPack) => {
                              return (
                                <tr key={indexPack}>
                                  <td>{pack.package_number}</td>
                                  <td>{itemPack.barcode}</td>
                                  <td>{itemPack.provider_code}</td>
                                  <td>{itemPack.description}</td>
                                  <td>{itemPack.amount}</td>
                                  <td>
                                    {parseFloat(
                                      itemPack.weight
                                    ).toFixed(2)}
                                  </td>
                                  <td>
                                    {parseFloat(
                                      itemPack.volume
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                              );
                            }
                          );
                        })
                      : null}

                    {total
                      ? [total].map((item, indexTotal) => {
                          return (
                            <tr key={indexTotal}>
                              <td
                                style={{ textAlign: 'right' }}
                                colSpan="4"
                              >
                                TOTAL
                              </td>

                              <td>{item.total_products}</td>
                              <td>
                                {parseFloat(
                                  item.total_weight
                                ).toFixed(2)}
                              </td>
                              <td>
                                {parseFloat(
                                  item.total_volume
                                ).toFixed(2)}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="hidden-print">
          <Col sm={12}></Col>
        </Row>
      </Container>
    </div>
  );
}

/*Methodo*/
const mapStateToProps = (state, props) => {
  return {
    response:
      state.documentPackingReducers.documentPublicPackingListResponse.res,
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentPublicPackingListAction,
})(PublicPackingListReadOnly);
