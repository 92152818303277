import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import Vector from "../img/vector.svg";
import Arrow from "../img/arrow.svg";
import logo from "../img/logo.png";
import { getName, close, authenticateUser } from "../utils/Commons.js"
import uuid from 'react-uuid'
/*Component o Page*/
function Navbar(props) {

  let module = props.module
  let navigationOptions
  if(module == 'PackBuilder'){
    navigationOptions = [{ navName: 'Carga Arma Packs', path: '/packBuilder' },
                         { navName: 'Administrador de cola', path: '/packRequestList' }]
  }
  else if(module == 'Entry'){
    navigationOptions = [{ navName: 'Administrador de cola', path: '/entry' }]
  }
  else{
    navigationOptions = [ { navName: 'Administrador de cola', path: '/documents' },
                          { navName: 'Packing', path: '/pendingPackingList' },
                          { navName: 'Sin entregar', path: '/toDelivery' },
                          { navName: 'Traspaso de preparaciones', path: '/preparationTransfer' },
                          { navName: 'Completadas', path: '/completedPackingList' },
                          { navName: 'Movimiento de preparaciones', path: '/preparationMovements' }
                        ]
}

  let no_admin_roles = ['none', 'basic', 'operator']                     
  if(no_admin_roles.includes(localStorage.getItem("role"))){
    delete navigationOptions[0]
  }

  let history = useHistory();

  const checkSelected = (name) => {
    if(props.navBarName == name || props.navBarName.includes(name.toLowerCase())){
      return true
    }
    return false
  }

  const dropdownAction = () => {
    const dropdown = document.getElementById('dropdownNavigationMenu')
    if(dropdown.getAttribute("aria-expanded") == 'false'){
      dropdown.classList.add('black-text')
    }
    else{
      dropdown.classList.remove('black-text')
    }

  }

  const allowActions = () => {
    if(window.location.pathname.includes('documentPublicPackingListReadOnly')){
      return false
    }
    return true
  }

  return (
    <div className="navbarGrid">
      <div className="input-group mr-5">
        <Link to="/">
          <img
            className="navbar-logo ml-5"
            src={logo}
            alt="hbt logo"
          />
        </Link>
        <span className="navbar-title">{props.title}</span>
      </div>
      {allowActions() && (
        <>
          <span className="navbarText">
            {props.navBarName == 'no-dropdown'
              ? ''
              : props.navBarName}
          </span>
          <span>
            {props.navBarName == 'no-dropdown' ? (
              ''
            ) : navigationOptions.length == 1 ? (
              ''
            ) : (
              <NavDropdown
                id="dropdownNavigationMenu"
                className="navigationDropdown navbar-dropdown navbarText mb-3"
                title="Opciones"
                onClick={() => dropdownAction()}
                onBlur={() => dropdownAction()}
              >
                {navigationOptions.map((location) => (
                  <NavDropdown.Item
                    className={
                      checkSelected(location['navName'])
                        ? 'navbar-selected-option'
                        : 'navbar-unselected-option'
                    }
                    href={location['path']}
                    key={location['navName']}
                    value={location['path']}
                  >
                    {location['navName']}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            )}
          </span>
          <span />
          <div className="input-group navbar-login mr-5">
            <span className="navbarStrongText ml-4"> Hola </span>
            <span className="navbarUserName ml-2">
              {' '}
              {getName(props.module)}{' '}
            </span>
            <input
              type="image"
              className="mr-5 ml-4"
              src={Vector}
              border="0"
              onClick={() => close(props.module)}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Navbar;