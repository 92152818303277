import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import es from '../../es.json'
import Navbar from "../../components/navbar"
import { authenticateUser, validateToken } from "../../utils/Commons.js";

function Landing() {

  let history = useHistory();

  // If user is already login in, verify if the user has permission in the Landing module.
  // If user is not loged in, validates its token and if user has permission in the Landing module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Landing')
    }else{
      validateToken(localStorage.getItem("token"),'Landing')
    }
  })

  const toDeparture = () => {
    if (localStorage.getItem("module")) {
      switch (localStorage.getItem("module")) {
        case "Admin":
          history.push("/documents");
          break;
        case "Picking":
          history.push("/operator");
          break;

        case "Packing":
          history.push("/pendingPackingList");
          break;

        case "Delivery":
          history.push("/options");
          break;
        case "Entry":
          history.push("/entry");
          break;
        default:
          if(localStorage.getItem("role") == 'operator') history.push('/pendingPackingList')
          if(localStorage.getItem("role") == 'supervisor') history.push("/documents");
      }
    }
  };

  const toEntry = () => {
    history.push("/entry");
  }

  const toPackBuilder = () => {
    history.push("/packBuilder");
  }

  return(
    <div>
      <Navbar module="Admin" title="Packing" navBarName= 'no-dropdown'/>
      <div className="container center-landing">
        { localStorage.getItem("role") == 'supervisor' ?
          <div className="row row-landing mb-5">
            <div className="col">
              <button className="btn btn-primary btn-block button-landing mx-auto" onClick={() => { toEntry() }}>
                { es['Common']['Entry'] }
              </button>
              </div>
            </div>
          : 
          null
        }
        <div className="row row-landing mb-5">
          <div className="col">
            <button className="btn btn-primary btn-block button-landing mx-auto" onClick={() => { toDeparture() }}>
            { es['Common']['Departure'] }
            </button>
          </div>
        </div>
        { localStorage.getItem("role") == 'supervisor' ?
          <div className="row row-landing mb-5">
            <div className="col">
            <button className="btn btn-primary btn-block button-landing mx-auto" onClick={() => { toPackBuilder() }}>
            { es['Common']['PackBuilder'] }
              </button>
              </div>
            </div>
          : 
          null
        }
      </div>
    </div>
  )

}

export default (Landing);
