import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PendingReceptionListAction,PendingReceptionPrioritizationAction } from "../../redux/actions/documentAdmin.actions";
import "react-datepicker/dist/react-datepicker.css";
import { Container, Row, Col } from "react-grid-system";
import { useHistory } from "react-router-dom";
import Navbar from "../../components/navbar"
import logo from "../../img/logo.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from "moment";
import dragAndDropIcon from "../../img/DragAndDrop.svg";
import { authenticateUser, validateToken } from "../../utils/Commons.js";

/*Component o Page*/
function Entry({
  pendingReceptionResponse,
  PendingReceptionListAction,
  PendingReceptionPrioritizationAction,
  fetchPendingReceptionPrioritizationResponse,
  fetchPendingReceptionListResponse
}) {

  // Initial DnD State
  // 
  // Initial state for the drag and drop function
  // set the constructor to blank
  // 
  // @return [Hash] with the Drag and drop object variables
  const initialDnDState = {
    draggedId: null,
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
  };
  const [selectedType, setSelectedType] = useState("national");
  const [documents, setDocuments] = useState([]);
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
  
  // Index used for table redendering
  // And drag and drop movement
  let myIndex = 0

  // If user is already login in, verify if the user has permission in the Entry module.
  // If user is not loged in, validates its token and if user has permission in the Entry module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Packing')
    }else{
      validateToken(localStorage.getItem("token"),'Entry')
    }
  })

  // Get the pending reception list on page load
  useEffect(() => {
    if(localStorage.getItem("token") !== null){
      const data = {
        document_type: selectedType,
      };
      PendingReceptionListAction(data);
    }
  }, []);

  // Get the pending reception list every time the selected type changes
  useEffect(() => {
    const data = {
      document_type: selectedType,
    };
    PendingReceptionListAction(data);
  }, [selectedType])

  // Set the documents from the response
  // into the documents state for table rendering
  useEffect(() => {
      if (!pendingReceptionResponse) return;
      setDocuments(pendingReceptionResponse);
  }, [pendingReceptionResponse]);

  // Promote top ten
  // 
  // @param id [Integer] the id of the document to move
  // @param position [Integer] the final position of the document
  // Send the payload to the api to promote the document
  // 
  // @return [Void]
  const promoteTopTen = (id, position) => {
    const data = {
      id: id,
      priority: position,
      document_type: selectedType,
    }
    PendingReceptionPrioritizationAction(data);    
  };

  // Drag and drop
  //
  // Drag and drop function
  // @params event [Object] the object dragged
  // Store the dragged item position
  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    const selectedId = Number(event.currentTarget.dataset.id);
    event.dataTransfer.setData("text/html", "");
    return setDragAndDrop({
      draggedFrom: initialPosition,
      draggedId: selectedId,
      draggedTo: null,
      isDragging: true,
      originalOrder: documents,
      updatedOrder: []
    });
    
  };

  // On Drag Over
  // 
  // Store the hover location as final position
  const onDragOver = (event) => {
    event.preventDefault();
    let newList = dragAndDrop.originalOrder;
    let draggedFrom = dragAndDrop.draggedFrom;
    let draggedTo = Number(event.currentTarget.dataset.position);
    draggedTo = draggedTo > draggedFrom ? draggedTo + 1 : draggedTo;
    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    );
    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: draggedTo,
      updatedOrder: newList
    });
  };

  // On Drop
  // 
  // Send the payload to the api to promote the document
  // Reset the drag and drop state
  const onDrop = (event) => {
    // The new priority is calculated form the priority of the element
    if (dragAndDrop.updatedOrder.length > 0) {
      handleSendData()
    }
    // Reset value
    setDragAndDrop({
      draggedFrom: null,
      draggedTo: null,
      draggedId: null,
      isDragging: false,
      originalOrder: [],
      updatedOrder: []
    });
  };

  // Handle send data
  // 
  // Send the payload to the api to promote the document
  // Get the item from the list with the draggedFrom position
  // Build the payload with the item id and the draggedTo Position
  // then send the payload to the api
  // 
  // @return [Void]
  const handleSendData = () => {
    const data = {
      id: dragAndDrop.draggedId,
      priority: dragAndDrop.draggedTo
    }
    PendingReceptionPrioritizationAction(data);
  }

  // Document Type Column
  // 
  // Depending on the selected document type
  // Change the columns of the table
  const documentTypeColumn = () => {
    if (selectedType == 'supply'){
      return 'Tipo de despacho'
    }else{
      return 'Análisis'
    }
  }

  // Document Type Value
  // 
  // Depending on the selected document type
  // Change the value displayed on the table
  const documentTypeValue = (document) => {
    if (selectedType == 'supply'){
      return document.attributes.shipping_type
    }else{
      return document.attributes.analisis
    }
  }

  // Format Date
  // 
  // Format the date to a readable format
  const formatDate = (date) => {
    return(Moment(date).format("DD-MM-yyyy")) 
  }

  return (
    <div>
      <ToastContainer />
      {fetchPendingReceptionPrioritizationResponse || fetchPendingReceptionListResponse ?
        <div className='loading-modal'>
          <div className='loading-modal-logo'>
            <figure>
              <img className='rotation-zoom' src={logo} alt="hbt logo" />
				    </figure>
          </div>
        </div>
        :
        null
      }
      <div id="date-picker"></div>
      <Navbar module="Entry" title="Admin" navBarName="Administrador de cola entrada"/>
      <Container className="body-height">
        <div className="row">
          <div className="col-6 col-md-4 mx-auto py-5">
            <select className="entry-picking-select form-control"
                    name="select-document_type"
                    id="select-document_type"
                    onChange={(e) => {setSelectedType(e.target.value)}}>
              <option value="national">Importación nacional</option>
              <option value="international">Importación internacional</option>
              <option value="supply">Abastecimientos</option>
            </select>
          </div>
        </div>
        <Row>
          <Col sm={12}>
            <section>
              <table className="table entry-picking-table">
                <thead>
                  <tr>
                    <td></td>
                    <td>Nro.</td>
                    <td>Nro. Documento</td>
                    <td>Fecha</td>
                    <td>Tipo de Documento</td>
                    <td>{documentTypeColumn()}</td>
                    <td>Acción</td>
                  </tr>
                </thead>
                <tbody>
                  {documents
                    ? documents.map((item) => {
                      myIndex +=1
                      return (
                          <tr>
                            <td key={myIndex}
                            data-position={item.attributes.priority}
                            data-id={item.attributes.id}
                            draggable
                            onDragStart={(e) => onDragStart(e, false)}
                            onDragOver={(e) => onDragOver(e, false)}
                            onDrop={(e) => onDrop(e, false)}
                            className='dropArea'><img src={dragAndDropIcon} alt="DragAndDrop" /></td>
                            <td>{myIndex}</td>
                            <td>{item.attributes.document_number}</td>
                            <td>{formatDate(item.attributes.document_date)}</td>
                            <td>{item.attributes.document_type.toUpperCase()}</td>
                            <td>{documentTypeValue(item)}</td>
                            <td>{myIndex > 1 ? <button className='btn btn-primary col-10' onClick={() => {promoteTopTen(item.attributes.id, 1)}}>Priorizar</button> : null}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// Map State to Props
// 
// Get the data from the api
// And map the response to a readable variable
const mapStateToProps = (state, props) => {
  return {
    pendingReceptionResponse: state.documentAdminReducers.pendingReceptionResponse.res,
    fetchPendingReceptionListResponse: state.documentAdminReducers.fetchPendingReceptionResponse,
    fetchPendingReceptionPrioritizationResponse: state.documentAdminReducers.fetchPendingReceptionPrioritizationResponse,
  };
};

// Export the component with its actions
export default connect(mapStateToProps, {
  PendingReceptionListAction,
  PendingReceptionPrioritizationAction,
})(Entry);
