import Constants from "../../utils/Constants";

export const PackBuilderUploadDocumentsAction = (payload) => {
  return {
    type: Constants.PackBuilderUploadDocumentsAction,
    payload,
  };
};

export const PackBuilderMonthsAction = (payload) => {//a
  return {
    type: Constants.packBuilderMonthsAction,
    payload,
  };
};

export const GeneratePackRequestAction = (payload) => {
  return {
    type: Constants.GeneratePackRequestAction,
    payload
  };
};

export const PackBuilderUploadFileAction = (file) => {
  return {
    type: Constants.PackBuilderUploadFileAction,
    file
  };
};

export const PackBuilderManualUploadAction = (payload) => {
  return {
    type: Constants.PackBuilderManualUploadAction,
    payload
  };
};

export const PendingPackRequestListAction = () => {
  return {
    type: Constants.PendingPackRequestListAction
  }
}

export const PackRequestPrioritizationAction = (payload) => {
  return {
    type: Constants.PackRequestPrioritizationAction,
    payload
  }
}

export const PackRequestUnselectAction = (payload) => {
  return {
    type: Constants.PackRequestUnselectAction,
    payload
  }
}

export const PackRequestDeleteAction = (id) => {
  return {
    type: Constants.PackRequestDeleteAction,
    id
  }
}

