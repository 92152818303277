import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import {
  DocumentToPackAction,
  ChangeStatusToDeliveredWithNoLabel,
  ChangeStatusToDeliveryAction,
  DocumentPackingListAction,
} from "../../redux/actions/documentPacking.actions";
import { SavePackingAction, EditPackingAction, RemovePackageAction } from "../../redux/actions/productPacking.actions";
import InputNumber from "react-input-just-numbers";
import Navbar from "../../components/navbar"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import { authenticateUser, validateToken } from "../../utils/Commons.js";

let timeoutId;

/*Component o Page*/
function PackDetail({
  response,
  responseEditPack,
  DocumentPackingListAction,
  DocumentToPackAction,
  SavePackingAction,
  EditPackingAction,
  RemovePackageAction,
  ChangeStatusToDeliveredWithNoLabel,
}) {
  const { id } = useParams();
  let history = useHistory();
  const [detailDocument, setDetailDocument] = useState([]);
  const [rows, setRows] = useState([]);
  const [tables, setTables] = useState([1]);
  const [inputQuantity, setInputQuantity] = useState(0);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState('complete');
  const [orderPackages, setOrderPackages] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [currentPackage, setCurrentPackage] = useState("1");
  const [lastPackageNumber, setLastPackageNumber] = useState(1);
  const [newTable, setNewTable] = useState(false);
  const [editErrors,setEditErrors] = useState([])
  const [editSuccess, setEditSuccess] = useState(false)
  const state = useSelector(state => state.documentPackingReducers.documentToPackResponse.res);

  useEffect(() => {
    DocumentToPackAction({ id });
  }, []);
  
  // If user is already login in, verify if the user has permission in the Packing module.
  // If user is not loged in, validates its token and if user has permission in the Packing module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Packing')
    }else{
      validateToken(localStorage.getItem("token"),'Packing')
    }
  })

  useEffect(() => {
    if(editSuccess){
      toast.success("Bulto editado con éxito")
      setEditSuccess(false)
    }
  }, [editSuccess])


  useEffect(() => {
    editErrors.map((err) => {
      toast.error(err.description[0])
    })
  }, [editErrors])

  useEffect(()=>{
    if(_.isEmpty(responseEditPack)){return}
    if(responseEditPack.status == 200){
      setEditSuccess(true)
      return}
    setEditErrors(responseEditPack)
  }, [responseEditPack])

  useEffect(() => {
    if (
      !state ||
      !state.attributes ||
      !state.attributes.order_products
    ) {
      return;
    }
    // Save product
    setOrderProducts(state.attributes.order_products)
    // Get the last pack used by the document
    if (state.attributes.order_packages.length > 0) {
      // Eliminate empty packages as long as there are no products.
      removeEmptyPackages(state.attributes.order_packages)
      const lastPackUsed = state.attributes.order_packages.reduce(function (
        prev,
        current
      ) {
        return Number(prev.package_number) > Number(current.package_number)
          ? prev
          : current;
      });
      setOrderPackages(state.attributes.order_packages)
      state.attributes.order_packages.map((orderPackage) => {
        orderPackage.products.map( (product) => {
          const item = {
            pack: Number(orderPackage.package_number),
            barcode: product.barcode,
            stock: product.amount,
            id: Number(product.line_number),
            description: product.description,
            weight: product.weight,
            volume: product.volume
          };
          setRows((prevState) => {
            return [...prevState, item];
          });
        }) 
      })
      if(newTable) {
        setCurrentPackage(String(Number(lastPackUsed.package_number) + 1));
      } else {
        setCurrentPackage(lastPackUsed.package_number)
        setTables([Number(lastPackUsed.package_number) + 1]);
      }
      setLastPackageNumber(Number(lastPackUsed.package_number))
    } else {
      // Reset Hooks
      setRows([])
      setTables([1]);
      setOrderPackages([])
      setCurrentPackage("1")
      setLastPackageNumber(1)
      setNewTable(true)
    }
  }, [state]);

  const onBack = () => {
    history.push(`/pendingPackingList`);
  };

  const orderByOrderValue = ( a, b ) => {
    if ( a.package_number > b.package_number ){
      return -1;
    }
    if ( a.package_number < b.package_number ){
      return 1;
    }
    return 0;
  }

  const savePacking = (item) => {
    SavePackingAction(item);
    setNewTable(false);
    getPackingList();
  };

  const getPackingList = () => {
    DocumentPackingListAction({ id });
  };

  const handleAddRow = (
    barcode,
    stock,
    description,
    weight,
    width,
    lenght,
    height,
    volume,
    selected_option
  ) => {
    console.log('Selected Method: ', selected_option )
    const documentToUpdate = orderProducts.find((item) => item.barcode === barcode);
    let reduced_packed_stock = reducePackedStock(barcode, documentToUpdate.stock)
    let package_stock = selected_option == 'divided' ? (reduced_packed_stock/stock) : stock
    package_stock = ((package_stock * 100) / 100);
    const item = {
      pack: currentPackage,
      barcode: barcode,
      stock: package_stock,
      id: id,
      description: description,
      weight: weight,
      width: width,
      lenght: lenght,
      height: height,
      volume: volume,
    };
    
    if((selected_option == 'divided')){
      if((stock % 1 != 0)){return toast.error("Cantidad no puede ser decimal.")}
    }else{
      if(package_stock < 0 || package_stock > reducePackedStock(barcode, documentToUpdate.stock) ){return toast.error(`No se puede envultar(${package_stock}) más de lo que queda (${reducePackedStock(barcode, documentToUpdate.stock)}).`);}
    }
    
    if(selected_option == 'divided'){
      for(var index = 0; index < stock; index++){
        let current_package = orderPackages.find((item) => item.package_number === currentPackage);
        let dividedPack = current_package ? currentPackage+1 : currentPackage
        item.pack = +dividedPack + +index
        setCurrentPackage(item.pack)
        savePacking(item)
      }
      toast.success(`Producto dividido en ${stock} bultos`)
    }else{
      savePacking(item)
      toast.success(`Producto agregado con éxito`)
    }
    
  };

  const debounceAddRow = (
    barcode,
    stock,
    description,
    weight,
    width,
    lenght,
    height,
    volume,
    selected_option) => {
      console.log('Debounce selected_option: ', selected_option)
      if(timeoutId){clearTimeout(timeoutId)}
      timeoutId = setTimeout(() => {handleAddRow(barcode,
        stock,
        description,
        weight,
        width,
        lenght,
        height,
        volume,
        selected_option)}, 1000)
  }

  

  const addPackage = () => {
    if(newTable) return
    const validate = rows.filter(
      (item) => item.pack === tables[tables.length - 1]
    );
    setNewTable(true);
    setCurrentPackage(String(lastPackageNumber + 1));
    if (typeof validate !== "undefined" && validate.length > 0) {
      setTables((prevState) => {
        return [...prevState, tables[tables.length - 1] + 1];
      });
    }
  };

  const removePackage = (bulk) => {
    let payload = {
      id: id,
      package_id: bulk.id,
    }
    RemovePackageAction(payload);
  }

  const removeEmptyPackages = (packages) => {
    packages.map((bulk) => {
      if (bulk.products.length === 0) {
        removePackage(bulk)
      }
    })
  }

  const selectPackage = (bulk) => {
    setNewTable(false)
    setCurrentPackage(bulk.package_number)
  }

  function editPackage(line, packageNumber){
    const inputDiv = document.querySelector(`.input-product-${line}-${packageNumber}`)
    const editDiv = document.querySelector(`.edit-product-${line}-${packageNumber}`)
    const amountDiv = document.querySelector(`.input-amount-${line}-${packageNumber}`)
    const amountEditDiv = document.querySelector(`.edit-amount-${line}-${packageNumber}`)

    inputDiv.classList.remove('d-none')
    inputDiv.classList.add('d-flex')
    editDiv.classList.add('d-none')
    amountDiv.classList.remove('d-none')
    amountEditDiv.classList.add('d-none')
  }

  const saveEditPackage = (pId, line, packageNumber) => {
    const newAmount = document.querySelector(`#input-amount-product-${line}-${packageNumber}`).value
    let payload = {
      id: id,
      package_product_id: pId,
      quantity: newAmount
    }
    EditPackingAction(payload);
    
    cancelEditPackage(line, packageNumber)
  }

  const cancelEditPackage = (line, packageNumber) => {
    const inputDiv = document.querySelector(`.input-product-${line}-${packageNumber}`)
    const editDiv = document.querySelector(`.edit-product-${line}-${packageNumber}`)
    const amountDiv = document.querySelector(`.input-amount-${line}-${packageNumber}`)
    const amountEditDiv = document.querySelector(`.edit-amount-${line}-${packageNumber}`)

    inputDiv.classList.add('d-none')
    inputDiv.classList.remove('d-flex')
    amountDiv.classList.add('d-none')
    amountEditDiv.classList.remove('d-none')
    editDiv.classList.remove('d-none')
  }

  const finishWithNoLabel = () => {
    ChangeStatusToDeliveredWithNoLabel(id);
    history.push(`/documentPackingList/${id}`);
  };

  function goLabel() {
    window.location.href = `/label/${id}`
  }

  const requestedAmount = (barcode) => {
    let result = 0
    orderProducts.map((item) => {
      if(item.barcode == barcode){
        result = item.stock;
      }
    })
    return result;
  }

  const reducePackedStock = (barcode, stock) => {
    let result = 0;
    orderPackages.map((bulk) => {
      bulk.products.map((product) => {
        if(product.barcode === barcode){
          result += +product.amount
        }
      })
    })

    result = (Math.round(result * 100) / 100).toFixed(2);
    stock = (Math.round(stock * 100) / 100).toFixed(2);
    let reducedStock = (Math.round((stock - result) * 100) / 100).toFixed(2);
    if(reducedStock <= 0.01){reducedStock = 0}
    return reducedStock;
  };

  return (
    <div>
      <Navbar module="Packing" title="Packing" navBarName='Embultado'/>
      <Container className="body-height">
        <ToastContainer />
        <div className="row containerPackingButtons">
          <div className="col-12">
            <div className="row">
              <div className="col-6 col-md-2">
                <button className="btn btn-outline-primary btn-block" onClick={() => {onBack();}}>
                  Volver
                </button>
              </div>
              <div className="col-6 col-md-2">
                <button className="btn btn-primary btn-block" onClick={() => {goLabel(id);}}>
                  Ver etiqueta
                </button>
              </div>
              <div className="col-12 col-md-5 text-center">
                <h2>Embalaje</h2>
              </div>
                  
              <div className="col-12 col-md-3">
                <button onClick={() => { finishWithNoLabel();}}
                    className={
                      (!detailDocument || detailDocument) &&
                      Number(detailDocument.length) === 0
                        ? "buttonEnabled btn btn-primary btn-block"
                        : "buttonDisabled btn btn-primary btn-block"
                    }
                    disabled={
                      (!detailDocument || detailDocument) &&
                      Number(detailDocument.length) === 0
                        ? ""
                        : "true"
                    }>
                    Terminar sin etiquetar
                </button>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Col sm={12}>
            {response && response.attributes ? (
              <>
                <label className="documentData mr-3">
                  Número de Documento : {response.attributes.document_number}
                </label>
                <label className="documentData">
                  Tipo de Documento : {response.attributes.document_type}
                </label>
              </>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <table className="dataList">
              <thead>
                <tr>
                  <td>N</td>
                  <td>Cod. Prov.</td>
                  <td>Descripción</td>
                  <td>Cantidad</td>
                  <td>Acciones</td>
                  
                </tr>
              </thead>
              <tbody>
                {orderProducts
                  ? orderProducts.map((item, index) => {
                      if(reducePackedStock(item.barcode, item.stock) <= 0){return}
                      if(item.product.product_type !== 'EXISTENCIA') {return}
                      return (
                        <tr key={index}>
                          <td style={{ width: "10px" }}>{index + 1}.-</td>
                          <td>
                            {item.product.product_provider_code}
                          </td>
                          <td style={{ width: "400px" }}>
                            {item.product.gloss}
                          </td>
                          <td style={{ paddingLeft: "2px", width: "100px" }}>
                            {" "}
                            {item.stock > 1
                              ? reducePackedStock(item.barcode, item.stock) + " Unidades"
                              : reducePackedStock(item.barcode, item.stock) + " Unidad"}
                          </td>
                          <td style={{ paddingLeft: "2px", width: "150px" }}>
                            <input
                              type="radio"
                              value="complete"
                              className="mr-1"
                              name="product_quantity"
                              checked={0}
                              onChange={(e) => {
                                debounceAddRow(
                                  item.barcode,
                                  reducePackedStock(item.barcode, item.stock),
                                  item.product.gloss,
                                  item.product.logistic_weight,
                                  item.product.logistic_width,
                                  item.product.logistic_lenght,
                                  item.product.logistic_height,
                                  item.product.logistic_volume,
                                  'complete'
                                )
                                setSelectedMethod('complete')
                              }
                                
                              }
                            />
                            Embultar Todo
                          </td>
                          <td style={{ width: "150px" }}>
                            <input
                              type="radio"
                              value="partial"
                              className="mr-1"
                              name="product_quantity"
                              onClick={() => {
                                setSelectedRow(index);
                                setSelectedItem(true);
                                setSelectedMethod('partial')
                              }}
                              checked={
                                selectedRow === index && selectedMethod == 'partial'
                                  ? true
                                  : false
                              }
                            />
                            Editar Cantidad
                          </td>
                          <td style={{ width: "100px" }}>
                            <input
                              type="radio"
                              className="mr-1"
                              value="divided"
                              name="product_quantity"
                              onClick={() => {
                                setSelectedRow(index);
                                setSelectedItem(true);
                                setSelectedMethod('divided')
                              }}
                              checked={
                                selectedRow === index && selectedMethod == 'divided'
                                  ? true
                                  : false
                              }
                            />
                            Más de un Bulto
                          </td>

                        
                          <td style={{ width: "100px" }}>
                            {selectedRow === index && selectedItem ? (
                              <InputNumber
                                style={{ width: "40px" }}
                                type="number"
                                name={index}
                                min="0"
                                pattern={"[0-9]"}
                                onBlur={(e) => {
                                  const partialStock = e.target.value;
                                  setInputQuantity(partialStock);
                                }}
                              />
                            ) : null}
                          </td>
                          <td>
                            {selectedRow === index && selectedItem ? (
                              <button
                                className="AcceptButtom"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  debounceAddRow(
                                    item.barcode,
                                    inputQuantity,
                                    item.product.gloss,
                                    item.product.logistic_weight,
                                    item.product.logistic_width,
                                    item.product.logistic_lenght,
                                    item.product.logistic_height,
                                    item.product.logistic_volume,
                                    selectedMethod
                                  );
                                }}
                              >
                                Aceptar
                              </button>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </Col>
        </Row>

        <div className='row mb-2'>
          <div className='col-6 justify-content-beginning'>
            <h4 id="h6">Información por Bulto </h4>
          </div>
          <div className='col-6 d-flex justify-content-end'>
            <button className="btn btn-outline-primary" onClick={() => {addPackage()}}>
              Nuevo Bulto
            </button>
          </div>
        </div>
      <div className="row">
        {(orderPackages.length < 1 || newTable) &&
            <div className="col-12 order-1">
              <div>
                <div className="row">
                  <label className="col-md-6 col-12 documentData">Bulto {currentPackage}</label>
                  <div className='col-md-6 col-12 d-flex justify-content-end'>
                      <button
                        className={`btn mb-2 ${currentPackage ? 'btn-primary' : 'btn-outline-primary'}`}
                        disabled={ currentPackage ? true : false }
                      >
                        Seleccionar Bulto
                      </button>
                  </div>
                </div>
                <table id="packTable" className="newPackTable">
                  <tbody>
                    <tr>
                      <td>Código de Proveedor</td>
                      <td>Ítem</td>
                      <td>Descripción</td>
                      <td>Cantidad</td>
                      <td>Peso (kg)</td>
                      <td>Ancho (cms)</td>
                      <td>Largo (cms)</td>
                      <td>Alto (cms)</td>
                      <td>Volumen (m3)</td>
                      <td>Preparación</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        }
        {(orderPackages &&
        orderPackages.sort(orderByOrderValue).map((bulk, index) => {
          return(
            <div key={index} className={`col-12`}>
              <div>
                <div className='row'>
                  <label className="col-md-6 col-12 documentData">Bulto {bulk.package_number}</label>
                  
                  <div className='col-md-6 col-12 d-flex justify-content-end'>
                    { currentPackage !== bulk.package_number &&
                      <button className="btn btn-outline-primary mb-2 mr-2" onClick={() => {removePackage(bulk)}}>
                        Eliminar Bulto
                      </button>
                    }
                    { orderPackages.length > 1 ?
                      <button
                        className={`btn mb-2 ${currentPackage !== bulk.package_number ? 'btn-outline-primary' : 'btn-primary'}`}
                        disabled={ currentPackage === bulk.package_number ? true : false }
                        onClick={ () => {selectPackage(bulk)} }
                      >
                        Seleccionar Bulto
                      </button>
                      :
                      null
                    }
                  </div>
                </div>
                <div className='table-responsive'>
                <table id="packTable" className="newPackTable">
                  <tbody>
                    <tr >
                      <td>Código de Proveedor</td>
                      <td>Ítem</td>
                      <td>Descripción</td>
                      <td>Cantidad</td>
                      <td>Peso (kg)</td>
                      <td>Ancho (cms)</td>
                      <td>Largo (cms)</td>
                      <td>Alto (cms)</td>
                      <td>Volumen (m3)</td>
                      <td>Preparación</td>
                    </tr>
                    {bulk.products &&
                      bulk.products.map((product) => {
                        return (
                          <tr key={product.line_number}>
                            <td>{product.provider_code}</td>
                            <td>{product.barcode}</td>
                            <td>{product.description}</td>
                            <td>
                              <div className={`d-none input-amount-${product.line_number}-${bulk.package_number} justify-content-center`} >
                                <input className='edit-amount-input' id={`input-amount-product-${product.line_number}-${bulk.package_number}`} name={`input-amount-product-${product.line_number}-${bulk.package_number}`}/>
                              </div>
                              <div className={`edit-amount-${product.line_number}-${bulk.package_number}`}>
                                {parseFloat(product.amount).toFixed(2)}
                              </div>
                            </td>
                            <td>{product.weight}</td>
                            <td>{product.logistic_width}</td>
                            <td>{product.logistic_lenght}</td>
                            <td>{product.logistic_height}</td>
                            <td>{product.volume}</td>
                            <td>
                              <div className={`d-none input-product-${product.line_number}-${bulk.package_number} justify-content-center`} >
                                {/* <input className='edit-bulk-input' id={`input-package-product-${product.line_number}-${bulk.package_number}`} name={`input-package-product-${product.line_number}-${bulk.package_number}`}
                                placeholder='N° bulto'/> */}
                                <button className='btn btn-success mr-1' onClick={() => {saveEditPackage(product.id, product.line_number, bulk.package_number)}}>✓</button>
                                <button className='btn btn-danger ml-1' onClick={() => {cancelEditPackage(product.line_number, bulk.package_number)}}>X</button>
                              </div>
                              <div className={`edit-product-${product.line_number}-${bulk.package_number}`}>
                                <button className='btn btn-primary d-flex mr-auto ml-auto d-block' onClick={() => {editPackage(product.line_number, bulk.package_number)}}>Editar</button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          );
        }))}
      </div>
        <Row>
          <Col>
            {orderPackages.length > 0 &&
              <div>
                <label className="documentData">Suma total por bulto</label>
                <table className="newPackTableResume">
                  <tbody>
                    <tr>
                      <td>Bulto</td>
                      <td>Peso (kg)</td>
                      <td>Volumen (m3)</td>
                    </tr>
                    {orderPackages
                      .map((itemPackages) => {
                        return (
                          <tr>
                            <td>{itemPackages.package_number}</td>
                            <td>{itemPackages.package_weight}</td>
                            <td>{itemPackages.package_volume}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
}
/*Methodo*/
const mapStateToProps = (state, props) => {
  return {
    response: state.documentPackingReducers.documentToPackResponse.res,
    responseProduct: state.productReducers.saveProductPackResponse.res,
    responseEditPack: state.productReducers.editProductPackResponse,
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentToPackAction,
  DocumentPackingListAction,
  SavePackingAction,
  EditPackingAction,
  RemovePackageAction,
  ChangeStatusToDeliveredWithNoLabel,
  ChangeStatusToDeliveryAction,
})(PackDetail);

