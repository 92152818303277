import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "../views/Login";
import Landing from "../views/Landing";
import Entry from "../views/Entry";
import PackBuilder from "../views/PackBuilder/packBuilder";
import Document from "../views/Administrator/documentList";
import DocumentPending from "../views/Picking/documentList";
import DocumentDetail from "../views/Picking/picking";
import DocumentToPack from "../views/Packing/documentList";
import PackingList from "../views/Packing/packingList";
import PackDetail from "../views/Packing/packing";
import SearchOptions from "../views/Delivery/searchOptions";
import Searcher from "../views/Delivery/searcher";
import Label from "../views/Packing/label";
import LabelReadOnly from "../views/Packing/labelReadOnly";
import PackingListReadOnly from "../views/Packing/packingListReadOnly";
import PackDetailReadOnly from "../views/Packing/packingReadOnly";
import Empty from "../components/empty";
import DocumentDeliveryDetail from "../views/Administrator/documentDetail";
import DocumentsPendingToDelivery from "../views/Administrator/documentToDeliveryList";
import PackageLocation from "../views/Packing/packageLocation";
import PreparationTransfer from "../views/Delivery/preparationTransfer"
import CompletedPackingList from "../views/Packing/completedPacking";
import PreparationHistory from "../views/Packing/preparationHistory";
import PackRequestList from "../views/PackBuilder/packRequestList";
import { LastLocationProvider } from "react-router-last-location";
import publicPackingListReadOnly from "../views/Packing/publicPackingListReadOnly";

function Navigator() {
  return (
    <Router>
      <LastLocationProvider>
        <Switch>
          <Route path={"/login"} component={Login} />
          <Route exact path="/">
            <Redirect to="/landing" />
          </Route>
          <Route path={"/landing"} component={Landing} />
          {/* -----Entry---- */}
          <Route path={"/entry"} component={Entry} />
          {/* -----Admin---- */}
          <Route exact path={"/documents"} component={Document} />
          <Route exact path={"/toDelivery"} component={DocumentsPendingToDelivery} />
          {/* ----- picking------ */}
          <Route path={"/operator"} component={DocumentPending} />
          <Route path={"/document/:id"} component={DocumentDetail} />
          {/* ----- packing------ */}
          <Route path={"/pendingPackingList"} component={DocumentToPack} />
          <Route path={"/documentPacking/:id"} component={PackDetail} />
          <Route path={"/documentPackingList/:id"} component={PackingList} />
          <Route path={"/packageLocation/:id"} component={PackageLocation} />
          <Route path={"/completedPackingList"} component={CompletedPackingList} />
          <Route path={"/documentPackingListReadOnly/:id"} component={PackingListReadOnly} />
          <Route path={"/documentPublicPackingListReadOnly/:id"} component={publicPackingListReadOnly} />
          <Route path={"/documentPackingReadOnly/:id"} component={PackDetailReadOnly} />
          <Route path={"/labelReadOnly/:id"} component={LabelReadOnly} />
          <Route path={"/preparationTransfer"} component={PreparationTransfer} />
          {/* ----- Delivery------ */}
          <Route path={"/options"} component={SearchOptions} />
          <Route path={"/orderSearch"} component={Searcher} />
          <Route path={"/label/:id"} component={Label} />
          <Route path={"/documentDelivery/:id"} component={DocumentDeliveryDetail} />
          <Route path={"/preparationMovements"} component={PreparationHistory} />
          {/* ----- Pack Builder ----- */}
          <Route path={"/packBuilder"} component={PackBuilder}/>
          <Route path={"/packRequestList"} component={PackRequestList} />
          {/*  */}
          <Route component={Empty} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
}

export { Navigator };
