import Constants from "../../utils/Constants";

const productState = {
  productResponse: [],
  saveProductResponse: {},
  saveProductPackResponse: {},
  editProductPackResponse: {},
};

export default (state = productState, action) => {
  switch (action.type) {
    case Constants.SuccessFetchProduct:
      return { ...state, productResponse: action.payload };

    case Constants.FailFetchProduct:
      return { ...state, productResponse: action.payload };

    //save products in location (picking)

    case Constants.SuccessFetchSaveProduct:
      return { ...state, saveProductResponse: action.payload };

    case Constants.FailFetchSaveProduct:
      return { ...state, saveProductResponse: action.payload };
   
    // Move Product Package
    case Constants.SuccessFetchEditPackingAction:
      return { ...state, editProductPackResponse: action.payload };

    case Constants.FailFetchEditPackingAction:
      return { ...state, editProductPackResponse: action.payload };      

    //save products packing

    case Constants.SuccessFetchSaveProductPack:
      return { ...state, saveProductPackResponse: action.payload };

    case Constants.FailFetchSaveProductPack:
      return { ...state, saveProductPackResponse: action.payload };

    default:
      return state;
  }
};
