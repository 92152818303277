import Constants from "../../utils/Constants";

export const SavePackingAction = (payload) => {
  return {
    type: Constants.savePackingAction,
    payload,
  };
};

export const EditPackingAction = (payload) => {
  return {
    type: Constants.editPackingAction,
    payload,
  };
};

export const RemovePackageAction = (payload) => {
  return {
    type: Constants.removePackageAction,
    payload,
  }
}
