import { Fetch } from "../Fetch";
import Routes from "../Routes";
import { jsonResponse } from "../../tools/JsonResponse";

class DocumentService {

  // Get Available Locations for document
  async documentLocations(payload) {
    return Fetch("get", Routes.AvailableLocations(payload.id))
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async storePreparationLocation(payload) {
    return Fetch("post", Routes.StorePreparationLocation(payload.data.id), payload)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async changePreparationLocation(payload) {
    return Fetch("post", Routes.ChangePreparationLocation(payload.id), payload)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async storePackageLocation(payload) {
    return Fetch("post", Routes.StorePackageLocation(payload.data.id), payload)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  //docuemtn list for preparation (packing)
  async documentPendingToPack(payload, pageNumber) {
    return Fetch("get", `${Routes.DocumentsPendingToPack(payload)}&page=${pageNumber}`)
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  //document to deliver
  async changeStatusToDelivery(payload) {
    return Fetch("post", Routes.DocumentToDelivery(payload))
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // documkent list peding for labels
  async documentPendingToLabel(payload) {
    return Fetch("get", Routes.DocumentPendingToLabel, payload)
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // document list pending for deliverying/packing
  async documentPendingToDelivery(payload) {
    return Fetch("get", Routes.DocumentPendingToDelivery, payload)
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // document to pack// document packing list
  async documentPackingList(payload) {
    return Fetch("get", Routes.DocumentPackingList(payload.id))
      .then((res) => {
        //return jsonResponse(res.status, res.data);
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async documentPublicPackingList(payload) {
    return Fetch("get", Routes.DocumentPublicPackingList(payload.id))
      .then((res) => {
        //return jsonResponse(res.status, res.data);
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }
  async documentToPack(payload) {
    return Fetch("get", Routes.DocumentToPack(payload.id))
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  //document to deliver with no labels
  async changeStatusToDeliveredWithNoLabel(payload) {
    return Fetch("post", Routes.DocumentToDeliveredWithNoLabels(payload.id))
      .then((res) => {
        //return jsonResponse(res.status, res.data)

        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async documentSearch(payload, search){
    return Fetch("get", `${Routes.DocumentsPendingToPack(payload)}&document_number=${search}`)
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async documentLabel(payload) {
    return Fetch("get", Routes.DocumentLabel(payload.id))
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }
}

export default new DocumentService();
