import { Fetch } from "../Fetch";
import Routes from "../Routes";
import { jsonResponse } from "../../tools/JsonResponse";

class DocumentService {
  async documentToDeliverySearch(payload) {
    let url = `${Routes.DocumentToDeliverySearchAndFilter(payload.search, payload.filter)}&page=${payload.current_page}`
    return Fetch("get", url)
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async preparationFilterSearch(payload) {
    let url = `${Routes.PreparationTransferFilter(payload.search, payload.filter, payload.begin_date, payload.end_date)}&page=${payload.current_page}`
    return Fetch("get", url)
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async preparationMovementSearch(payload) {
    let url = `${Routes.PreparationMovements(payload.search, payload.initial_location, payload.final_location, payload.date_filter)}`
    return Fetch("get", url)
      .then((res) => {
        return jsonResponse(200, res);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async packingCompleted(pageNumber) {
    return Fetch("get", `${Routes.DocumentCompleted()}?page=${pageNumber}`)
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async documentCompletedSearch(search) {
    return Fetch("get", `${Routes.DocumentCompleted()}?search=${search}`)
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async documentDeliveryComplete({id}){
    return Fetch("post", Routes.documentDeliveryComplete(id))
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }
}

export default new DocumentService();
