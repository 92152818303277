import Constants from "../../utils/Constants";

export const DocumentListAction = (payload) => {
  return {
    type: Constants.documentListAction,
    payload,
  };
};

// Pending Reception List Action
// 
// Action to get the pending reception documents
// Map a constant to the action name
// @params payload {document_type: selected_type}
// 
// @returns [Hash] with the action type and the payload
export const PendingReceptionListAction = (payload) => {
  return {
    type: Constants.pendingReceptionListAction,
    payload,
  };
};

// Pending Reception Prioritization Action
// 
// Action to change the priority of a pending reception document
// Map a constant to the action name
// @params payload {id: document_id, priority: new_priority}
// 
// @returns [Hash] with the action type and the payload
export const PendingReceptionPrioritizationAction = (payload) => {
  return {
    type: Constants.pendingReceptionPrioritizationAction,
    payload,
  };
};

export const SyncStockAction = (payload) => {
  return {
    type: Constants.syncStockAction,
    payload,
  };
};

export const DocumentFilterAction = (payload) => {
  return {
    type: Constants.documentFilterAction,
    payload,
  };
};

export const DocumentPrioritizationAction = (payload) => {
  return {
    type: Constants.documentPrioritizationAction,
    payload,
  };
};

export const ReleaseTakenDocumentAction = (payload) => {
  return {
    type: Constants.releaseTakenDocumentAction,
    payload,
  };
};

export const unselectTakenDocumentAction = (payload) => {
  return {
    type: Constants.unselectTakenDocumentAction,
    payload,
  };
};

export const TakenDocumentCountAction = (payload) => {
  return {
    type: Constants.takenDocumentCountAction,
    payload,
  };
};
