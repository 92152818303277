export default {
  loginAction: "LOGIN_ACTION",
  SuccessFetchLogin: "SUCCESS_FETCH_LOGIN",
  FailFetchLogin: "FAIL_FETCH_LOGIN",

  // -------------------------Admin---------------------------

  documentListAction: "DOCUMENT_LIST_ACTION",
  StartFetchDocumentList: "START_FETCH_DOCUMENT_LIST",
  SuccessFetchDocumentList: "SUCCESS_FETCH_DOCUMENT_LIST",
  FailFetchDocumentList: "FAIL_FETCH_DOCUMENT_LIST",
  pendingReceptionListAction: "PENDING_RECEPTION_LIST_ACTION",
  StartFetchPendingReceptionList: "START_FETCH_PENDING_RECEPTION_LIST",
  SuccessFetchPendingReceptionList: "SUCCESS_FETCH_PENDING_RECEPTION_LIST",
  FailFetchPendingReceptionList: "FAIL_FETCH_PENDING_RECEPTION_LIST",
  documentSearchAction: "DOCUMENT_SEARCH_ACTION",
  documentCompletedSearch: "DOCUMENT_COMPLETED_SEARCH",
  documentFilterAction: "DOCUMENT_FILTER_ACTION",
  preparationFilterAction: "PREPARATION_FILTER_ACTION",
  preparationMovementSearchAction: 'PREPARATION_MOVEMENT_SEARCH_ACTION',
  SuccessFetchPreparationMovement: 'SUCCESS_FETCH_PREPARATION_MOVEMENT',
  FailFetchPreparationMovement: 'FAIL_FETCH_PREPARATION_MOVEMENT',
  FailFetchPreparationFilter: "FAIL_FETCH_PREPARATION_FILTER",
  SuccessFetchPreparationFilter: "SUCCESS_FETCH_PREPARATION_FILTER",
  StartFetchDocumentFilter: "START_FETCH_DOCUMENT",
  SuccessFetchDocumentFilter: "SUCCESS_FETCH_DOCUMENT_FILTER",
  FailFetchDocumentFilter: "FAIL_FETCH_DOCUMENT_FILTER",

  takenDocumentCountAction: 'TAKEN_DOCUMENT_COUNT_ACTION',
  SuccessFetchTakenDocumentCountAction: 'SUCEESS_FETCH_TAKEN_DOCUMENT_COUNT_ACTION',
  FailFetchTakenDocumentCountAction: 'FAIL_FETCH_TAKEN_DOCUMENT_COUNT_ACTION',

  releaseTakenDocumentAction: 'RELEASE_TAKEN_DOCUMENT_ACTION',
  unselectTakenDocumentAction: 'UNSELECT_TAKEN_DOCUMENT_ACTION',
  SuccessFetchReleaseTakenDocumentAction: 'SUCEESS_FETCH_RELEASE_TAKEN_DOCUMENT_ACTION',
  FailFetchReleaseTakenDocumentAction: 'FAIL_FETCH_RELEASE_TAKEN_DOCUMENT_ACTION',

  documentPrioritizationAction: "DOCUMENT_PRIORITIZATION_ACTION",
  StartFetchDocumentPrioritization: "START_FETCH_DOCUMENT_PRIORITIZATION",
  SuccessFetchDocumentPrioritization: "SUCCESS_FETCH_DOCUMENT_PRIORITIZATION",
  FailFetchDocumentPrioritization: "FAIL_FETCH_DOCUMENT_PRIORITIZATION",

  pendingReceptionPrioritizationAction: "PENDING_RECEPTION_PRIORITIZATION_ACTION",
  StartFetchPendingReceptionPrioritization: "START_FETCH_PENDING_RECEPTION_PRIORITIZATION",
  SuccessFetchPendingReceptionPrioritization: "SUCCESS_FETCH_PENDING_RECEPTION_PRIORITIZATION",
  FailFetchPendingReceptionPrioritization: "FAIL_FETCH_PENDING_RECEPTION_PRIORITIZATION",

  syncStockAction: "SYNC_STOCK_ACTION",
  SuccessFetchSyncStockAction: "SUCCESS_FETCH_SYNC_STOCK_ACTION",
  FailFetchSyncStockAction: "FAIL_FETCH_SYNC_STOCK_ACTION",

  // -------------------------Picking---------------------------

  documentSelectedToPickAction: "DOCUMENT_SELECTED_TO_PICK_ACTION",
  SuccessFetchDocumentSelectedToPick: "SUCCESS_FETCH_DOCUMENT_SELECTED_TO_PICK",
  FailFetchDocumentSelectedToPick: "FAIL_FETCH_DOCUMENT_SELECTED_TO_PICK",

  documentPendingAction: "DOCUMENT_PENDING_ACTION",
  SuccessFetchDocumentPending: "SUCCESS_FETCH_PENDING_DOCUMENT",
  FailFetchDocumentPending: "FAIL_FETCH_PENDING_DOCUMENT",

  packBuilderMonthsAction: "PACK_BUILDER_MONTHS_ACTION",
  SuccessFetchPackBuilderMonths: "SUCCESS_FETCH_PACK_BUILDER_MONTHS",
  FailFetchPackBuilderMonths: "FAIL_FETCH_PACK_BUILDER_MONTHS",

  documentPackingComplete: "DOCUMENT_PACKING_COMPLETE",
  documentCompletedAction: "DOCUMENT_COMPLETED_ACTION",
  SuccessFetchDocumentCompleted: "SUCCESS_FETCH_COMPLETED_DOCUMENT",
  FailFetchDocumentCompleted: "FAIL_FETCH_COMPLETED_DOCUMENT",

  documentSelectedAction: "DOCUMENT_SELECTED_ACTION",
  SuccessFetchDocumentSelected: "SUCCESS_FETCH_SELECTED_DOCUMENT",
  FailFetchDocumentSelected: "FAIL_FETCH_SELECTED_DOCUMENT",

  productAction: "DOCUMENT_ACTION_PRODUCT",
  SuccessFetchProduct: "SUCCESS_FETCH_PRODUCT",
  FailFetchProduct: "FAIL_FETCH_PRODUCT",

  changeProductStatusAction: "CHANGE_PRODUCT_STATUS_ACTION",
  SuccessFetchChangeProductStatus: "SUCCESS_FETCH_CHANGE_PRODUCT_STATUS",
  FailFetchChangeProductStatus: "FAIL_FETCH_CHANGE_PRODUCT_STATUS",

  saveProductAction: "SAVE_PRODUCT_ACTION",
  SuccessFetchSaveProduct: "SUCCESS_FETCH_SAVE_PRODUCT",
  FailFetchSaveProduct: "FAIL_FETCH_SAVE_PRODUCT",

  PendingDocumentSelectionAction: "PENDING_DOCUMENT_SELECTION_ACTION",
  PendingDocumentSelectionSuccess: "PENDING_DOCUMENT_SELECTION_SUCCESS",
  PendingDocumentSelectionFailure: "PENDING_DOCUMENT_SELECTION_FAILURE",

  // -------------------------Packing---------------------------

  showLoadingScreen: "SHOW_LOADING_SCREEN",
  showModalLoadingScreen: "SHOW_MODAL_LOADING_SCREEN",

  documentPendingToPackAction: "DOCUMENT_PENDING_TO_PACK_ACTION",
  SuccessFetchDocumentPendingToPack: "SUCCESS_FETCH_DOCUMENT_PENDING_TO_PACK",
  FailFetchDocumentPendingToPack: "FAIL_FETCH_DOCUMENT_PENDING_TO_PACK",

  documentLocationAction: "DOCUMENT_LOCATION_ACTION",
  SuccessFetchDocumentLocation: "SUCCESS_FETCH_DOCUMENT_LOCATION",
  FailFetchDocumentLocation: "FAIL_FETCH_DOCUMENT_LOCATION",

  storePreparationLocationAction: "STORE_PREPARATION_LOCATION_ACTION",
  SuccessStorePreparationLocation: "SUCCESS_STORE_PREPARATION_LOCATION",
  FailStorePreparationLocation: "FAIL_STORE_PREPARATION_LOCATION",

  storePackageLocationAction: "STORE_PACKAGE_LOCATION_ACTION",
  SuccessStorePackageLocation: "SUCCESS_STORE_PACKAGE_LOCATION",
  FailStorePackageLocation: "FAIL_STORE_PACKAGE_LOCATION",

  changeStatusToPackAction: "DOCUMENT_CHANGE_STATUS_TO_PACK_ACTION",
  SuccessFetchChangeStatusToPackAction: "SUCCESS_FETCH_CHANGE_STATUS_TO_PACK",
  FailFetchChangeStatusToPackAction: "FAIL_FETCH_CHANGE_STATUS_TO_PACK",

  changeStatusToDeliveryAction: "DOCUMENT_CHANGE_STATUS_TO_DELIVERY_ACTION",
  SuccessFetchChangeStatusToDeliveryAction:
    "SUCCESS_FETCH_CHANGE_STATUS_TO_DELIVERY",
  FailFetchChangeStatusToDeliveryAction: "FAIL_FETCH_CHANGE_STATUS_TO_DELIVERY",

  documentsPendingToDeliveryAction: "DOCUMENT_PENDING_TO_DELIVERY_ACTION",
  SuccessFetchDocumentsPendingToDeliveryAction:
    "SUCCESS_FETCH_DOCUMENT_PENDING_TO_DELIVERY",
  FailFetchDocumentsPendingToDeliveryAction:
    "FAIL_FETCH_DOCUMENT_PENDING_TO_DELIVERY",

  documentsPendingToLabelAction: "DOCUMENT_PENDING_TO_LABEL_ACTION",
  SuccessFetchDocumentsPendingToLabelAction:
    "SUCCESS_FETCH_DOCUMENT_PENDING_TO_LABEL",
  FailFetchDocumentsPendingToLabelAction:
    "FAIL_FETCH_DOCUMENT_PENDING_TO_LABEL",

  documentPackingListAction: "DOCUMENT_PACKING_LIST_ACTION",
  SuccessFetchDocumentPackingListAction: "SUCCESS_FETCH_DOCUMENT_PACKING_LIST",
  FailFetchDocumentPackingListAction: "FAIL_FETCH_DOCUMENT_PACKING_LIST",

  documentPublicPackingListAction: "DOCUMENT_PUBLIC_PACKING_LIST_ACTION",
  SuccessFetchDocumentPublicPackingListAction: "SUCCESS_FETCH_DOCUMENT_PUBLIC_PACKING_LIST",
  FailFetchDocumentPublicPackingListAction: "FAIL_FETCH_DOCUMENT_PUBLIC_PACKING_LIST",

  documentToPackAction: "DOCUMENT_TO_PACK_ACTION",
  SuccessFetchDocumentToPack: "SUCCESS_FETCH_DOCUMENT_TO_PACK",
  FailFetchDocumentToPack: "FAIL_FETCH_DOCUMENT_TO_PACK",

  savePackingAction: "SAVE_PACKING_ACTION",
  SuccessFetchSavePackingAction: "SUCCESS_FETCH_SAVE_PACKING",
  FailFetchSavePackingAction: "FAIL_FETCH_SAVE_PACKING",

  removePackageAction: "REMOVE_PACKAGE_ACTION",
  SuccessFetchRemovePackageAction: "SUCCESS_FETCH_REMOVE_PACKAGE",
  FailFetchRemovePackageAction: "FAIL_FETCH_REMOVE_PACKAGE",
  changePreparationLocationAction : "CHANGE_PACKAGE_LOCATION_ACTION",
  SuccessChangePreparationLocationAction : "SUCCESS_CHANGE_PACKAGE_LOCATION",
  FailChangePreparationLocationAction : "FAIL_CHANGE_PACKAGE_LOCATION",
  editPackingAction: "EDIT_PACKING_ACTION",
  SuccessFetchEditPackingAction: "SUCCESS_FETCH_EDIT_PACKING",
  FailFetchEditPackingAction: "FAIL_FETCH_EDIT_PACKING",

  documentLabelAction: "DOCUMENT_LABEL_ACTION",
  SuccessFetchDocumentLabelAction: "SUCCESS_FETCH_DOCUMENT_LABEL",
  FailFetchDocumentLabelAction: "FAIL_FETCH_DOCUMENT_LABEL",
  changeStatusToDeliveredWithNoLabelAction:
    "CHANGE_STATUS_TO_DELIVERED_WITH_NO_LABELS_ACTION",
  SuccessFetchChangeStatusToDeliveredWithNoLabelAction:
    "SUCCESS_FETCH_CHANGE_STATUS_TO_DELIVERED_WITH_NO_LABELS",
  FailFetchChangeStatusToDeliveredWithNoLabelAction:
    "FAIL_FETCH_CHANGE_STATUS_TO_DELIVERED_WITH_NO_LABELS",

  // -------------------------Delivery---------------------------
  documentToDeliverySearchAction: "DOCUMENT_TO_DELIVERY_SEARCH_ACTION",
  StartFetchDocumentToDeliverySearch: "START_FETCH_DOCUMENT_TO_DELIVERY_SEARCH",
  SuccessFetchDocumentToDeliverySearchAction: "SUCCESS_FETCH_DOCUMENT_TO_DELIVERY_SEARCH",
  FailFetchDocumentToDeliverySearchAction: "FAIL_FETCH_DOCUMENT_TO_DELIVERY_SEARCH",
  documentDeliveryCompleteAction: "DOCUMENT_DELIVERY_COMPLETE_ACTION",
  StartFetchDocumentDelivery: "START_FETCH_DOCUMENT_DELIVERY",
  SuccessFetchDocumentDeliveryComplete: "SUCCESS_FETCH_DOCUMENT_DELIVERY_COMPLETE",
  FailFetchDocumentDeliveryComplete: "FAIL_FETCH_DOCUMENT_DELIVERY_COMPLETE",

  // ------------------------- Pack Builder ---------------------------
  PackBuilderUploadDocumentsAction: "PACK_BUILDER_UPLOAD_DOCUMENTS",
  StartFetchPackBuilderUploadDocuments: "START_PACK_BUILDER_UPLOAD_DOCUMENTS",
  SuccessFetchPackBuilderUploadDocumentsAction: "SUCCESS_PACK_BUILDER_UPLOAD_DOCUMENTS",
  FailFetchPackBuilderUploadDocumentsAction: "FAIL_PACK_BUILDER_UPLOAD_DOCUMENTS",

  GeneratePackRequestAction: "GENERATE_PACK_REQUEST_ACTION",
  StartFetchGeneratePackRequestAction: "START_GENERATE_PACK_REQUEST_ACTION",
  SuccessFetchGeneratePackRequestAction: "SUCCESS_GENERATE_PACK_REQUEST_ACTION",
  FailFetchGeneratePackRequestAction: "FAIL_GENERATE_PACK_REQUEST_ACTION",
  
  PackBuilderUploadFileAction: "PACK_BUILDER_UPLOAD_FILE_ACTION",
  StartFetchPackBuilderUploadFileAction: "START_PACK_BUILDER_UPLOAD_FILE_ACTION",
  SuccessFetchPackBuilderUploadFileAction: "SUCCESS_PACK_BUILDER_UPLOAD_FILE_ACTION",
  FailFetchPackBuilderUploadFileAction: "FAIL_PACK_BUILDER_UPLOAD_FILE_ACTION",

  PackBuilderManualUploadAction: "PACK_BUILDER_UPLOAD_MANUAL_ACTION",
  StartFetchPackBuilderManualUploadAction: "START_PACK_BUILDER_UPLOAD_MANUAL_ACTION",
  SuccessFetchPackBuilderManualUploadAction: "SUCCESS_PACK_BUILDER_UPLOAD_MANUAL_ACTION",
  FailFetchPackBuilderManualUploadAction: "FAIL_PACK_BUILDER_UPLOAD_MANUAL_ACTION",

  PendingPackRequestListAction: "PENDING_PACK_REQUEST_LIST_ACTION",
  StartFetchPendingPackRequestListAction: "START_PENDING_PACK_REQUEST_LIST_ACTION",
  SuccessFetchPendingPackRequestListAction: "SUCCESS_PENDING_PACK_REQUEST_LIST_ACTION",
  FailFetchPendingPackRequestListAction: "FAIL_PENDING_PACK_REQUEST_LIST_ACTION",

  PackRequestPrioritizationAction: "PACK_REQUEST_PRIORITIZATION_ACTION",
  StartFetchPackRequestPrioritizationAction: "START_PACK_REQUEST_PRIORITIZATION_ACTION",
  SuccessFetchPackRequestPrioritizationAction: "SUCCESS_PACK_REQUEST_PRIORITIZATION_ACTION",
  FailFetchPackRequestPrioritizationAction: "FAIL_PACK_REQUEST_PRIORITIZATION_ACTION",

  PackRequestUnselectAction: "PACK_REQUEST_UNSELECT_ACTION",
  StartPackRequestUnselectAction: "START_PACK_REQUEST_UNSELECT_ACTION",
  SuccessPackRequestUnselectAction: "SUCCESS_PACK_REQUEST_UNSELECT_ACTION",
  FailPackRequestUnselectAction: "FAIL_PACK_REQUEST_UNSELECT_ACTION",

  PackRequestDeleteAction: "PACK_REQUEST_DELETE_ACTION",
  StartPackRequestDeleteAction: "START_PACK_REQUEST_DELETE_ACTION",
  SuccessPackRequestDeleteAction: "SUCCESS_PACK_REQUEST_DELETE_ACTION",
  FailPackRequestDeleteAction: "FAIL_PACK_REQUEST_DELETE_ACTION"
};
