import { Fetch } from "../Fetch";
import Routes from "../Routes";
import { jsonResponse } from "../../tools/JsonResponse";

class ProductService {
  async saveProduct(payload) {
    const data = {
      data:{
        type: "ProductPick",
        id: null,
        attributes:{
        line_number: payload.line_number,
        sku: payload.sku,
        barcode: payload.barcode,
        stock: payload.stock,
        location: payload.location
       }
     }
      
    };

    return Fetch("post", Routes.SaveProduct(payload.id), data)
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async savePacking(payload) {
    const data = {
      sku: payload.sku,
      barcode: payload.barcode,
      provider_code: payload.provider_code,
      amount: payload.stock,
      package_number: payload.pack,
      id: payload.id,
    };

    return Fetch("post", Routes.SavePacking(data.id), data)
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async editPacking(payload) {
    const data = {
      pack_id: payload.package_product_id,
      quantity: payload.quantity,
      package_number: payload.package_number,
      id: payload.id,
    };

    return Fetch("post", Routes.EditPacking(data.id), data)
      .then((res) => {
        console.log('Response', res)
        return jsonResponse(200, res);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async removePackage(payload) {
    const data = {
      id: payload.id,
      package_id: payload.package_id
    };

    return Fetch("post", Routes.RemovePackage(data.id), data)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }
}

export default new ProductService();
