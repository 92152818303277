import Constants from "../../utils/Constants";

const documentState = {
  documentSelectedToPickResponse: [],
  documentPendingResponse: [],
  documentCompletedResponse: [],
  documentSelectedResponse: [],
};

export default (state = documentState, action) => {
  switch (action.type) {
    //document to pick

    case Constants.SuccessFetchDocumentSelectedToPick:
      return { ...state, documentSelectedToPickResponse: action.payload };

    case Constants.FailFetchDocumentSelectedToPick:
      return { ...state, documentSelectedToPickResponse: action.payload };

    //documents pending

    case Constants.SuccessFetchDocumentPending:
      return { ...state, documentPendingResponse: action.payload };

    case Constants.FailFetchDocumentPending:
      return { ...state, documentPendingResponse: action.payload };

    //documents completed

    case Constants.SuccessFetchDocumentCompleted:
      return { ...state, documentCompletedResponse: action.payload };

    case Constants.FailFetchDocumentCompleted:
      return { ...state, documentCompletedResponse: action.payload };

    //documents selected by the operator

    case Constants.SuccessFetchDocumentSelected:
      return { ...state, documentSelectedResponse: action.payload };

    case Constants.FailFetchDocumentSelected:
      return { ...state, documentSelectedResponse: action.payload };

    default:
      return state;
  }
};
