import { call, put, takeEvery } from "redux-saga/effects";
import Constants from "../../utils/Constants";
import DocumentService from "../../API/services/DocumentPickingService";

function* documentSelectedToPick(action) {
  try {
    const data = yield call(
      DocumentService.documentSelectedToPick,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentSelectedToPick,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentSelectedToPick,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentSelectedToPick,
      payload: e.message,
    });
  }
}

function* documentPending(action) {
  try {
    const data = yield call(DocumentService.documentPending, action.payload);

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentPending,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentPending,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentPending,
      payload: e.message,
    });
  }
}

function* pendingDocumentSelected(action) {
  try {
    const data = yield call(
      DocumentService.selectPendingDocument,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.PendingDocumentSelectionFailure,
        payload: data,
      });
    }

    yield put({
      type: Constants.PendingDocumentSelectionSuccess,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.PendingDocumentSelectionFailure,
      payload: e.message,
    });
  }
}

function* documentCompleted(action) {
  try {
    const data = yield call(DocumentService.documentCompleted, action.payload);

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentCompleted,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentCompleted,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentCompleted,
      payload: e.message,
    });
  }
}

function* documentSelected(action) {
  try {
    const data = yield call(DocumentService.documentSelected, action.payload);
    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentSelected,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentSelected,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentSelected,
      payload: e.message,
    });
  }
}

export function* documentPickingSaga() {
  yield takeEvery(
    Constants.documentSelectedToPickAction,
    documentSelectedToPick
  );
  yield takeEvery(Constants.documentPendingAction, documentPending);
  yield takeEvery(Constants.documentCompletedAction, documentCompleted);
  yield takeEvery(Constants.documentSelectedAction, documentSelected);
  yield takeEvery(
    Constants.PendingDocumentSelectionAction,
    pendingDocumentSelected
  );
}
