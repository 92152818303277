import { Fetch } from "../Fetch";
import Routes from "../Routes";
import { jsonResponse } from "../../tools/JsonResponse";

class DocumentService {
  // document to pick
  async documentSelectedToPick(payload) {
    return Fetch("get", Routes.DocumentSelectedToPick(payload.id))
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // operator's document list
  async documentPending(payload) {
    return Fetch("get", Routes.DocumentPending, payload)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }
  
  // changing the status when the document is picked
  async documentCompleted(payload) {
    return Fetch("post", Routes.DocumentStatusCompleted(payload.id))
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async selectPendingDocument(documentId) {
    return Fetch("POST", Routes.pendingDocumentSelected(documentId))
      .then((res) => jsonResponse(200, res.data))
      .catch((err) => jsonResponse(500, err));
  }

  async documentSelected(payload) {
    return Fetch("post", Routes.DocumentStatusSelected(payload.id))
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }
}

export default new DocumentService();
