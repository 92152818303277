import { Fetch } from "../Fetch";
import Routes from "../Routes";
import { jsonResponse } from "../../tools/JsonResponse";
class LoginService {
  async login(payload) {
    const data = {
      data: {
        type: "sessions",
        attributes: {
          token: payload.token
        },
      },
    };

    return Fetch("post", Routes.login, data)
      .then((res) => {
        return jsonResponse(200, res);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }
}

export default new LoginService();
