import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store/index";
import { Navigator } from "./navigations/Navigator";
import Application from "./views/Application";

function App() {
  return (
    <Provider store={store}>
      <Application>
        <Navigator />
      </Application>
    </Provider>
  );
}

export default App;
