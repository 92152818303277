import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import logo from "../../img/logo.png";
import { getName, close } from "../../utils/Commons.js"

function SearchOptions() {
  let history = useHistory();

  const goToOrderList = () => {
    history.push(`/pendingOrderList`);
  };

  const goToSearcher = () => {
    history.push(`/orderSearch`);
  };

  const toLogin = () => {
    localStorage.setItem("module", "Delivery");
    history.push("/login");
  };


  return (
    <Container>
      <Row>
        <Col sm={12}>
          <table className="containerTittleGrabber">
            <tbody>
              <tr>
                <td>
                  <img
                    className="containerTittleGrabberImg"
                    src={logo}
                    alt="hbt logo"
                  />
                </td>
                <td>
                  <label className="containerTittleGrabberTitte">Entrega</label>
                </td>
                <td>
                  <label className="containerTittleGrabberLabelName">
                    Hola {getName(toLogin)} !
                  </label>
                </td>
                <td>
                  <label className="containerTittleGrabberLabelClose">
                    Cerrar Sesión
                  </label>
                </td>
                <td>
                  <button
                    className="containerTittleGrabberButton"
                    onClick={() => {
                      close();
                    }}
                  >
                    x
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className="containerSearchOptions">
            <button
              disabled={true}
              onClick={() => {
                goToOrderList();
              }}
              className="buttonDisabled"
            >
              Ver Listado de Pedidos
            </button>

            <button
              onClick={() => {
                goToSearcher();
              }}
            >
              Buscar por factura, destino, cliente o fecha
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SearchOptions;
