import 'react-toastify/dist/ReactToastify.css';
import es from '../../es.json';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../../components/navbar"
import logo from "../../img/logo.png";
import { ToastContainer, toast } from 'react-toastify';
import { getWarehouse, authenticateUser, validateToken } from "../../utils/Commons"
import { PackBuilderUploadDocumentsAction, GeneratePackRequestAction, PackBuilderUploadFileAction, PackBuilderManualUploadAction, PackBuilderMonthsAction } from "../../redux/actions/documentPackBuilder.actions"
import close from "../../img/close2.svg"
import Modal from "react-bootstrap/Modal";

function PackBuilder({
  PackBuilderUploadDocumentsAction,
  PackBuilderDocumentResponse,
  PackBuilderMonthsAction,
  AvailableMonthsResponse,
  GeneratePackRequestAction,
  GeneratePackRequestResponse,
  PackBuilderUploadFileAction,
  PackBuilderUploadFileResponse,
  PackBuilderManualUploadAction,
  PackBuilderUploadManualResponse
}) {

  const [documents, setDocuments] = useState([]);
  const [request, setRequest] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [isOpenRequest, setIsOpenRequest] = useState(false);
  const [isOpenFileUpload, setIsOpenFileUpload] = useState(false);
  const [isOpenManualUpload, setIsOpenManualUpload] = useState(false);
  const [quantity, setQuantity] = useState(false);
  const [sku, setSku] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState('');
  const [months, setMonths] = useState([]);

  useEffect(() => {
    PackBuilderUploadDocumentsAction();
    PackBuilderMonthsAction();
  }, [])

  useEffect(() => {
    if (!PackBuilderDocumentResponse) return;
    setDocuments(PackBuilderDocumentResponse.res);
  }, [PackBuilderDocumentResponse]);

  useEffect(() => {
    if(!AvailableMonthsResponse || !AvailableMonthsResponse.attributes || !AvailableMonthsResponse.attributes.months){
      return;
    }else{
      setMonths(AvailableMonthsResponse.attributes.months);
    }
  }, [AvailableMonthsResponse]);

  useEffect(() => {
    if (!PackBuilderUploadManualResponse || !sku) return;
    hideModalManualUpload();
    if(PackBuilderUploadManualResponse.res != undefined){
      toast.success(`Carga de ${sku} realizada.`)
      PackBuilderUploadDocumentsAction()
    }
    else{
      toast.error(`Problema realizando la carga del producto`)
    }
  }, [PackBuilderUploadManualResponse]);

  useEffect(() => {
    if (!GeneratePackRequestResponse || !selectedDocument) return;
    hideModalRequest();
    if(GeneratePackRequestResponse.res != undefined){
      toast.success(`Pack ${selectedDocument.attributes.sku} solicitado`)
      PackBuilderUploadDocumentsAction()
    }
    else{
      toast.error(`Problema realizando la solicitud de pack.`)
    }
    setRequest(GeneratePackRequestResponse);
  }, [GeneratePackRequestResponse]);

  useEffect(() => {
    if (!PackBuilderUploadFileResponse || !fileUpload) return;
    hideModalFileUpload();
    toast.success(`Archivo cargado.`)
    PackBuilderUploadDocumentsAction()
  }, [PackBuilderUploadFileResponse]);

  const showModalRequest = () => {
    setIsOpenRequest(true);
  }

  const hideModalRequest = () => {
    setIsOpenRequest(false);
  }
  
  const packRequestModal = (packUpload) => {
    setSelectedDocument(packUpload)
    showModalRequest()
  }

  const showModalManualUpload = () => {
    setIsOpenManualUpload(true);
  }

  const hideModalManualUpload = () => {
    setIsOpenManualUpload(false);
  }

  const manualUploadModal = () => {
    showModalManualUpload()
  }

  const uploadPackBuilderFile = () => {
    PackBuilderUploadFileAction(fileUpload)
  }

  const filterByMonth = (month) => {
    const payload = {
      month: month,
    };
    PackBuilderUploadDocumentsAction(payload)
  }

  const generatePackManualUpload = () => {
    const payload = { data:
                      { 
                        attributes: {
                          quantity: quantity,
                          sku:sku
                        }
                      }}
    PackBuilderManualUploadAction(payload)
  }

  const showModalFileUpload = () => {
    setIsOpenFileUpload(true);
  }

  const hideModalFileUpload = () => {
    setIsOpenFileUpload(false);
  }

  const fileUploadModal = () => {
    showModalFileUpload()
  }

  const generatePackRequest = () => {
    const payload = { data:
                      { 
                        id: selectedDocument.id,
                        attributes: {
                          quantity: quantity
                        }
                      }}
    GeneratePackRequestAction(payload)
  }

  const anyPendingResponse = () => {
    return ( PackBuilderDocumentResponse || GeneratePackRequestResponse || PackBuilderUploadFileResponse || PackBuilderUploadManualResponse ).length == 0
  }

  let myIndex = 0

  return (
    <div>
      <ToastContainer />
      { anyPendingResponse() ?
        <div className='loading-modal'>
          <div className='loading-modal-logo'>
            <figure>
              <img className='rotation-zoom' src={logo} alt="hbt logo" />
				    </figure>
          </div>
        </div>
        :
        null
      }

      <Modal show={ isOpenRequest } onHide={ hideModalRequest } size='md'>
        <Modal.Header className="d-block">
          <div className="row">
            <div className="col-10">
              <h4>Generación de pedido de pack</h4>
            </div>
            <div className="col-2 text-right">
              <img className="close-button" src={ close } alt="close" onClick={ hideModalRequest }/>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12'>
              <h5 className='modal-subtitle'> Producto seleccionado: </h5>
              <h5 className='modal-subtitle mx-2 card-bold'>{ selectedDocument ? selectedDocument.attributes.sku : '' }</h5>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <h5 className='modal-subtitle'> Cantidad a solicitar: </h5>
              <input
                type="number"
                name={ quantity }
                className = 'mx-2 w-100'
                pattern={"[0-9]"}
                onChange={(e) => {
                  setQuantity(e.target.value)
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-container">
          <div className="row">
            <div className="col-6 pl-0"><button type="button" className="btn btn-secondary btn-block" onClick={ hideModalRequest }>Cancelar</button></div>
            <div className="col-6 pr-0"><button type="button" className="btn btn-primary btn-block" onClick={ generatePackRequest }>Generar pedido</button></div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={ isOpenFileUpload } onHide={ hideModalFileUpload } size='md'>
        <Modal.Header className="d-block">
          <div className="row">
            <div className="col-10">
              <h4>Carga Arma Pack por Archivo</h4>
            </div>
            <div className="col-2 text-right">
              <img className="close-button" src={ close } alt="close" onClick={ hideModalFileUpload }/>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12'>
              <input id='upload_stock_excel' name='upload_stock_excel' type='file' accept='.xlsx' onChange={(e) => setFileUpload(e.target.files[0])} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-container">
          <div className="row">
            <div className="col-6 pl-0"><button type="button" className="btn btn-secondary btn-block" onClick={ hideModalFileUpload }>Cancelar</button></div>
            <div className="col-6 pr-0"><button type="button" className="btn btn-primary btn-block" onClick={ ()=> uploadPackBuilderFile() }>Generar pedido</button></div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={ isOpenManualUpload } onHide={ hideModalManualUpload } size='md'>
        <Modal.Header className="d-block">
          <div className="row">
            <div className="col-10">
              <h4>Agregar pack a carga mensual</h4>
            </div>
            <div className="col-2 text-right">
              <img className="close-button" src={ close } alt="close" onClick={ hideModalManualUpload }/>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12'>
              <h5 className='modal-subtitle'> SKU: </h5>
              <input
                type="text"
                name={ sku }
                className = 'mx-2 w-100'
                onChange={(e) => {
                  setSku(e.target.value)
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <h5 className='modal-subtitle'> Cantidad a solicitar: </h5>
              <input
                type="number"
                name={ quantity }
                className = 'mx-2 w-100'
                pattern={"[0-9]"}
                onChange={(e) => {
                  setQuantity(e.target.value)
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-container">
          <div className="row">
            <div className="col-6 pl-0"><button type="button" className="btn btn-secondary btn-block" onClick={ hideModalManualUpload }>Cancelar</button></div>
            <div className="col-6 pr-0"><button type="button" className="btn btn-primary btn-block" onClick={ ()=> generatePackManualUpload() }>Generar pedido</button></div>
          </div>
        </Modal.Footer>
      </Modal>

      <div id="date-picker"></div>
      <Navbar module="PackBuilder" title="Arma Pack" navBarName="Carga Arma Packs"/>
      <div className="container body-height">
        <div className='row pt-4 pb-4'>
          <div className='col-4'>
          </div>
          <div className='col-4 text-center'>
            <label className="title text-center">Carga Arma Packs</label><br />
            <label className="subtitle">Bodega { getWarehouse() }</label>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <label className="subtitle mr-3">mes:</label>
            <select className="select" name="select-pack_date" id="select-pack_date" 
                                       onChange={(e) => {filterByMonth(e.target.value);}}>
              {months.map((month) => <option key={month} value={month == 'current_month' ? '' : month}>{es['PackBuilder']['Months'][month]}</option>)}
            </select>
          </div>
          <div className='col-6'>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-8'/>
          <div className='col-2'>
            <button type="button" className="btn btn-primary btn-block" onClick={ () => fileUploadModal() }> Cargar Archivo </button>
          </div>
          <div className='col-2'>
            <button type="button" className="btn btn-primary btn-block" onClick={ () => manualUploadModal() }> Cargar Manual </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <section>
              <table className="table entry-picking-table">
                <thead>
                  <tr>
                    <td>Nro.</td>
                    <td>SKU</td>
                    <td>Nombre</td>
                    <td>Cantidad Requerida</td>
                    <td>Cantidad Armable</td>
                    <td>Fecha de Creación</td>
                    <td>Acción</td>
                  </tr>
                </thead>
                <tbody>
                  {documents
                    ? documents.map((item) => {
                      myIndex +=1
                      return (
                          <tr>
                            <td>{ myIndex }</td>
                            <td>{ item.attributes.sku }</td>
                            <td>{ item.attributes.gloss }</td>
                            <td>{ item.attributes.quantity }</td>
                            <td>{ item.attributes.buildable_quantity }</td>
                            <td>{ item.attributes.created_at }</td>
                            <td>{ <button className='btn btn-primary' onClick={() => { packRequestModal(item) }}>Solicitar</button> }</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

// Map State to Props
// 
// Get the data from the api
// And map the response to a readable variable
const mapStateToProps = (state, props) => {
  return {
    PackBuilderDocumentResponse: state.documentPackBuilderReducers.uploadPackBuilderDocuments,
    AvailableMonthsResponse: state.documentPackBuilderReducers.packBuilderMonthsResponse.res,
    GeneratePackRequestResponse: state.documentPackBuilderReducers.generatePackRequestDocument,
    PackBuilderUploadFileResponse: state.documentPackBuilderReducers.fileUploadResponse,
    PackBuilderUploadManualResponse: state.documentPackBuilderReducers.manualUploadResponse
  };
};

// Export the component with its actions
export default connect(mapStateToProps, {
  PackBuilderUploadDocumentsAction,
  GeneratePackRequestAction,
  PackBuilderMonthsAction,
  PackBuilderUploadFileAction,
  PackBuilderManualUploadAction
})(PackBuilder);
