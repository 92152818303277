import { call, put, takeEvery } from "redux-saga/effects";
import Constants from "../../utils/Constants";
import DocumentService from "../../API/services/DocumentDeliveryService";

function* documentToDeliverySearch(action) {
  let targetPage = action.payload === undefined ? 1 : action.payload.current_page
  try {
    yield put({
      type: Constants.showLoadingScreen,
      payload: true,
    });
    const data = yield call(
      DocumentService.documentToDeliverySearch,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentToDeliverySearchAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentToDeliverySearchAction,
      payload: data,
    });
    yield put({
      type: Constants.showLoadingScreen,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentToDeliverySearchAction,
      payload: e.message,
    });
  }
}

function* preparationTransferFilter(action){
  let targetPage = action.payload === undefined ? 1 : action.payload.current_page
  try {
    yield put({
      type: Constants.showLoadingScreen,
      payload: true,
    });
    const data = yield call(
      DocumentService.preparationFilterSearch,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchPreparationFilter,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchPreparationFilter,
      payload: data,
    });
    yield put({
      type: Constants.showLoadingScreen,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchPreparationFilter,
      payload: e.message,
    });
  }
}

function* preparationMovementSearch(action){
  let targetPage = action.payload === undefined ? 1 : action.payload.current_page
  try {
    yield put({
      type: Constants.showLoadingScreen,
      payload: true,
    });
    const data = yield call(
      DocumentService.preparationMovementSearch,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchPreparationMovement,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchPreparationMovement,
      payload: data,
    });
    yield put({
      type: Constants.showLoadingScreen,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchPreparationMovement,
      payload: e.message,
    });
  }
}

function* documentCompleted(action) {
  let targetPage = action.payload === undefined ? 1 : action.payload.current_page
  try {
    yield put({
      type: Constants.showLoadingScreen,
      payload: true,
    });
    const data = yield call(
      DocumentService.packingCompleted,
      targetPage
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentCompleted,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentCompleted,
      payload: data,
    });
    yield put({
      type: Constants.showLoadingScreen,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: Constants.showLoadingScreen,
      payload: false,
    });
    yield put({
      type: Constants.FailFetchDocumentCompleted,
      payload: e.message,
    });
  }
}

function* documentCompletedSearch(action) {
  let search = action.payload === undefined ? 1 : action.payload.search
  let targetPage = action.payload === undefined ? 1 : action.payload.current_page
  try {
    yield put({
      type: Constants.showLoadingScreen,
      payload: true,
    });
    const data = yield call(
      DocumentService.documentCompletedSearch,
      search,
      targetPage
    );
    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentCompleted,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentCompleted,
      payload: data,
    });
    yield put({
      type: Constants.showLoadingScreen,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: Constants.showLoadingScreen,
      payload: false,
    });
    yield put({
      type: Constants.FailFetchDocumentCompleted,
      payload: e.message,
    });
  }
}

function* documentDeliveryComplete(action) {
  try {
    const data = yield call(
      DocumentService.documentDeliveryComplete,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentDeliveryComplete,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentDeliveryComplete,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentDeliveryComplete,
      payload: e.message,
    });
  }
}

export function* documentDeliverySaga() {
  // yield takeEvery(Constants.documentDeliveryCompleteAction, documentDeliveryComplete);
  yield takeEvery(Constants.preparationMovementSearchAction, preparationMovementSearch)
  yield takeEvery(Constants.preparationFilterAction, preparationTransferFilter)
  yield takeEvery(Constants.documentToDeliverySearchAction, documentToDeliverySearch)
  yield takeEvery(Constants.documentCompletedSearch, documentCompletedSearch)
  yield takeEvery(Constants.documentPackingComplete, documentCompleted)
}
