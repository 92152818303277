import React from "react";
import { connect } from "react-redux";
import { DocumentPendingAction } from "../redux/actions/documentPicking.actions";
import Pusher from "pusher-js";

let pusher = new Pusher("cc1115390387d929c4df", {
  cluster: "us2",
});

function Application({ DocumentPendingAction, children }) {
  let channel = pusher.subscribe("picking");
  channel.bind("document-taken", function (data) {
    DocumentPendingAction();
  });
  return <div>{children}</div>;
}

export default connect(null, { DocumentPendingAction })(Application);
