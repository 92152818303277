import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import {
  DocumentToPackAction,
  ChangeStatusToDeliveredWithNoLabel,
  ChangeStatusToDeliveryAction,
  DocumentPackingListAction,
} from "../../redux/actions/documentPacking.actions";
import { SavePackingAction, EditPackingAction, RemovePackageAction } from "../../redux/actions/productPacking.actions";
import InputNumber from "react-input-just-numbers";
import Navbar from "../../components/navbar"
import { authenticateUser, validateToken } from "../../utils/Commons.js";

/*Component o Page*/
function PackDetailReadOnly({
  response,  
  DocumentPackingListAction,
  DocumentToPackAction,
  SavePackingAction,
  EditPackingAction,
  RemovePackageAction,
  ChangeStatusToDeliveredWithNoLabel,
}) {
  const { id } = useParams();
  let history = useHistory();
  const [detailDocument, setDetailDocument] = useState([]);
  const [rows, setRows] = useState([]);
  const [tables, setTables] = useState([1]);
  const [inputQuantity, setInputQuantity] = useState(0);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(false);
  const [orderPackages, setOrderPackages] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [currentPackage, setCurrentPackage] = useState(1);
  const [lastPackageNumber, setLastPackageNumber] = useState(1);
  const [newTable, setNewTable] = useState(false);
  const state = useSelector(state => state.documentPackingReducers.documentToPackResponse.res);

  useEffect(() => {
    DocumentToPackAction({ id });
  }, []);

  // If user is already login in, verify if the user has permission in the Packing module.
  // If user is not loged in, validates its token and if user has permission in the Packing module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Packing')
    }else{
      validateToken(localStorage.getItem("token"),'Packing')
    }
  })

  useEffect(() => {
    if (
      !state ||
      !state.attributes ||
      !state.attributes.order_products
    ) {
      return;
    }
    //guardar productos
    setOrderProducts(state.attributes.order_products)
    // get the last pack used by the document
    if (state.attributes.order_packages.length > 0) {
      const lastPackUsed = state.attributes.order_packages.reduce(function (
        prev,
        current
      ) {
        return Number(prev.package_number) > Number(current.package_number)
          ? prev
          : current;
      });
      setOrderPackages(state.attributes.order_packages)
      state.attributes.order_packages.map((orderPackage) => {
        orderPackage.products.map( (product) => {
          const item = {
            pack: Number(orderPackage.package_number),
            barcode: product.barcode,
            stock: product.amount,
            id: Number(product.line_number),
            description: product.description,
            weight: product.weight,
            volume: product.volume
          };
          setRows((prevState) => {
            return [...prevState, item];
          });
        }) 
      })
      setCurrentPackage(Number(lastPackUsed.package_number))
      setLastPackageNumber(Number(lastPackUsed.package_number))
      setTables([Number(lastPackUsed.package_number) + 1]);
    } else {
      setTables([1]);
    }
  }, [state]);

  const onBack = () => {
    history.push(`/completedPackingList`);
  };

  const savePacking = (item) => {
    SavePackingAction(item);
    setNewTable(false);
    getPackingList();
  };

  const getPackingList = () => {
    DocumentPackingListAction({ id });
  };

  const handleAddRow = (
    barcode,
    stock,
    description,
    weight,
    width,
    lenght,
    height,
    volume
  ) => {
    const item = {
      pack: currentPackage,
      barcode: barcode,
      stock: stock,
      id: id,
      description: description,
      weight: weight,
      width: width,
      lenght: lenght,
      height: height,
      volume: volume,
    };
    const documentToUpdate = orderProducts.find((item) => item.barcode === barcode);
    if(stock < 0 || reducePackedStock(barcode, documentToUpdate.stock) < stock){return;}

    savePacking(item)
  };

  const addPackage = () => {
    const validate = rows.filter(
      (item) => item.pack === tables[tables.length - 1]
    );
    setNewTable(true);
    setCurrentPackage(lastPackageNumber+1);

    if (typeof validate !== "undefined" && validate.length > 0) {
      setTables((prevState) => {
        return [...prevState, tables[tables.length - 1] + 1];
      });
    }
  };

  const removePackage = (bulk) => {
    let payload = {
      id: id,
      package_id: bulk.id,
    }
    RemovePackageAction(payload);
  }

  function editPackage(line, packageNumber){
    const inputDiv = document.querySelector(`.input-product-${line}-${packageNumber}`)
    const editDiv = document.querySelector(`.edit-product-${line}-${packageNumber}`)
    const amountDiv = document.querySelector(`.input-amount-${line}-${packageNumber}`)
    const amountEditDiv = document.querySelector(`.edit-amount-${line}-${packageNumber}`)

    inputDiv.classList.remove('d-none')
    inputDiv.classList.add('d-flex')
    editDiv.classList.add('d-none')
    amountDiv.classList.remove('d-none')
    amountEditDiv.classList.add('d-none')
  }

  const saveEditPackage = (pId, line, packageNumber) => {
    // const newPackage = document.querySelector(`#input-package-product-${line}-${packageNumber}`).value
    const newAmount = document.querySelector(`#input-amount-product-${line}-${packageNumber}`).value
    let payload = {
      id: id,
      package_product_id: pId,
      quantity: newAmount
    }
    EditPackingAction(payload);
    cancelEditPackage(line, packageNumber)
  }

  const cancelEditPackage = (line, packageNumber) => {
    const inputDiv = document.querySelector(`.input-product-${line}-${packageNumber}`)
    const editDiv = document.querySelector(`.edit-product-${line}-${packageNumber}`)
    const amountDiv = document.querySelector(`.input-amount-${line}-${packageNumber}`)
    const amountEditDiv = document.querySelector(`.edit-amount-${line}-${packageNumber}`)

    inputDiv.classList.add('d-none')
    inputDiv.classList.remove('d-flex')
    amountDiv.classList.add('d-none')
    amountEditDiv.classList.remove('d-none')
    editDiv.classList.remove('d-none')
  }

  const finishWithNoLabel = () => {
    ChangeStatusToDeliveredWithNoLabel(id);
    history.push(`/documentPackingListReadOnly/${id}`);
  };

  function goLabel() {
    history.push(`/labelReadOnly/${id}`);
  }

  const reducePackedStock = (barcode, stock) => {
    let result = 0;
    orderPackages.map((bulk) => {
      bulk.products.map((product) => {
        if(product.barcode === barcode){
          result += product.amount
        }
      })
    })
    return stock - result;
  };

  return (
    <div>
      <Navbar module="Packing" title="Packing" navBarName='Embultado'/>
      <Container className="body-height">
        <div className="row containerPackingButtons">
          <div className="col-12">
            <div className="row">
              <div className="col-2">
                <button className="btn btn-outline-primary btn-block" onClick={() => {onBack();}}>
                  Volver
                </button>
              </div>
              <div className="col-2">
                <button className="btn btn-primary btn-block" onClick={() => {goLabel(id);}}>
                  Ver etiqueta
                </button>
              </div>
              <div className="col-5 text-center">
                <h2>Embalaje</h2>
              </div>
                  
              <div className="col-3">
                <button onClick={() => { finishWithNoLabel();}}
                    className={
                      (!detailDocument || detailDocument) &&
                      Number(detailDocument.length) === 0
                        ? "buttonEnabled btn btn-primary btn-block"
                        : "buttonDisabled btn btn-primary btn-block"
                    }
                    disabled={
                      (!detailDocument || detailDocument) &&
                      Number(detailDocument.length) === 0
                        ? ""
                        : "true"
                    }>
                    Packing List
                </button>
              </div>
            </div>
          </div>
        </div>
        
        

        <Row>
          <Col sm={12}>
            {response && response.attributes ? (
              <>
                <label className="documentData mr-3">
                  Número de Documento : {response.attributes.document_number}
                </label>
                <label className="documentData">
                  Tipo de Documento : {response.attributes.document_type}
                </label>
              </>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <table className="dataList">
              <tbody>
                {orderProducts
                  ? orderProducts.map((item, index) => {
                      if(reducePackedStock(item.barcode, item.stock) <= 0){return}
                      if(item.product.product_type !== 'EXISTENCIA') {return}
                      return (
                        <tr key={index}>
                          <td style={{ width: "10px" }}>{index + 1}.-</td>
                          <td style={{ width: "400px" }}>
                            {item.product.gloss}
                          </td>
                          <td style={{ paddingLeft: "2px", width: "100px" }}>
                            {" "}
                            {item.stock > 1
                              ? reducePackedStock(item.barcode, item.stock) + " Unidades"
                              : item.stock + " Unidad"}
                          </td>
                          <td style={{ paddingLeft: "2px", width: "150px" }}>
                            <input
                              type="radio"
                              value="complete"
                              name="product_quantity"
                              checked={0}
                              onChange={(e) =>
                                handleAddRow(
                                  item.barcode,
                                  reducePackedStock(item.barcode, item.stock),
                                  item.product.gloss,
                                  item.product.barcode,
                                  item.product.logistic_weight,
                                  item.product.logistic_width,
                                  item.product.logistic_lenght,
                                  item.product.logistic_height,
                                  item.product.logistic_volume
                                )
                              }
                            />
                            Embultar Todo
                          </td>
                          <td style={{ width: "150px" }}>
                            <input
                              type="radio"
                              value="partial"
                              name="product_quantity"
                              onClick={() => {
                                setSelectedRow(index);
                                setSelectedItem(true);
                              }}
                              checked={
                                selectedRow === index && selectedItem
                                  ? true
                                  : false
                              }
                            />
                            Editar Cantidad
                          </td>

                          <>
                            <td style={{ width: "100px" }}>
                              {selectedRow === index && selectedItem ? (
                                <InputNumber
                                  style={{ width: "40px" }}
                                  type="number"
                                  name={index}
                                  min="0"
                                  max={item.stock}
                                  pattern={"[0-9]"}
                                  onBlur={(e) => {
                                    const partialStock = e.target.value;
                                    setInputQuantity(partialStock);
                                  }}
                                />
                              ) : null}
                            </td>
                            <td>
                              {selectedRow === index && selectedItem ? (
                                <button
                                  className="AcceptButtom"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    handleAddRow(
                                      item.barcode,
                                      inputQuantity,
                                      item.product.gloss,
                                      item.product.barcode,
                                      item.product.logistic_weight,
                                      item.product.logistic_width,
                                      item.product.logistic_lenght,
                                      item.product.logistic_height,
                                      item.product.logistic_volume
                                    );
                                  }}
                                >
                                  Aceptar
                                </button>
                              ) : null}
                            </td>
                          </>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </Col>
        </Row>
        <div className='row'>
          <div className='col-6 justify-content-beginning'>
            <h4 id="h6">Información por Bulto </h4>
          </div>
          <div className='col-6 d-flex justify-content-end'>
            
          </div>
        </div>
      <div className="row">
        {(orderPackages.length < 1 || newTable) && 
          
            <div className="col-12 order-1">
              <div>
                <label className="documentData">Bulto {currentPackage}</label>

                <table id="packTable" className="newPackTable">
                  <tbody>
                    <tr>
                      <td>Ítem</td>
                      <td>Descripción</td>
                      <td>Cantidad</td>
                      <td>Peso (kg)</td>
                      <td>Ancho (cms)</td>
                      <td>Largo (cms)</td>
                      <td>Alto (cms)</td>
                      <td>Volumen (m3)</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        }
          
        {(orderPackages && 
        orderPackages.map((bulk, index) => {
          return(
            
            <div key={index} className={`col-12 order-${orderPackages.length - index +1}`}>
              <div>
                <div className='d-flex justify-content-between'> 
                  <label className="documentData">Bulto {bulk.package_number}</label>
                  
                  
                </div>
                
                <div className='table-responsive'>
                <table id="packTable" className="newPackTable ">
                  <tbody>
                    <tr >
                      <td>Ítem</td>
                      <td>Descripción</td>
                      <td>Cantidad</td>
                      <td>Peso (kg)</td>
                      <td>Ancho (cms)</td>
                      <td>Largo (cms)</td>
                      <td>Alto (cms)</td>
                      <td>Volumen (m3)</td>
                    </tr>
                    {bulk.products &&
                      bulk.products.map((product) => {
                        return (
                          <tr key={product.line_number}>
                            <td>{product.barcode}</td>
                            <td>{product.description}</td>
                            <td>
                              <div className={`d-none input-amount-${product.line_number}-${bulk.package_number} justify-content-center`} >
                                <input className='edit-amount-input' id={`input-amount-product-${product.line_number}-${bulk.package_number}`} name={`input-amount-product-${product.line_number}-${bulk.package_number}`}/>
                              </div>
                              <div className={`edit-amount-${product.line_number}-${bulk.package_number}`}>
                                {product.amount}
                              </div>
                              
                            </td>
                            <td>{product.weight}</td>
                            <td>{product.logistic_width}</td>
                            <td>{product.logistic_lenght}</td>
                            <td>{product.logistic_height}</td>
                            <td>{product.volume}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                </div>
                
              </div>
            </div>
          );
        }))}
      </div>
        <Row>
          <Col>
            {orderPackages &&
            
              <div>
                <label className="documentData">Suma total por bulto</label>

                <table className="newPackTableResume">
                  <tbody>
                    <tr>
                      <td>Bulto</td>
                      <td>Peso (kg)</td>
                      <td>Volumen (m3)</td>
                    </tr>
                    {orderPackages
                      .map((itemPackages) => {
                        return (
                          <tr>
                            <td>{itemPackages.package_number}</td>
                            <td>{itemPackages.package_weight}</td>
                            <td>{itemPackages.package_volume}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
}
/*Methodo*/
const mapStateToProps = (state, props) => {
  return {
    response: state.documentPackingReducers.documentToPackResponse.res,
    responseProduct: state.productReducers.saveProductPackResponse.res,
    responseEditPack: state.productReducers.editProductPackResponse.res,
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentToPackAction,
  DocumentPackingListAction,
  SavePackingAction,
  EditPackingAction,
  RemovePackageAction,
  ChangeStatusToDeliveredWithNoLabel,
  ChangeStatusToDeliveryAction,
})(PackDetailReadOnly);
