import Constants from "../../utils/Constants";

export const DocumentPendingToPackAction = (payload) => {
  return {
    type: Constants.documentPendingToPackAction,
    payload,
  };
};

export const DocumentLocationAction = (payload) => {
  return {
    type: Constants.documentLocationAction,
    payload,
  };
};

export const DocumentSearchAction = (payload) =>{
  return {
    type: Constants.documentSearchAction,
    payload,
  }
}

export const ChangePreparationLocationAction = (payload) => {
  return {
    type: Constants.changePreparationLocationAction,
    payload,
  };
};

export const StorePreparationLocationAction = (payload) => {
  return {
    type: Constants.storePreparationLocationAction,
    payload,
  };
};

export const StorePackageLocationAction = (payload) => {
  return {
    type: Constants.storePackageLocationAction,
    payload,
  };
};

export const ChangeStatusToDeliveryAction = (payload) => {
  return {
    type: Constants.changeStatusToDeliveryAction,
    payload,
  };
};
export const ChangeStatusToPackAction = (payload) => {
  return {
    type: Constants.changeStatusToPackAction,
    payload,
  };
};

export const ChangeStatusToDeliveredWithNoLabel = (payload) => {
  return {
    type: Constants.changeStatusToDeliveredWithNoLabelAction,
    payload,
  };
};
export const DocumentsPendingToLabelAction = (payload) => {
  return {
    type: Constants.documentsPendingToLabelAction,
    payload,
  };
};

export const DocumentsPendingToDeliveryAction = (payload) => {
  return {
    type: Constants.documentsPendingToDeliveryAction,
    payload,
  };
};

export const DocumentPackingListAction = (payload) => {
  return {
    type: Constants.documentPackingListAction,
    payload,
  };
};

export const DocumentPublicPackingListAction = (payload) => {
  return {
    type: Constants.documentPublicPackingListAction,
    payload,
  };
};
export const DocumentToPackAction = (payload) => {
  return {
    type: Constants.documentToPackAction,
    payload,
  };
};
export const DocumentLabelAction = (payload) => {
  return {
    type: Constants.documentLabelAction,
    payload,
  };
};
