import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PendingPackRequestListAction, PackRequestPrioritizationAction, PackRequestUnselectAction, PackRequestDeleteAction } from "../../redux/actions/documentPackBuilder.actions";
import { ToastContainer, toast } from 'react-toastify';
import { getWarehouse, authenticateUser, validateToken } from "../../utils/Commons"
import es from '../../es.json'
import Navbar from "../../components/navbar"
import logo from "../../img/logo.png";
import dragAndDropIcon from "../../img/DragAndDrop.svg";
import close from "../../img/close2.svg"
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';

/*Component o Page*/
function PackRequestList({
  PendingPackRequestListAction,
  PackRequestPrioritizationAction,
  PackRequestUnselectAction,
  PackRequestDeleteAction,
  packPrioritizationResponse,
  pendingPackListResponse,
  unselectPackRequestResponse,
  deletePackRequestResponse
}) {

  // Initial DnD State
  // 
  // Initial state for the drag and drop function
  // set the constructor to blank
  // 
  // @return [Hash] with the Drag and drop object variables
  const initialDnDState = {
    draggedId: null,
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
  };

  const [loaderStatus, setLoaderStatus] = useState(true)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState('')
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
  
  // Index used for table redendering
  // And drag and drop movement
  let myIndex = 0

  // If user is already login in, verify if the user has permission in the Entry module.
  // If user is not loged in, validates its token and if user has permission in the Entry module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Packing')
    }else{
      validateToken(localStorage.getItem("token"),'PackList')
    }
  })

  // Get the pending reception list on page load
  useEffect(() => {
    if(localStorage.getItem("token") !== null){
      PendingPackRequestListAction();
    }
  }, []);

  useEffect(() => {
    if (unselectPackRequestResponse === undefined) { return }
    toast.success('Documento liberado.')
    PendingPackRequestListAction()
    setLoaderStatus(false)
  }, [unselectPackRequestResponse])

  useEffect(() => {
    if (deletePackRequestResponse === undefined) { return }
    toast.success('Documento Eliminado.')
    PendingPackRequestListAction()
    setLoaderStatus(false)
  }, [deletePackRequestResponse])

  // Set the documents from the response
  // into the documents state for table rendering
  useEffect(() => {
      setLoaderStatus(true)
      if (!pendingPackListResponse) return;
      setDocuments(pendingPackListResponse.res);
      setLoaderStatus(false)
  }, [pendingPackListResponse]);

  
  useEffect(() => {
    if (!packPrioritizationResponse) return;
    PendingPackRequestListAction()
    setLoaderStatus(false)
  }, [packPrioritizationResponse]);

  // Promote top ten
  // 
  // @param id [Integer] the id of the document to move
  // @param position [Integer] the final position of the document
  // Send the payload to the api to promote the document
  // 
  // @return [Void]
  const promoteTopTen = (id, position) => {
    const payload = {
      "data": {
        "id": id,
        "attributes": {
          "priority": position
        }
      }
    }
    PackRequestPrioritizationAction(payload);  
    setLoaderStatus(true)  
  };

  // Drag and drop
  //
  // Drag and drop function
  // @params event [Object] the object dragged
  // Store the dragged item position
  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    const selectedId = Number(event.currentTarget.dataset.id);
    event.dataTransfer.setData("text/html", "");
    return setDragAndDrop({
      draggedFrom: initialPosition,
      draggedId: selectedId,
      draggedTo: null,
      isDragging: true,
      originalOrder: documents,
      updatedOrder: []
    });
    
  };

  // On Drag Over
  // 
  // Store the hover location as final position
  const onDragOver = (event) => {
    event.preventDefault();
    let newList = dragAndDrop.originalOrder;
    let draggedFrom = dragAndDrop.draggedFrom;
    let draggedTo = Number(event.currentTarget.dataset.position);
    draggedTo = draggedTo > draggedFrom ? draggedTo + 1 : draggedTo;
    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    );
    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: draggedTo,
      updatedOrder: newList
    });
  };

  // On Drop
  // 
  // Send the payload to the api to promote the document
  // Reset the drag and drop state
  const onDrop = (event) => {
    // The new priority is calculated form the priority of the element
    if (dragAndDrop.updatedOrder.length > 0) {
      handleSendData()
    }
    // Reset value
    setDragAndDrop({
      draggedFrom: null,
      draggedTo: null,
      draggedId: null,
      isDragging: false,
      originalOrder: [],
      updatedOrder: []
    });
  };

  // Handle send data
  // 
  // Send the payload to the api to promote the document
  // Get the item from the list with the draggedFrom position
  // Build the payload with the item id and the draggedTo Position
  // then send the payload to the api
  // 
  // @return [Void]
  const handleSendData = () => {
    const payload = {
      "data": {
        "id": dragAndDrop.draggedId,
        "attributes": {
          "priority": dragAndDrop.draggedTo
        }
      }
    }
    PackRequestPrioritizationAction(payload);
    setLoaderStatus(true)
  }

  // Pack Request State
  // 
  // Obtains the pack requests state.
  // @returns [String] With the pack request information.
  const packRequestState = (item) => {
    return es['PackRequest'][item.aasm_state];
  }

  // Pack Request Taken By
  // 
  // Obtain the current operator for correct state
  // @returns [String] With the pack request operator by correct state.
  const packRequestTakenBy = (item) => {
    switch (item.aasm_state) {
      case "picking":
        return item.picked_by;
      case "transferring":
        return item.transfered_by;
      case "building":
        return item.builded_by;
      case "verifying":
        return item.verified_by;
      default:
        return "Disponible";
    }
  }


  // Is Document Taken
  // Function to verify if the document is taken. Used to render the unselect button
  // @returns [Boolean] depending if the document is taken or not.
  const isDocumentTaken  = (item) => {
    return item.queue_state === 'taken'
  }

  // Is Document Taken
  // Function to verify if the document is taken. Used to render the unselect button
  // @returns [Boolean] depending if the document is taken or not.
  const packKind  = (aasm_state) => {
    switch (aasm_state) {
      case "picking":
        return "desk_transfer";
      case "transferring":
        return "transfer_pack";
      case "building":
        return "integration";
      case "verifying":
        return "warehouse_transfer";
      default:
        return "undefined_kind";
    }
  }

  //  Unselect Document
  //
  // Communicates with api and unselect the selected document
  // @returns [Null]
  const unselectDocument = (documentId, documentKind) => {
    let payload = {
      id: documentId,
      kind: documentKind,
    }
    PackRequestUnselectAction(payload)
    setLoaderStatus(true)
  }

  const packRequestDelete = (documentId) => {
    PackRequestDeleteAction(documentId)
    hidePackRequestDeleteModal()
    setLoaderStatus(true)
  }

  const showPackRequestDeleteModal = () => {
    setIsOpenDeleteModal(true);
  }

  const hidePackRequestDeleteModal = () => {
    setIsOpenDeleteModal(false);
  }
  
  const packRequestModal = (packUpload) => {
    setSelectedDocument(packUpload)
    showPackRequestDeleteModal()
  }


  return (
    <div className="section">
      <ToastContainer />
      { loaderStatus ?
        <div className='loading-modal'>
          <div className='loading-modal-logo'>
            <figure>
              <img className='rotation-zoom' src={logo} alt="hbt logo" />
				    </figure>
          </div>
        </div>
        :
        null
      }
      <div id="date-picker"></div>

      <Modal show={ isOpenDeleteModal } onHide={ hidePackRequestDeleteModal } size='md'>
        <Modal.Header className="d-block">
          <div className="row">
            <div className="col-10">
              <h4> Eliminación de solicitud de Pack </h4>
            </div>
            <div className="col-2 text-right">
              <img className="close-button" src={ close } alt="close" onClick={ hidePackRequestDeleteModal }/>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {
            selectedDocument? 
              <div className='row'>
                <div className="col">
                  <dl>
                    <dt> SKU </dt>
                      <dd> { selectedDocument.attributes.sku } </dd>
                    <dt> Nombre </dt>
                      <dd> { selectedDocument.attributes.name } </dd>
                    <dt> Cantidad </dt>
                      <dd> { selectedDocument.attributes.quantity } </dd>
                    <dt> Solicitado por</dt>
                      <dd> { selectedDocument.attributes.requested_by} </dd>
                    <dt> Estado </dt>
                      <dd> { packRequestState(selectedDocument.attributes) } </dd>
                  </dl>
                </div>
              </div> 
            :
            null
          }
        </Modal.Body>
        <Modal.Footer className="modal-footer-container">
          {
            selectedDocument? 
              <div className="row">
                <div className="col-6 pl-0"><button type="button" className="btn btn-secondary btn-block" onClick={ hidePackRequestDeleteModal }>Cancelar</button></div>
                <div className="col-6 pr-0"><button type="button" className="btn btn-primary btn-block" onClick={ () => packRequestDelete(selectedDocument.id) }> Eliminar Pack </button></div>
              </div>
            :
              null
          }
        </Modal.Footer>
      </Modal>

      <Navbar module="PackBuilder" title="Arma Pack" navBarName="Administrador de cola"/>
      <div className="row mx-5">
        <div className='col-12'>
        
          <div className='row pt-4 pb-4'>
            <div className='col-4'>
            </div>
            <div className='col-4 text-center'>
              <label className="title text-center">Administrador de cola</label><br />
              <label className="subtitle">Bodega { getWarehouse() }</label>
            </div>
          </div>
          <div className='row mx-5'>
            <div className='col-12'>
              <table className="table entry-picking-table pack-admin">
                <thead>
                  <tr>
                    <td></td>
                    <td>Número</td>
                    <td>SKU</td>
                    <td>Nombre</td>
                    <td>Cantidad Solicitada</td>
                    <td>Pedido por</td>
                    <td>Estado</td>
                    <td>Tomado por</td>
                    <td className='text-center' colSpan='2'>Acciones</td>
                    <td>Priorizar</td>
                  </tr>
                </thead>
                <tbody>
                  { documents
                    ? documents.map((item) => {
                      myIndex +=1
                      return (
                          <tr>
                            <td key={myIndex}
                            data-position={item.attributes.priority}
                            data-id={item.id}
                            draggable
                            onDragStart={(e) => onDragStart(e, false)}
                            onDragOver={(e) => onDragOver(e, false)}
                            onDrop={(e) => onDrop(e, false)}
                            className='dropArea'><img src={dragAndDropIcon} alt="DragAndDrop" /></td>
                            <td>{myIndex}</td>
                            <td>{ item.attributes.sku }</td>
                            <td>{ item.attributes.name }</td>
                            <td>{ item.attributes.quantity }</td>
                            <td>{ item.attributes.requested_by }</td>
                            <td>{ packRequestState(item.attributes) }</td>
                            <td>{ packRequestTakenBy(item.attributes) }</td>
                            <td> <button className='btn btn-danger' onClick={() => {packRequestModal(item)}}> Eliminar </button> </td>
                            <td>{ isDocumentTaken(item.attributes) ? <button className='btn btn-primary' onClick={() => {unselectDocument(item.id,packKind(item.attributes.aasm_state))}}> Liberar </button> : null }</td>
                            <td>{ myIndex > 1 ? <button className='btn btn-primary' onClick={() => {promoteTopTen(item.id, 1)}}> Priorizar </button> : null }</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Map State to Props
// 
// Get the data from the api
// And map the response to a readable variable
const mapStateToProps = (state, props) => {
  return {
    pendingPackListResponse: state.documentPackBuilderReducers.packRequestDocuments,
    packPrioritizationResponse: state.documentPackBuilderReducers.prioritizePackRequestResponse,
    unselectPackRequestResponse: state.documentPackBuilderReducers.unselectPackRequestResponse,
    deletePackRequestResponse: state.documentPackBuilderReducers.deletePackRequestResponse
  };
};

// Export the component with its actions
export default connect(mapStateToProps, {
  PendingPackRequestListAction,
  PackRequestPrioritizationAction,
  PackRequestUnselectAction,
  PackRequestDeleteAction
})(PackRequestList);
