import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DocumentListAction,
  TakenDocumentCountAction,
  DocumentPrioritizationAction,
  DocumentFilterAction,
  ReleaseTakenDocumentAction,
  unselectTakenDocumentAction,
} from '../../redux/actions/documentAdmin.actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Container, Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import Navbar from '../../components/navbar';
import {
  getWarehouse,
  getEmail,
  getName,
  authenticateUser,
  validateToken,
} from '../../utils/Commons';
import logo from '../../img/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DocumentService from '../../API/services/DocumentAdminService';

/*Component o Page*/
function Document({
  ReleaseTakenDocumentAction,
  DocumentListAction,
  TakenDocumentCountAction,
  responseFilter,
  response,
  DocumentFilterAction,
  DocumentPrioritizationAction,
  takenDocumentsCount,
  unselectTakenDocumentAction,
  fetchDocumentPrioritizationResponse,
  fetchDocumentListResponse,
  fetchDocumentFilterResponse,
}) {
  //console.log(fetchDocumentPrioritizationResponse, 'Prioritization Response')
  //console.log(fetchDocumentListResponse, 'List Response')

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filteredBy, setFilteredBy] = useState('');
  const [deliveredBy, setDeliveredBy] = useState('');
  const [top10, setTop10] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [takenDocuments, setTakenDocuments] = useState('');
  const [takenDocumentsDetail, setTakenDocumentsDetail] =
    useState('');
  const [dateSelected, setDateSelected] = useState(false);

  let history = useHistory();
  let myIndex = -1;

  useEffect(() => {
    if (localStorage.getItem('token')) {
      validateRole();
      DocumentListAction();
      TakenDocumentCountAction();
    }
  }, []);

  // If user is already login in, verify if the user has permission in the Admin module.
  // If user is not loged in, validates its token and if user has permission in the Admin module.
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      authenticateUser('Admin');
    } else {
      validateToken(localStorage.getItem('token'), 'Admin');
    }
  });
  // **
  // Unification useEffect dependent of response
  useEffect(() => {
    // Define local variables
    let top10;
    let filterTop10;
    let finalFilterDocuments;
    let filteredResponse;
    if (!response) return;

    // Filter the response
    filteredResponse = response.filter(
      (item) =>
        item.attributes.total_lines !== 0 ||
        item.attributes.total_products !== 0
    );
    filteredResponse = filteredResponse.filter(
      (item) => item.attributes.document_type !== 'NOTA DE VENTA'
    );

    if (!filteredResponse) return;

    // Get the top10
    top10 = filteredResponse.splice(0, 10);
    // Store in the hook top10
    setTop10(top10);

    setDocuments(filteredResponse);
    if (dateSelected) {
      filterType(startDate, endDate, filteredBy, deliveredBy);
    } else {
    }
  }, [response]);

  // Unification useEffect dependent of responseFilter
  useEffect(() => {
    if (!responseFilter) return;
    let filterDocuments = [];
    top10.map((itemTop10) => {
      if (filterDocuments.length > 0) {
        filterDocuments = filterDocuments.filter(
          (item, index) =>
            item.attributes.id !== itemTop10.attributes.id
        );
      } else {
        filterDocuments = responseFilter.filter(
          (item, index) =>
            item.attributes.id !== itemTop10.attributes.id
        );
      }
    });
    filterDocuments = filterDocuments.filter(
      (item) =>
        item.attributes.total_lines !== 0 ||
        item.attributes.total_products !== 0
    );
    filterDocuments = filterDocuments.filter(
      (item) => item.attributes.document_type !== 'NOTA DE VENTA'
    );
    setDocuments(filterDocuments);
  }, [responseFilter]);
  // **

  useEffect(() => {
    if (fetchDocumentPrioritizationResponse) return;
    if (!fetchDocumentPrioritizationResponse) {
      DocumentListAction();
    }
  }, [fetchDocumentPrioritizationResponse]);

  useEffect(() => {
    if (!takenDocumentsCount) return;
    console.log('Taken Documents', takenDocumentsCount.attributes);
    let documentHash =
      takenDocumentsCount.attributes.documents_detail;
    let tooltipData = '';
    for (var doc in documentHash) {
      tooltipData += `${doc}-${documentHash[doc]}\n`;
    }
    setTakenDocuments(takenDocumentsCount.attributes.total_taken);
    setTakenDocumentsDetail(tooltipData);
  }, [takenDocumentsCount]);

  const validateRole = () => {
    let invalid_roles = ['none', 'basic', 'operator'];
    let role = localStorage.getItem('role');
    if (invalid_roles.includes(role)) {
      localStorage.setItem('module', 'Packing');
      history.push('/');
    } else {
      return;
    }
  };

  const filterType = (
    startDate,
    endDate,
    filtered_By,
    delivered_By
  ) => {
    const data = {
      startDate: startDate,
      endDate: endDate,
      filteredBy: filtered_By,
      deliveredBy: delivered_By,
    };
    DocumentFilterAction(data);
  };

  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
    flag: null,
  };

  const releaseTakenDocument = () => {
    ReleaseTakenDocumentAction();
    setTakenDocuments(0);
  };

  const releaseDocument = (document_id) => {
    unselectTakenDocumentAction({ id: document_id });
  };

  // TESTED
  const promoteTopTen = (id, position) => {
    let newList = documents;
    // Element position in documents
    const draggedFrom = position;
    // The element to move
    const itemDragged = newList[draggedFrom];
    // The rest of the elements
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    );
    // The element positions first in the new arrangement of documents
    newList = [itemDragged, ...remainingItems];
    setDocuments(newList);
    // The priority is calculated form the priority of the element
    // From position 9 of the top10
    const data = {
      id: id,
      priority: top10[9].attributes.priority + 1,
    };
    DocumentPrioritizationAction(data);
  };

  const downloadOperativeChargeReport = async (email) => {
    const data = await DocumentService.downloadOperativeChargeReport(
      email
    );
    if (data.status == 200) {
      toast.success(
        'Reporte generado. Será enviado a su correo electrónico.'
      );
    } else {
      toast.error('Problema generando Reporte.');
    }
  };

  // TESTED
  // --** Documents and Top10 **--
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);

  /* If flag is true, it means select an item from documents, otherwise, from top10. 
    And the flag value is stored to compare this value with the destination place. */
  const onDragStart = (event, flag) => {
    // Corresponds to the position of the element in the current array (documents o top10).
    // top10: 0 - 9
    // documents: 0 - 00
    const initialPosition = Number(
      event.currentTarget.dataset.position
    );
    if (flag) {
      // Documents
      event.dataTransfer.setData('text/html', '');
      return setDragAndDrop({
        draggedFrom: initialPosition,
        draggedTo: null,
        isDragging: true,
        originalOrder: documents,
        updatedOrder: [],
        flag: true,
      });
    } else {
      // Top10
      event.dataTransfer.setData('text/html', '');
      return setDragAndDrop({
        draggedFrom: initialPosition,
        draggedTo: null,
        isDragging: true,
        originalOrder: top10,
        updatedOrder: [],
        flag: false,
      });
    }
  };

  /* If flag is true, it means that the destination place is documents, otherwise, is top10. 
    If flag and dragAndDrop.flag are equal, it means that origin and destination is the same,
    therefore, work same data set, otherwise, the initial data array is rearmed and worked together. */
  const onDragOver = (event, flag) => {
    event.preventDefault();
    let newList;
    let draggedFrom;
    let draggedTo;
    // If my origin and destination is the same.
    // documents a documents, top10 a top10
    if (flag === dragAndDrop.flag) {
      // Corresponds to the position of the element.
      draggedTo = Number(event.currentTarget.dataset.position);
      newList = dragAndDrop.originalOrder;
      draggedFrom = dragAndDrop.draggedFrom;
    } else {
      // If my origin and destination is not the same.
      // Concatenate and work with the complete arrangement.
      newList = top10.concat(documents);
      if (flag) {
        // top10 to documents
        // Where I'm going corresponds to the position of the element + 10
        // in the complete arrangement !!.
        draggedTo = Number(event.currentTarget.dataset.position) + 10;
        draggedFrom = dragAndDrop.draggedFrom;
      } else {
        // documents to top10
        // Where I come from corresponds to the position of the element+ 10
        // in the complete arrangement !!.
        draggedTo = Number(event.currentTarget.dataset.position);
        draggedFrom = dragAndDrop.draggedFrom + 10;
      }
    }
    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    );
    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: draggedTo,
      updatedOrder: newList,
    });
  };

  /* If flag is true, it means that the destination place is documents, otherwise, is top10. 
  If flag and dragAndDrop.flag are equal, it means that origin and destination is the same,
  and document or top10 are set.
  flag != dragAndDrop.flag the initial data array is disarmed in the same way as at the beginning. */
  const onDrop = (event, flag) => {
    if (flag === dragAndDrop.flag && flag) {
      // Only moved within the documents
      setDocuments(dragAndDrop.updatedOrder);
    } else if (flag === dragAndDrop.flag && !flag) {
      // Only moved within the top10
      setTop10(dragAndDrop.updatedOrder);
    } else {
      // If my origin and destination is not the same.
      // flag != dragAndDrop.flag
      let top10;
      let documents = dragAndDrop.updatedOrder.slice();
      // Get the top10
      top10 = documents.splice(0, 10);
      // Set top10 and documents
      setTop10(top10);
      setDocuments(documents);
    }

    // The new priority is calculated form the priority of the element
    if (dragAndDrop.updatedOrder.length > 0) {
      handleSendData(flag);
    }
    // Reset value
    setDragAndDrop({
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
      originalOrder: [],
      updatedOrder: [],
      flag: null,
    });
  };

  const handleSendData = (flag) => {
    let item = dragAndDrop.updatedOrder[dragAndDrop.draggedTo];
    let previousItem;
    if (flag === dragAndDrop.flag && flag) {
      // Documents
      // Limit case, zero position of documents
      if (dragAndDrop.draggedTo === 0) {
        // Is top10[9]
        previousItem = top10[9];
      } else {
        previousItem =
          dragAndDrop.updatedOrder[dragAndDrop.draggedTo - 1];
      }
    } else {
      // Top10
      // Limit case, zero position of top10
      if (dragAndDrop.draggedTo === 0) {
        // Is null
        previousItem = null;
      } else {
        previousItem =
          dragAndDrop.updatedOrder[dragAndDrop.draggedTo - 1];
      }
    }
    if (previousItem) {
      const data = {
        id: item.attributes.id,
        priority: previousItem.attributes.priority + 1,
      };
      DocumentPrioritizationAction(data);
    } else {
      const data = {
        id: item.attributes.id,
        priority: 1,
      };
      DocumentPrioritizationAction(data);
    }
  };

  const releasePendingDocumentButton = (document_number, doc_id) => {
    if (takenDocumentsCount) {
      if (
        document_number in
        takenDocumentsCount.attributes.documents_detail
      ) {
        return (
          <button
            className="btn btn-primary"
            onClick={() => {
              releaseDocument(doc_id);
            }}
          >
            {' '}
            Liberar{' '}
          </button>
        );
      }
    }
  };

  const handleClick = () => {
    filterType(startDate, endDate, filteredBy, deliveredBy);
    setDateSelected(true);
  };

  // Document State
  //
  // @returns [String] With the document information and its picker.
  const documentState = (item) => {
    if (
      item.aasm_state === 'Disponible' ||
      item.picker_name === null
    ) {
      return item.aasm_state;
    }
    return `${item.aasm_state} - ${item.picker_name}`;
  };

  function DocumentList() {
    if (localStorage.getItem('token')) {
      return (
        <div>
          <ToastContainer />
          {fetchDocumentPrioritizationResponse ||
          fetchDocumentListResponse ||
          fetchDocumentFilterResponse ? (
            <div className="loading-modal">
              <div className="loading-modal-logo">
                <figure>
                  <img
                    className="rotation-zoom"
                    src={logo}
                    alt="hbt logo"
                  />
                </figure>
              </div>
            </div>
          ) : null}
          <div id="date-picker"></div>
          <Navbar
            module="Admin"
            title="Admin"
            navBarName="Administrador de cola"
          />
          <Container className="body-height">
            <Row>
              <div className="col-3">
                <div className="headData">
                  <label
                    className="subtitle"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={takenDocumentsDetail}
                  >
                    {' '}
                    Documentos Tomados: {takenDocuments}
                  </label>
                  <br />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      releaseTakenDocument();
                    }}
                  >
                    Liberar Documentos
                  </button>
                </div>
              </div>
              <div className="col-6">
                <div className="headData ">
                  <label className="title text-center">
                    Documentos pendientes de preparar
                  </label>
                  <br />
                  <label className="subtitle">
                    Bodega {getWarehouse()}
                  </label>
                </div>
              </div>
              <div className="col-3">
                <button
                  className="adminButtom btn btn-primary"
                  onClick={() => {
                    downloadOperativeChargeReport(getEmail());
                  }}
                >
                  Carga Operativa
                </button>
              </div>
              <div className="containerAdmin mx-3">
                <div className="d-flex align-items-end">
                  <div className="col pr-1 pl-1">
                    <label>Desde</label>
                    <DatePicker
                      className="inputCalendar"
                      placeholderText="Fecha Inicio"
                      selected={startDate}
                      dateFormat="yyyy-dd-MM"
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div className="col pr-1 pl-1">
                    <label>Hasta</label>
                    <DatePicker
                      className="inputCalendar"
                      placeholderText="Fecha Fin"
                      selected={endDate}
                      dateFormat="yyyy-dd-MM"
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                  <div className="col pr-1 pl-1">
                    <label>Estado</label>
                    <select
                      className="select"
                      name="select-document_type"
                      id="select-document_type"
                      onChange={(e) => {
                        setFilteredBy(e.target.value);
                      }}
                    >
                      <option value="">Todos</option>
                      <option value="pending">Pendientes</option>
                      <option value="preparing">Incompletos</option>
                    </select>
                  </div>
                  <div className="col pr-1 pl-1">
                    <label>Tipo de Despacho</label>
                    <select
                      className="select"
                      name="select-delivery_type"
                      id="select-delivery_type"
                      onChange={(e) => {
                        setDeliveredBy(e.target.value);
                      }}
                    >
                      <option value="">Todos</option>
                      <option value="RETIRA EN TIENDA">
                        Retiro en tienda
                      </option>
                      <option value="DESPACHO DOMICILIO">
                        Despacho a domicilio
                      </option>
                      <option value="ENTREGA INMEDIATA">
                        Entrega Inmediata
                      </option>
                      <option value="DESPACHO PROGRAMADO">
                        Despacho programado
                      </option>
                    </select>
                  </div>
                  <div className="col pr-1 pl-1">
                    <button
                      className="buttonGo btn btn-primary"
                      type="submit"
                      appearance="primary"
                      onClick={() => handleClick()}
                    >
                      Ir
                    </button>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col sm={12}>
                <section>
                  <table className="table">
                    <thead>
                      <tr>
                        <td>Nro</td>
                        <td>Tipo Doc Origen</td>
                        <td>Nro Doc Origen</td>
                        <td>Cant Líneas</td>
                        <td>Cant Producto</td>
                        <td>Comuna</td>
                        <td>Tipo de Despacho</td>
                        <td>Estado</td>
                        <td>Accion</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {top10
                        ? top10.map((item) => {
                            myIndex += 1;
                            const indexAux = myIndex;

                            return (
                              <tr
                                key={indexAux}
                                data-position={indexAux}
                                draggable
                                onDragStart={(e) =>
                                  onDragStart(e, false)
                                }
                                onDragOver={(e) =>
                                  onDragOver(e, false)
                                }
                                onDrop={(e) => onDrop(e, false)}
                                className="dropArea"
                              >
                                {/* <td>{item.attributes.priority}</td> */}
                                <td>{indexAux + 1}</td>
                                <td>
                                  {item.attributes.document_type}
                                </td>
                                <td>
                                  {item.attributes.document_number}
                                </td>
                                <td>{item.attributes.total_lines}</td>
                                <td>
                                  {item.attributes.total_products}
                                </td>
                                <td>{item.attributes.commune}</td>
                                <td>
                                  {item.attributes.delivery_type}
                                </td>
                                <td>
                                  {documentState(item.attributes)}
                                </td>
                                <td>
                                  {releasePendingDocumentButton(
                                    item.attributes.document_number,
                                    item.attributes.id
                                  )}
                                </td>
                                <td></td>
                              </tr>
                            );
                          })
                        : null}

                      {documents
                        ? documents.map((item) => {
                            myIndex += 1;
                            const indexAux = myIndex;
                            return (
                              <tr
                                key={indexAux}
                                data-position={indexAux - 10}
                                draggable
                                onDragStart={(e) =>
                                  onDragStart(e, true)
                                }
                                onDragOver={(e) =>
                                  onDragOver(e, true)
                                }
                                onDrop={(e) => onDrop(e, true)}
                                className="dropArea"
                              >
                                {/* <td>{item.attributes.priority}</td> */}
                                <td>{indexAux + 1}</td>
                                <td>
                                  {item.attributes.document_type}
                                </td>
                                <td>
                                  {item.attributes.document_number}
                                </td>
                                <td>{item.attributes.total_lines}</td>
                                <td>
                                  {item.attributes.total_products}
                                </td>
                                <td>{item.attributes.commune}</td>
                                <td>
                                  {item.attributes.delivery_type}
                                </td>
                                <td>
                                  {documentState(item.attributes)}
                                </td>
                                <td>
                                  {releasePendingDocumentButton(
                                    item.attributes.document_number,
                                    item.attributes.id
                                  )}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      promoteTopTen(
                                        item.attributes.id,
                                        indexAux - 10
                                      );
                                    }}
                                  >
                                    Priorizar
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </section>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      return <></>;
    }
  }

  return DocumentList();
}

/*Methodo*/
const mapStateToProps = (state, props) => {
  return {
    response: state.documentAdminReducers.documentListResponse.res,
    responseFilter:
      state.documentAdminReducers.documentFilterResponse.res,
    takenDocumentsCount:
      state.documentAdminReducers.takenDocumentCount.res,
    fetchDocumentPrioritizationResponse:
      state.documentAdminReducers.fetchDocumentPrioritizationResponse,
    fetchDocumentListResponse:
      state.documentAdminReducers.fetchDocumentListResponse,
    fetchDocumentFilterResponse:
      state.documentAdminReducers.fetchDocumentFilterResponse,
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentListAction,
  TakenDocumentCountAction,
  DocumentFilterAction,
  DocumentPrioritizationAction,
  ReleaseTakenDocumentAction,
  unselectTakenDocumentAction,
})(Document);
