import React from "react";
import { SaveProductAction } from "../../redux/actions/productPicking.actions";
import { useState } from "react";

const Product = ({ sku, quantity, warehouse, SaveProductAction, id, line ,barcode,provider_code }) => {
  const [productQuantity, setProductQuantity] = useState([]);

  const changeQuantity = (loc) => {
    if (parseInt(productQuantity.value) <= loc.stock) {
      const data = {
        id: id,
        line_number: line,
        sku: sku,
        barcode: barcode,
        provider_code: provider_code,
        stock: parseInt(productQuantity.value),
        location: loc.warehouse_location,
      };
      SaveProductAction(data);
    }
  };

  const renderUnits = () => {
    if (quantity > 1) {
      return "Unidades";
    } else {
      return "Unidad";
    }
  };

  const onlyNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };
  

  return (
    <div>
      <table>
        <tbody>
        <tr>
            <td>Barcode:</td>
            <td>{barcode}</td>
          </tr>
          <tr>
            <td>Provider Code:</td>
            <td>{provider_code}</td>
          </tr>
          <tr>
            <td>
              {quantity} {renderUnits()}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table">
        <tbody>
          <tr>
            <td rowSpan="2">Ubicación</td>
          </tr>
          <tr>
            <td>Stock</td>
          </tr>

          {warehouse &&
            warehouse.map((loc, i) => {
              return (
                <>
                  <tr key={i}>
                    <td rowSpan="3">{loc.warehouse_location}</td>
                  </tr>
                  <tr>
                    <td>{loc.stock}</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="number"
                        name={i}
                        pattern={"[0-9]"}
                        placeholder="Cant"
                        style={{ width: "40%" }}
                        onKeyPress={(e) => {
                          onlyNumber(e);
                        }}
                        onBlur={() => changeQuantity(loc)}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          const inputName = e.target.name;
                          setProductQuantity({
                            input: inputName,
                            value: newValue,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Product;

