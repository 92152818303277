import React, { useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import { DocumentToDeliverySearchAction } from "../../redux/actions/documentDelivery.actions";
import { connect } from "react-redux";
import magnifyingGlass from "../../img/search.svg";
import logo from "../../img/logo.png";
import { useHistory } from "react-router-dom";
import { getName, close } from "../../utils/Commons.js"

function Searcher({ response, DocumentToDeliverySearchAction }) {
  const [searcher, setSearcher] = useState("");
  let history = useHistory();
  const handleChange = () => {
    DocumentToDeliverySearchAction({ search: searcher });
  };

  const toLogin = () => {
    localStorage.setItem("module", "Delivery");
    history.push("/login");
  };


  return (
    <Container>
      <Row>
        <Col sm={12}>
          <table className="containerTittleGrabber">
            <tbody>
              <tr>
                <td>
                  <img
                    className="containerTittleGrabberImg"
                    src={logo}
                    alt="hbt logo"
                  />
                </td>
                <td>
                  <label className="containerTittleGrabberTitte">Entrega</label>
                </td>
                <td>
                  <label className="containerTittleGrabberLabelName">
                    Hola {getName(toLogin)} !
                  </label>
                </td>
                <td>
                  <label className="containerTittleGrabberLabelClose">
                    Cerrar Sesión
                  </label>
                </td>
                <td>
                  <button
                    className="containerTittleGrabberButton"
                    onClick={() => {
                      close();
                    }}
                  >
                    x
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className="inputContainer">
            <input
              className="input"
              type="text"
              placeholder="Buscar"
              onKeyDown={(e) => (e.key === "Enter" ? handleChange() : null)}
              defaultValue={searcher}
              onChange={(e) => {
                setSearcher(e.target.value);
              }}
            />
            <input
              type="image"
              className="inputImg"
              src={magnifyingGlass}
              border="0"
              alt="Search"
              onClick={() => handleChange()}
            />
          </div>
        </Col>
      </Row>
      {response && Number(response.length > 0) ? (
        <div className="">
          <table className="tableDataSearcher">
            <thead>
              <tr>
                <td>Nro. Doc. Origen</td>
                <td>Nro. De Preparación</td>
                <td>Tipo de Despacho</td>
                <td>B</td>
              </tr>
            </thead>
            {response.map((item, index) => {
              return (
                <tbody key={item + index}>
                  <tr key={index}>
                    <td>{item.attributes.document_number}</td>
                    <td>{item.attributes.id}</td>
                    <td>{item.attributes.delivery_type}</td>
                    <td>
                      {item.attributes.order_packages === "yes" ? "X" : null}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      ) : null}
    </Container>
  );
}

const mapStateToProps = (state, props) => {
  return {
    response:
      state.documentDeliveryReducers.documentToDeliverySearchResponse.res,
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentToDeliverySearchAction,
})(Searcher);
