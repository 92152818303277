import loginReducers from "./login.reducers";
import documentPickingReducers from "./documentPicking.reducers";
import productReducers from "./product.reducers";
import documentPackingReducers from "./documentPacking.reducers";
import documentDeliveryReducers from "./documentDelivery.reducers";
import documentAdminReducers from "./documentAdmin.reducers";
import documentPackBuilderReducers from "./documentPackBuilder.reducers";
export default {
  loginReducers,
  documentPickingReducers,
  productReducers,
  documentPackingReducers,
  documentDeliveryReducers,
  documentAdminReducers,
  documentPackBuilderReducers
};
