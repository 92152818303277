import { call, put, takeEvery} from "redux-saga/effects";
import Constants from "../../utils/Constants";
import DocumentPackBuilderService from "../../API/services/DocumentPackBuilderService";

function* packBuilderMonths(action){
  try {
    const data = yield call(
      DocumentPackBuilderService.packBuilderMonths,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchPackBuilderMonths,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchPackBuilderMonths,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchPackBuilderMonths,
      payload: e.message,
    });
  }
}

function* packBuilderUploadDocumentList(action){
  console.log(action.payload)
  let month = action.payload === undefined ? '' : action.payload.month
  try {
    yield put({
      type: Constants.StartFetchPackBuilderUploadDocuments,
      payload: { 
        fetch: true
      }
    });
    
    const data = yield call(
      DocumentPackBuilderService.packBuilderUploadList,
      month
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchPackBuilderUploadDocumentsAction,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessFetchPackBuilderUploadDocumentsAction,
      payload: { data, fetch: false }
    });

  } catch (e) {
    yield put({
      type: Constants.FailFetchPackBuilderUploadDocumentsAction,
      payload: { data: e.message, fetch: false }
    });
  }
}

function* generatePackRequest(action){
  try {
    yield put({
      type: Constants.StartFetchGeneratePackRequestAction,
      payload: { 
        fetch: true
      }
    });
    
    const data = yield call(
      DocumentPackBuilderService.generatePackRequest,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchGeneratePackRequestAction,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessFetchGeneratePackRequestAction,
      payload: { data, fetch: false }
      
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchGeneratePackRequestAction,
      payload: { data: e.message, fetch: false }
    });
  }
}

function* packBuilderFileUpload(action){
  try{
    yield put({
      type: Constants.StartFetchPackBuilderUploadFileAction,
      payload: { 
        fetch: true
      }
    });
    
    const data = yield call(
      DocumentPackBuilderService.packBuilderFileUpload,
      action.file
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchPackBuilderUploadFileAction,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessFetchPackBuilderUploadFileAction,
      payload: { data, fetch: false }
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchPackBuilderUploadFileAction,
      payload: { data: e.message, fetch: false }
    });
  }
}

function* packBuilderManualUpload(action){
  try{
    yield put({
      type: Constants.StartFetchPackBuilderManualUploadAction,
      payload: { 
        fetch: true
      }
    });

    const data = yield call(
      DocumentPackBuilderService.packBuilderManualUpload,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchPackBuilderManualUploadAction,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessFetchPackBuilderManualUploadAction,
      payload: { data, fetch: false }
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchPackBuilderManualUploadAction,
      payload: { data: e.message, fetch: false }
    });
  }
}

function* packRequestsDocumentList(action){
  try{
    yield put({
      type: Constants.StartFetchPendingPackRequestListAction,
      payload: { 
        fetch: true
      }
    });

    const data = yield call(
      DocumentPackBuilderService.packRequestsDocumentList,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchPendingPackRequestListAction,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessFetchPendingPackRequestListAction,
      payload: { data, fetch: false }
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchPendingPackRequestListAction,
      payload: { data: e.message, fetch: false }
    });
  }
}

function* packRequestPrioritization(action){
  try{
    yield put({
      type: Constants.StartFetchPackRequestPrioritizationAction,
      payload: { 
        fetch: true
      }
    });

    const data = yield call(
      DocumentPackBuilderService.packBuilderPrioritization,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchPackRequestPrioritizationAction,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessFetchPackRequestPrioritizationAction,
      payload: { data, fetch: false }
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchPackRequestPrioritizationAction,
      payload: { data: e.message, fetch: false }
    });
  }
}

function* unselectPackRequest(action){
  try{
    yield put({
      type: Constants.StartPackRequestUnselectAction,
      payload: { 
        fetch: true
      }
    });

    const data = yield call(
      DocumentPackBuilderService.unselectPackRequest,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailPackRequestUnselectAction,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessPackRequestUnselectAction,
      payload: { data, fetch: false }
    });
  } catch (e) {
    yield put({
      type: Constants.FailPackRequestUnselectAction,
      payload: { data: e.message, fetch: false }
    });
  }
}

function* deletePackRequest(action){
  try{
    yield put({
      type: Constants.StartPackRequestDeleteAction,
      payload: { 
        fetch: true
      }
    });

    const data = yield call(
      DocumentPackBuilderService.deletePackRequest,
      action.id
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailPackRequestDeleteAction,
        payload: { data, fetch: false }
      });
    }
    yield put({
      type: Constants.SuccessPackRequestDeleteAction,
      payload: { data, fetch: false }
    });
  } catch (e) {
    yield put({
      type: Constants.FailPackRequestDeleteAction,
      payload: { data: e.message, fetch: false }
    });
  }
}

export function* documentPackBuilderSaga() {
  yield takeEvery(Constants.PackBuilderUploadDocumentsAction, packBuilderUploadDocumentList)
  yield takeEvery(Constants.GeneratePackRequestAction, generatePackRequest)
  yield takeEvery(Constants.packBuilderMonthsAction, packBuilderMonths)
  yield takeEvery(Constants.PackBuilderUploadFileAction, packBuilderFileUpload)
  yield takeEvery(Constants.PackBuilderManualUploadAction, packBuilderManualUpload)
  yield takeEvery(Constants.PendingPackRequestListAction, packRequestsDocumentList)
  yield takeEvery(Constants.PackRequestPrioritizationAction, packRequestPrioritization)
  yield takeEvery(Constants.PackRequestUnselectAction, unselectPackRequest)
  yield takeEvery(Constants.PackRequestDeleteAction, deletePackRequest)
}