import Constants from "../../utils/Constants";

const documentState = {
  documentPendingToPackResponse: [],
  documentChangeStatusToPackResponse: [],
  documentChangeStatusToDeliveryResponse: [],
  documentPendingToLabelResponse: [],
  documentPendingToDeliveryResponse: [],
  documentPackingListResponse: [],
  documentPublicPackingListResponse: [],
  documentToPackResponse: [],
  documentChangeStatusToDeliveredWithNoLabelResponse: [],
  documentLabelResponse: [],
  loadingScreen: false,
  ModalLoadingScreen: false,
  documentLocationResponse: [],
  documentPreparationLocationResponse: [],
  documentPackageLocationResponse: [],
  documentDeliveryCompleteResponse: [],
  changePreparationLocationResponse: [],
};

export default (state = documentState, action) => {
  switch (action.type) {

     // Change package location
     case Constants.SuccessChangePreparationLocationAction:
      return { ...state, changePreparationLocationResponse: action.payload };
  
    case Constants.FailChangePreparationLocationAction:
      return { ...state, changePreparationLocationResponse: action.payload };   
      
    // Document Store Preparation 
    case Constants.SuccessStorePreparationLocation:
      return { ...state, documentPreparationLocationResponse: action.payload};

    case Constants.FailStorePreparationLocation:
      return { ...state, documentPreparationLocationResponse: action.payload };
      
    // document list pending for preparing

    case Constants.SuccessFetchDocumentPendingToPack:
      return { ...state, documentPendingToPackResponse: action.payload };

    case Constants.FailFetchDocumentPendingToPack:
      return { ...state, documentPendingToPackResponse: action.payload };

    // document with status to pack

    case Constants.SuccessFetchChangeStatusToPackAction:
      return { ...state, documentChangeStatusToPackResponse: action.payload };

    case Constants.FailFetchChangeStatusToPackAction:
      return { ...state, documentChangeStatusToPackResponse: action.payload };

    case Constants.showLoadingScreen:
      return { ...state, loadingScreen: action.payload};
    case Constants.showModalLoadingScreen:
      return { ...state, ModalLoadingScreen: action.payload};

    //document with status to deliver

    case Constants.SuccessFetchChangeStatusToDeliveryAction:
      return {
        ...state,
        documentChangeStatusToDeliveryResponse: action.payload,
      };

    case Constants.FailFetchChangeStatusToDeliveryAction:
      return {
        ...state,
        documentChangeStatusToDeliveryResponse: action.payload,
      };

    // document list pending for labels

    case Constants.SuccessFetchDocumentsPendingToLabelAction:
      return {
        ...state,
        documentPendingToLabelResponse: action.payload,
      };

    case Constants.FailFetchDocumentsPendingToLabelAction:
      return {
        ...state,
        documentPendingToLabelResponse: action.payload,
      };

    //document list pending to deliver/pack

    case Constants.SuccessFetchDocumentsPendingToDeliverAction:
      return {
        ...state,
        documentPendingToDeliveryResponse: action.payload,
      };

    case Constants.FailFetchDocumentsPendingToDeliveryAction:
      return {
        ...state,
        documentPendingToDeliveryResponse: action.payload,
      };

    //document packing List

    case Constants.SuccessFetchDocumentPackingListAction:
      return {
        ...state,
        documentPackingListResponse: action.payload,
      };

    case Constants.FailFetchDocumentPackingListAction:
      return {
        ...state,
        documentPackingListResponse: action.payload,
      };

    // Document public packing List 
    
    case Constants.SuccessFetchDocumentPublicPackingListAction:
      return {
        ...state,
        documentPublicPackingListResponse: action.payload,
      };
    
    case Constants.FailFetchDocumentPublicPackingListAction:
      return {
        ...state,
        documentPublicPackingListResponse: action.payload,
      };  
      
    
    // Document Available locations to store
    case Constants.SuccessFetchDocumentLocation:
      return { 
        ...state, 
        documentLocationResponse: action.payload};
    
    case Constants.FailFetchDocumentLocation:
      return { 
        ...state, 
        documentLocationResponse: action.payload}

    // document to pack

    case Constants.SuccessFetchDocumentToPack:
      return { ...state, documentToPackResponse: action.payload };

    case Constants.FailFetchDocumentToPack:
      return { ...state, documentToPackResponse: action.payload };

    // document to deliver with no labels

    case Constants.SuccessFetchChangeStatusToDeliveredWithNoLabel:
      return {
        ...state,
        documentChangeStatusToDeliveredWithNoLabelResponse: action.payload,
      };

    case Constants.FailFetchChangeStatusToDeliveredWithNoLabelAction:
      return {
        ...state,
        documentChangeStatusToDeliveredWithNoLabelResponse: action.payload,
      };

    case Constants.SuccessFetchDocumentLabelAction:
      return { ...state, documentLabelResponse: action.payload };

    case Constants.FailFetchDocumentLabelAction:
      return { ...state, documentLabelResponse: action.payload };

    // document delivery complete

    case Constants.SuccessFetchDocumentDeliveryComplete:
      return { ...state, documentDeliveryCompleteResponse: action.payload};

    case Constants.FailFetchDocumentDeliveryComplete:
      return { ...state, documentDeliveryCompleteResponse: action.payload};

    default:
      return state;
  }
};
