import { Fetch } from "../Fetch";
import Routes from "../Routes";
import { jsonResponse } from "../../tools/JsonResponse";

class DocumentService {
  //principal documents list admin
  async documentList(payload) {
    return Fetch("get", Routes.DocumentList, payload)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // Pending Reception List
  // 
  // Service to connect to the api and get the pending reception list
  // Send the selected document type to the api
  // @params payload {type: document_type}
  // 
  // @returns [JsonResponse] with the document list 
  async pendingReceptionList(payload) {
    return Fetch("get", Routes.pendingReceptionList(payload.document_type))
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async syncStock(payload) {
    return Fetch("get", Routes.SyncStock, payload)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async takenDocumentCount(payload) {
    return Fetch("get", Routes.TakenDocument, payload)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async releaseTakenDocuments(payload) {
    return Fetch("get", Routes.ReleaseTakenDocuments, payload)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async unselectTakenDocuments(payload) {
    return Fetch("post", Routes.unselectTakenDocuments(payload.id))
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async documentPrioritization(payload) {
    const data = {
      priority: payload.priority,
      id: payload.id,
    };
    return Fetch("post", Routes.DocumentPrioritization(data.id), data)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // Pending Reception Prioritization
  // 
  // Service to connect to the api and change the priority of a pending reception document
  // Send the selected document id and the new priority to the api
  // @params payload {id: document_id, priority: new_priority}
  // 
  // @returns [JsonResponse] with the new document list order by new priority
  async pendingReceptionPrioritization(payload) {
    const data = {
      priority: payload.priority,
      id: payload.id,
    };
    return Fetch("post", Routes.PendingReceptionPrioritization(data.id), data)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async savePacking(payload) {
    const data = {
      sku: payload.sku,
      amount: payload.stock,
      package_number: payload.pack,
      id: payload.id,
    };

    return Fetch("post", Routes.SavePacking(data.id), data)
      .then((res) => {
        //return jsonResponse(res.status, res.data)
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async documentFilter(payload) {
    return Fetch(
      "get",
      Routes.DocumentFilter(
        payload.startDate,
        payload.endDate,
        payload.filteredBy,
        payload.deliveredBy
      ),
      null
    )
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  async downloadOperativeChargeReport(email){
    return Fetch("get", Routes.OperativeChargeReport(email))
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }
}

export default new DocumentService();
