import Constants from "../../utils/Constants";

const documentState = {
  uploadPackBuilderDocuments: [],
  packBuilderMonthsResponse: [],
  generatePackRequestDocument: [],
  packRequestDocuments: [],
  fileUploadResponse: [],
  manualUploadResponse: [],
  prioritizePackRequestResponse: []
}

// Pack Builder Document Reducer
// 
// Reducer to map the action response to a state
export default (state = documentState, action) => {
  switch (action.type) {
    // PackBuilder Upload Documents
    case Constants.StartFetchPackBuilderUploadDocuments:
      return {
        ...state,
        uploadPackBuilderDocuments: action.payload.data
      }
    case Constants.SuccessFetchPackBuilderUploadDocumentsAction:
      return {
        ...state,
        uploadPackBuilderDocuments: action.payload.data
      }
    case Constants.FailFetchPackBuilderUploadDocumentsAction:
      return {
        ...state,
        uploadPackBuilderDocuments: action.payload.data
      }
    // PackBuilder Generate Requests
    case Constants.StartFetchGeneratePackRequestAction:
      return {
        ...state,
        generatePackRequestDocument: action.payload.data
      }
    case Constants.SuccessFetchGeneratePackRequestAction:
      return {
        ...state,
        generatePackRequestDocument: action.payload.data
      }
    case Constants.FailFetchGeneratePackRequestAction:
      return {
        ...state,
        generatePackRequestDocument: action.payload.data
      }
    // PackBuilder File Upload
    case Constants.StartFetchPackBuilderUploadFileAction:
      return {
        ...state,
        fileUploadResponse: action.payload.data
      }
    case Constants.SuccessFetchPackBuilderUploadFileAction:
      return {
        ...state,
        fileUploadResponse: action.payload.data
      }
    case Constants.FailFetchPackBuilderUploadFileAction:
      return {
        ...state,
        fileUploadResponse: action.payload.data
      }
    // PackBuilder Manual Upload
    case Constants.StartFetchPackBuilderManualUploadAction:
      return {
        ...state,
        manualUploadResponse: action.payload.data
      }
    case Constants.SuccessFetchPackBuilderManualUploadAction:
      return {
        ...state,
        manualUploadResponse: action.payload.data
      }
    case Constants.FailFetchPackBuilderManualUploadAction:
      return {
        ...state,
        manualUploadResponse: action.payload.data
      }
    // PackBuilder Available Months
    case Constants.SuccessFetchPackBuilderMonths:
      return { 
        ...state, 
        packBuilderMonthsResponse: action.payload
      }
    case Constants.FailFetchPackBuilderMonths:
      return { 
        ...state, 
        packBuilderMonthsResponse: action.payload
      }
    // PackRequest Documents
    case Constants.StartFetchPendingPackRequestListAction:
      return {
        ...state,
        packRequestDocuments: action.payload.data
      }
    case Constants.SuccessFetchPendingPackRequestListAction:
      return {
        ...state,
        packRequestDocuments: action.payload.data
      }
    case Constants.FailFetchPendingPackRequestListAction:
      return {
        ...state,
        packRequestDocuments: action.payload.data
      } 
    // Prioritize PackRequests
    case Constants.StartFetchPackRequestPrioritizationAction:
      return {
        ...state,
        prioritizePackRequestResponse: action.payload.data
      }
    case Constants.SuccessFetchPackRequestPrioritizationAction:
      return {
        ...state,
        prioritizePackRequestResponse: action.payload.data
      }
    case Constants.FailFetchPackRequestPrioritizationAction:
      return {
        ...state,
        prioritizePackRequestResponse: action.payload.data
      }
    // Unselect PackRequest
    case Constants.StartPackRequestUnselectAction:
      return {
        ...state,
        unselectPackRequestResponse: action.payload.data
      }
    case Constants.SuccessPackRequestUnselectAction:
      return {
        ...state,
        unselectPackRequestResponse: action.payload.data
      }
    case Constants.FailPackRequestUnselectAction:
      return {
        ...state,
        unselectPackRequestResponse: action.payload.data
      }
    // Delete PackRequest
    case Constants.StartPackRequestDeleteAction:
      return {
        ...state,
        deletePackRequestResponse: action.payload.data
      }
    case Constants.SuccessPackRequestDeleteAction:
      return {
        ...state,
        deletePackRequestResponse: action.payload.data
      }
    case Constants.FailPackRequestDeleteAction:
      return {
        ...state,
        deletePackRequestResponse: action.payload.data
      }
    // Default
    default:
      return state;
  }
}
