import Routes from "./Routes";

const DEFAULT_URL = process.env.REACT_APP_API_URL;

export const Fetch = async (method, URL, data, extraHeaders) => {
  const defaultHeaders = new Headers();

  if (!defaultHeaders.has("content-type")) {
    defaultHeaders.append("content-type", "application/json");
    defaultHeaders.append(
      "X-api-token",
      "fb9c5a27-e3f2-68d4-bc91-fa32b043c3a2"
    );
    defaultHeaders.append("X-USER-TOKEN", localStorage.getItem("token"));
  }

  defaultHeaders.append("access-control-allow-origin", "*");
  defaultHeaders.append("Access-Control-Allow-Methods", "*");

  if (extraHeaders) {
    extraHeaders.forEach((item) => {
      defaultHeaders.append(item.key, item.value);
    });
  }

  const requestInit = {
    method: method,
    headers: new Headers(defaultHeaders),
  };

  if (data) {
    requestInit.body = JSON.stringify(data);
  }

  return await fetch(DEFAULT_URL + URL, requestInit).then((res) =>
    res ? res.json() : {}
  );
};
