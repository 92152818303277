import { Fetch } from "../Fetch";
import Routes from "../Routes";
import { jsonResponse } from "../../tools/JsonResponse";

class DocumentPackBuilderService {
  // Pack Builder Upload List
  // Service to connect to the api and get the pack builder upload list
  // 
  // @returns [JsonResponse] with the document list 
  async packBuilderUploadList(payload) {
    return Fetch("get", Routes.packBuilderUploadList(payload))
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // Pack Builder Upload List
  // Service to connect to the api and generate a pack request
  // 
  // @returns [JsonResponse] with the document list 
  async generatePackRequest(payload) {
    return Fetch("post", Routes.generatePackRequest(payload.data.id), payload)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // TODO: DOCUMENTATION
  // 
  // @returns [JsonResponse] with the document list 
  async packBuilderMonths(payload) {
    return Fetch("get", Routes.availableMonths(payload)) //a
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // TODO: DOCUMENTATION
  // 
  // @returns [JsonResponse] with the document list 
  async packBuilderFileUpload(file) {
    var data = new FormData()
    data.append('file', file)
    return fetch(`${process.env.REACT_APP_API_URL}${Routes.packBuilderFileUpload}`, {
      method: 'POST',
      headers: { "X-api-token": process.env.REACT_APP_API_TOKEN,
                 "X-user-token": localStorage.getItem("token") },
      body: data
    })
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // TODO: DOCUMENTATION
  // 
  // @returns [JsonResponse] with the document list 
  async packBuilderManualUpload(payload) {
    return Fetch("post", Routes.packBuilderManualUpload, payload)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // TODO: DOCUMENTATION
  // 
  // @returns [JsonResponse] with the document list 
  async packRequestsDocumentList() {
    return Fetch("get", Routes.packRequestsList())
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // TODO: DOCUMENTATION
  // 
  // @returns [JsonResponse] with the document list 
  async packBuilderPrioritization(payload) {
    return Fetch("post", Routes.prioritizePackRequest(payload.data.id), payload)
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // TODO: DOCUMENTATION
  async unselectPackRequest(payload) {
    return Fetch("get", Routes.unselectPackRequest(payload.id, payload.kind))
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }

  // TODO: DOCUMENTATION
  async deletePackRequest(id) {
    console.log(id)
    return Fetch("delete", Routes.detailPackRequest(id))
      .then((res) => {
        return jsonResponse(200, res.data);
      })
      .catch((err) => {
        return jsonResponse(500, err);
      });
  }
}

export default new DocumentPackBuilderService();
