import { call, put, takeEvery } from "redux-saga/effects";
import Constants from "../../utils/Constants";
import DocumentService from "../../API/services/DocumentPackingService";
import DeliveryService from "../../API/services/DocumentDeliveryService";



function* documentLocation(action){
  try {
    const data = yield call(
      DocumentService.documentLocations,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentLocation,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentLocation,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentLocation,
      payload: e.message,
    });
  }
}

function* storePreparationLocation(action){
  try {
    const data = yield call(
      DocumentService.storePreparationLocation,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailStorePreparationLocation,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessStorePreparationLocation,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailStorePreparationLocation,
      payload: e.message,
    });
  }
}

function* changePreparationLocation(action){
  try {
    yield put({
      type: Constants.showModalLoadingScreen,
      payload: true,
    });

    const data = yield call(
      DocumentService.changePreparationLocation,
      action.payload
    );
    

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailChangePreparationLocationAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessChangePreparationLocationAction,
      payload: data,
    });
    yield put({
      type: Constants.showModalLoadingScreen,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: Constants.FailChangePreparationLocationAction,
      payload: e.message,
    });
  }
}

function* storePackageLocation(action){
  try {
    const data = yield call(
      DocumentService.storePackageLocation,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailStorePackageLocation,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessStorePackageLocation,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailStorePackageLocation,
      payload: e.message,
    });
  }
}

function* documentSearch(action) {
  let warehouse = action.payload === undefined ? '' : action.payload.warehouse
  let search = action.payload === undefined ? '' : action.payload.search
  try {
    yield put({
      type: Constants.showLoadingScreen,
      payload: true,
    });
    const data = yield call(
      DocumentService.documentSearch,
      warehouse,
      search
    )
    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentPendingToPack,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentPendingToPack,
      payload: data,
    });
    yield put({
      type: Constants.showLoadingScreen,
      payload: false,
    });

  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentPendingToPack,
      payload: e.message,
    });
    yield put({
      type: Constants.showLoadingScreen,
      payload: false,
    });
  }
}

function* documentPendingToPack(action) {
  let warehouse = action.payload === undefined ? '' : action.payload.warehouse
  let targetPage = action.payload === undefined ? 1 : action.payload.current_page
  try {
    yield put({
      type: Constants.showLoadingScreen,
      payload: true,
    });

    const data = yield call(
      DocumentService.documentPendingToPack,
      warehouse,
      targetPage
    )
    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentPendingToPack,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentPendingToPack,
      payload: data,
    });
    yield put({
      type: Constants.showLoadingScreen,
      payload: false,
    });

  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentPendingToPack,
      payload: e.message,
    });
    yield put({
      type: Constants.showLoadingScreen,
      payload: false,
    });
  }
}

function* changeStatusToDelivery(action) {
  try {
    const data = yield call(
      DocumentService.changeStatusToDelivery,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchChangeStatusToDeliveryAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchChangeStatusToDeliveryAction,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchChangeStatusToDeliverAction,
      payload: e.message,
    });
  }
}

function* changeStatusToPack(action) {
  try {
    const data = yield call(DocumentService.changeStatusToPack, action.payload);

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchChangeStatusToPackAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchChangeStatusToPackAction,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchChangeStatusToPackAction,
      payload: e.message,
    });
  }
}

function* documentPendingToLabel(action) {
  try {
    const data = yield call(
      DocumentService.documentPendingToLabel,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentsPendingToLabelAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentsPendingToLabelAction,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentsPendingToLabelAction,
      payload: e.message,
    });
  }
}

function* documentPendingToDelivery(action) {
  try {
    const data = yield call(
      DocumentService.documentPendingToDelivery,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentsPendingToDeliveryAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentsPendingToDeliveryAction,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentsPendingToDeliveryAction,
      payload: e.message,
    });
  }
}

function* documentPackingList(action) {
  try {
    const data = yield call(
      DocumentService.documentPackingList,
      action.payload
    );
    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentPackingListAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentPackingListAction,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentPackingListAction,
      payload: e.message,
    });
  }
}

function* documentPublicPackingList(action) {
  try {
    const data = yield call(
      DocumentService.documentPublicPackingList,
      action.payload
    );
    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentPublicPackingListAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentPublicPackingListAction,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentPublicPackingListAction,
      payload: e.message,
    });
  }
}

function* documentToPack(action) {
  try {
    const data = yield call(DocumentService.documentToPack, action.payload);
    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentToPack,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentToPack,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentToPack,
      payload: e.message,
    });
  }
}

function* documentLabel(action) {
  try {
    const data = yield call(DocumentService.documentLabel, action.payload);

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentLabelAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentLabelAction,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentLabelAction,
      payload: e.message,
    });
  }
}

function* documentDeliveryComplete(action) {
  try {
    const data = yield call(
      DeliveryService.documentDeliveryComplete,
      action.payload
    );

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchDocumentDeliveryComplete,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchDocumentDeliveryComplete,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchDocumentDeliveryComplete,
      payload: e.message,
    });
  }
}

export function* documentPackingSaga() {
  yield takeEvery(Constants.documentPendingToPackAction, documentPendingToPack);
  yield takeEvery(Constants.documentLocationAction, documentLocation);
  yield takeEvery(Constants.storePreparationLocationAction, storePreparationLocation);
  yield takeEvery(Constants.changePreparationLocationAction, changePreparationLocation);
  yield takeEvery(Constants.storePackageLocationAction, storePackageLocation);
  yield takeEvery(Constants.changeStatusToPackAction, changeStatusToPack);
  yield takeEvery(
    Constants.changeStatusToDeliveryAction,
    changeStatusToDelivery
  );
  yield takeEvery(
    Constants.documentsPendingToLabelAction,
    documentPendingToLabel
  );
  yield takeEvery(
    Constants.documentsPendingToDeliveryAction,
    documentPendingToDelivery
  );
  yield takeEvery(Constants.documentSearchAction, documentSearch)
  yield takeEvery(Constants.documentPackingListAction, documentPackingList);
  yield takeEvery(Constants.documentPublicPackingListAction, documentPublicPackingList);
  yield takeEvery(Constants.documentToPackAction, documentToPack);
  yield takeEvery(Constants.documentLabelAction, documentLabel);
  yield takeEvery(Constants.documentDeliveryCompleteAction, documentDeliveryComplete);
}
