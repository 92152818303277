import React, { useEffect, useState } from "react";
import { Container } from "react-grid-system";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import logo from "../../img/logo.png";
import magnifyingGlass from "../../img/search.svg";
import Navbar from "../../components/navbar"
import DatePicker from "react-datepicker";
import { PreparationMovementSearchAction } from "../../redux/actions/documentDelivery.actions";
import { DocumentLocationAction } from "../../redux/actions/documentPacking.actions";
import { authenticateUser, validateToken } from "../../utils/Commons.js";

function PreparationHistory({ loadingScreen,
                              PreparationMovementSearchAction,
                              preparationMovementResponse,
                              DocumentLocationAction,
                              responseLocations}){

  const [currentPreparations, setCurrentPreparations] = useState([]);
  const [searcher, setSearcher] = useState('');
  const [initialLocation, setInitialLocation] = useState('');
  const [finalLocation, setFinalLocation] = useState('');
  const [filterDate, setFilterDate] = useState("");
  const [locations, setLocations] = useState([]);

  const { id } = useParams();

  const handleSearch = () => {
    let payload = {
      search: searcher,
      initial_location: initialLocation,
      final_location: finalLocation,
      date_filter: filterDate == "" ? filterDate : moment(filterDate).format('YYYY/MM/DD')
    }
    PreparationMovementSearchAction(payload)
  };

  const filterByLocation = (event, location) => {
    if(location == 'initial') { setInitialLocation(event) }
    else { setFinalLocation(event) }
  }

  const parsedStartDate = (date) => {
    setFilterDate(date)
  }

  useEffect(() => {
    DocumentLocationAction({ id })
  }, [])

  // If user is already login in, verify if the user has permission in the Packing module.
  // If user is not loged in, validates its token and if user has permission in the Packing module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Packing')
    }else{
      validateToken(localStorage.getItem("token"),'Packing')
    }
  })

  useEffect(() => {
    handleSearch()
  }, [initialLocation, finalLocation, filterDate])

  useEffect(() => {
    if (!preparationMovementResponse) return;

    setCurrentPreparations(preparationMovementResponse.data);
  }, [preparationMovementResponse]);

  useEffect(() => {
    if (!responseLocations) return;

    setLocations(['SIN FILTRO', ...responseLocations.attributes.locations]);
  }, [responseLocations]);

  return (
    <div>
      { loadingScreen ?
        <div className='loading-modal'>
          <div className='loading-modal-logo'>
            <figure>
              <img className='rotation-zoom' src={logo} alt="hbt logo" />
				    </figure>
          </div>
        </div>
        :
        null
      }
      <Navbar module="Packing" title="Packing" navBarName='Movimiento de preparaciones'/>

      <Container className="body-height">
        <div className='row'>
          <div className='col-12'>
            <div className="headData">
              <label className="title">Movimiento de Preparaciones</label>
            </div>
          </div>
        </div>

        {/* For Search and filters */}
        <div className="row mb-4">

          <div className="col-3">
            <label>
              Buscador
            </label>
            <div className="input-group">
              <div className="form-control">
                <input
                  className="inputSearch"
                  type="text"
                  placeholder="Número documento o RUT"
                  onKeyDown={(e) => (e.key === "Enter" ? handleSearch() : null)}
                  defaultValue={ searcher }
                  onChange={(e) => {
                    setSearcher(e.target.value);
                  }}
                />
                <input
                  type="image"
                  className="inputImgSearch mb-2"
                  src={magnifyingGlass}
                  border="0"
                  alt="Search"
                  onClick={() => handleSearch() }
                />
              </div>
            </div>
          </div>

          <div className="col-3">
            <label>
              Ubicación inicial
            </label>
            <select className="form-control w-100"
                    name="select-document_type"
                    id="select-document_type" 
                    onChange={(e) => { filterByLocation(e.target.value, 'initial'); }}>
              { locations.filter(item => item !== 'DEVOLUCIÓN').map((location) => <option key={location} value={location == 'SIN FILTRO' ? '' : location}>{location}</option>) }
            </select>
          </div>

          <div className="col-3">
            <label>
              Ubicación final
            </label>
            <select className="form-control w-100"
                    name="select-document_type"
                    id="select-document_type" 
                    onChange={(e) => { filterByLocation(e.target.value, 'last'); }}>
              { locations.map((location) => <option key={location} value={location == 'SIN FILTRO' ? '' : location}>{location}</option>) }
            </select>
          </div> 

          <div className='col-3'>
            <label>
              Fecha de movimiento
            </label>
            <DatePicker
              className="inputCalendar form-control"
              placeholderText="Filtro por fecha"
              selected={filterDate}
              dateFormat="yyyy-MM-dd"
              onChange={(date) => { parsedStartDate(date); }}
            />
          </div>

        </div>

        {/* Table */}
        <div className="row">
          <div className="col">
            <div className="listData">
              <table className="table">
                <thead>
                  <tr>
                    <td> Número Documento </td>
                    <td> Código bulto </td>
                    <td> Ubicación inicial </td>
                    <td> Ubicación final </td>
                    <td> Usuario </td>
                    <td> Fecha </td>
                  </tr>
                </thead>
                {currentPreparations
                  ? currentPreparations.map((item, index) => {
                    if(item === null) { return }
                    else {
                      return (
                        <tbody key={item + index}>
                          <tr>
                          <td> { item.attributes.doc_ref } </td>
                          <td> { item.attributes.doc_code } </td>
                          <td> { item.attributes.initial_location } </td>
                          <td> { item.attributes.final_location } </td>
                          <td> { item.attributes.user_name } </td>
                          <td> { moment(item.attributes.ocurred_at).format('DD/MM/YYYY - H:MM:SS') } </td>
                          </tr>
                        </tbody>
                      )
                    }
                  })
                : null}
              </table>
            </div>
          </div>
        </div> 

      </Container>
    </div>
  );
} 

const mapStateToProps = (state, props) => {
  return {
    preparationMovementResponse: state.documentDeliveryReducers.preparationMovementsResponse.res,
    responseLocations: state.documentPackingReducers.documentLocationResponse.res
  };
};

/*Export*/
export default connect(mapStateToProps, {
  PreparationMovementSearchAction,
  DocumentLocationAction
})(PreparationHistory);
