import Constants from "../../utils/Constants";

const documentState = {
  documentListResponse: [],
  pendingReceptionResponse: [],
  documentFilterResponse: [],
  documentPrioritizationResponse: [],
  syncStockResponse: [],
  takenDocumentCount: [],
  releaseTakenDocumentResponse: [],
  fetchDocumentPrioritizationResponse: false,
  fetchDocumentListResponse: false,
  fetchDocumentFilterResponse: false,
  fetchPendingReceptionResponse: false,
  fetchPendingReceptionPrioritizationResponse: false,
};

// Admin Document Reducer
// 
// Reducer to map the action response to a state
export default (state = documentState, action) => {
  switch (action.type) {
    // Pending Reception Document List
    case Constants.SuccessFetchPendingReceptionList:
      return { ...state, fetchPendingReceptionResponse: action.payload.fetch, pendingReceptionResponse: action.payload.data }
    case Constants.StartFetchPendingReceptionList:
      return { ...state, fetchPendingReceptionResponse: action.payload.fetch } 
    case Constants.StartFetchPendingReceptionPrioritization:
      return { ...state, fetchPendingReceptionPrioritizationResponse: action.payload.fetch }
    // Pending Document List
    case Constants.StartFetchDocumentList:
      return { ...state, fetchDocumentListResponse: action.payload.fetch }
    case Constants.SuccessFetchDocumentList:
      return {
        ...state,
        documentListResponse: action.payload.data,
        fetchDocumentListResponse: action.payload.fetch
      };



    case Constants.SuccessFetchSyncStockAction:
      return { ...state, syncStockResponse: action.payload};


    case Constants.FailFetchDocumentList:
      return {
        ...state,
        documentListResponse: action.payload.data,
        fetchDocumentListResponse: action.payload.fetch
      };

    // Filter documents
    case Constants.StartFetchDocumentFilter:
      return { ...state, fetchDocumentFilterResponse: action.payload.fetch };

    case Constants.SuccessFetchDocumentFilter:
      return {
        ...state,
        documentFilterResponse: action.payload.data,
        fetchDocumentFilterResponse: action.payload.fetch
      };

    case Constants.FailFetchDocumentFilter:
      return {
        ...state,
        documentFilterResponse: action.payload.data,
        fetchDocumentFilterResponse: action.payload.fetch
      };

    // Priorization of documents
    case Constants.StartFetchDocumentPrioritization:
      return { ...state, fetchDocumentPrioritizationResponse: action.payload.fetch }
    case Constants.SuccessFetchDocumentPrioritization:
      return {
        ...state,
        documentPrioritizationResponse: action.payload.data,
        fetchDocumentPrioritizationResponse: action.payload.fetch
      };
    case Constants.FailFetchDocumentPrioritization:
      return {
        ...state,
        documentPrioritizationResponse: action.payload.data,
        fetchDocumentPrioritizationResponse: action.payload.fetch
      };

    // Taken of documents
    case Constants.SuccessFetchTakenDocumentCountAction:
      return { ...state, takenDocumentCount: action.payload };
    case Constants.SuccessReleaseTakenDocumentAction:
      return { ...state, releaseTakenDocumentResponse: action.payload};
    case Constants.FailFetchReleaseTakenDocumentAction:
      return { ...state, releaseTakenDocumentAction: action.payload};
    
    default:
      return state;
  }
};
