import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DocumentSelectedToPickAction,
  DocumentCompletedAction,
} from "../../redux/actions/documentPicking.actions";

import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import Product from "../../components/Picking/Product";
import { SaveProductAction } from "../../redux/actions/productPicking.actions";
import Navbar from "../../components/navbar"

/*Component o Page*/
function DocumentDetail({
  SaveProductAction,
  DocumentSelectedToPickAction,
  DocumentCompletedAction,
  response,
  responseProduct,
}) {
  let history = useHistory();
  const { id } = useParams();
  const [detailDocument, setDetailDocument] = useState();
  const [count, setCount] = useState(0);
  const [finish, setFinish] = useState(false);

  useEffect(() => {
    DocumentSelectedToPickAction({ id });
  }, []);

  useEffect(() => {
    setDetailDocument(response);
  }, [response]);

  const onNext = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );

    if (!detailDocument) return;

    if (detailDocument.attributes.products.length !== 0) {
      const document_products_qantity =
        detailDocument.attributes.products.length - 1;

      if (document_products_qantity === 0) {
        setFinish(true);
      }
      if (count < document_products_qantity) {
        setCount(count + 1);
      }
      if (count === document_products_qantity - 1) {
        setFinish(true);
      }
    }
  };

  const onFinish = () => {
    setFinish(false);
    DocumentCompletedAction({ id });
    history.goBack();
  };
  const onBack = () => {
    history.goBack();
  };

  const toLogin = () => {
    localStorage.setItem("module", "Picking");
    history.push("/login");
  };

  
  return (
    <div>
      <Container>

        <Navbar title="Picking" module="Picking"/>
        <Row>
          <Col sm={12}>
            <table>
              {detailDocument && (
                <tbody>
                  <tr>
                    <td>
                      <button
                        className="buttonBack"
                        onClick={() => {
                          onBack();
                        }}
                      >
                        Volver
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Doc. Previo:
                    </td>
                    <td>{detailDocument.attributes.document_number}</td>
                  </tr>
                  <tr>
                    <td>
                      Línea: {count + 1}/{detailDocument.attributes.products.length}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {detailDocument ? (
              <Product
                {...detailDocument.attributes.products[count]}
                SaveProductAction={SaveProductAction}
                id={id}
                key={id}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col className="divCenter" sm={12}>
            <div className="divCenter">
              {" "}
              {finish ? (
                <button
                  className="buttonNext"
                  onClick={() => {
                    onFinish();
                  }}
                >
                  Siguiente Pedido
                </button>
              ) : (
                <button
                  className="buttonNext"
                  onClick={() => {
                    onNext();
                  }}
                >
                  Siguiente Producto
                </button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

/*Methodo*/
const mapStateToProps = (state, props) => {
  return {
    response: state.documentPickingReducers.documentSelectedToPickResponse.res,
    responseProduct: state.productReducers.productResponse.res,
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentSelectedToPickAction,
  DocumentCompletedAction,
  SaveProductAction,
})(DocumentDetail);
