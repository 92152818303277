import { call, put, takeEvery } from "redux-saga/effects";
import Constants from "../../utils/Constants";
import ProductService from "../../API/services/ProductService";
import DocumentService from "../../API/services/DocumentPackingService";

function* product(action) {
  try {
    const data = yield call(ProductService.product, action.payload);

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchProduct,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchProduct,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchProduct,
      payload: e.message,
    });
  }
}

function* saveProduct(action) {
  try {
    const data = yield call(ProductService.saveProduct, action.payload);

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchSaveProduct,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchSaveProduct,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchSaveProduct,
      payload: e.message,
    });
  }
}

function* savePacking(action) {
  try {
    const data = yield call(ProductService.savePacking, action.payload);

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchSavePackingAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchSavePackingAction,
      payload: data,
    });
    const document = yield call(DocumentService.documentToPack, {id: action.payload.id});
    yield put({
      type: Constants.SuccessFetchDocumentToPack,
      payload: document,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchSavePackingAction,
      payload: e.message,
    });
  }
}

function* editPacking(action) {
  try {
    const data = yield call(ProductService.editPacking, action.payload);
    console.log('Action Payload', action.payload)
    console.log('Data', data)
    if (data.res.errors) {
      return yield put({
        type: Constants.FailFetchEditPackingAction,
        payload: data.res.errors,
      });
    }
    yield put({
      type: Constants.SuccessFetchEditPackingAction,
      payload: data,
    });
    const document = yield call(DocumentService.documentToPack, {id: action.payload.id});
    yield put({
      type: Constants.SuccessFetchDocumentToPack,
      payload: document,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchEditPackingAction,
      payload: e.message,
    });
  }
}

function* removePackage(action){
  try{
    const data = yield call(ProductService.removePackage, action.payload);
    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchRemovePackageAction,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchRemovePackageAction,
      payload: data,
    });
    const document = yield call(DocumentService.documentToPack, {id: action.payload.id});
    yield put({
      type: Constants.SuccessFetchDocumentToPack,
      payload: document,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchRemovePackageAction,
      payload: e.message,
    });
  }
}

export function* productSaga() {
  yield takeEvery(Constants.productAction, product);
  yield takeEvery(Constants.saveProductAction, saveProduct);
  yield takeEvery(Constants.savePackingAction, savePacking);
  yield takeEvery(Constants.editPackingAction, editPacking);
  yield takeEvery(Constants.removePackageAction, removePackage);
}
