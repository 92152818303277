import { loginSaga } from "./login.sagas";
import { documentPickingSaga } from "./documentPicking.sagas";
import { productSaga } from "./product.sagas";
import { documentPackingSaga } from "./documentPacking.sagas";
import { documentDeliverySaga } from "./documentDelivery.sagas";
import { documentAdminSaga } from "./documentAdmin.sagas";
import { documentPackBuilderSaga } from "./documentPackBuilder.sagas";
export default [
  loginSaga,
  documentPickingSaga,
  productSaga,
  documentPackingSaga,
  documentDeliverySaga,
  documentAdminSaga,
  documentPackBuilderSaga,
];
