import Constants from "../../utils/Constants";

export const DocumentSelectedToPickAction = (payload) => {
  return {
    type: Constants.documentSelectedToPickAction,
    payload,
  };
};

export const DocumentPendingAction = (payload) => {
  return {
    type: Constants.documentPendingAction,
    payload,
  };
};

export const PendingDocumentSelectionAction = (payload) => {
  return {
    type: Constants.PendingDocumentSelectionAction,
    payload,
  };
};

export const DocumentCompletedAction = (payload) => {
  return {
    type: Constants.documentCompletedAction,
    payload,
  };
};

export const DocumentSelectedAction = (payload) => {
  return {
    type: Constants.documentSelectedAction,
    payload,
  };
};
