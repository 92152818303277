import React, { useEffect } from "react";
import { connect } from "react-redux";
import { LoginAction } from "../../redux/actions/login.actions";
import { useHistory } from "react-router-dom";
import { close } from "../../utils/Commons.js";


/*Component o Page*/
function Login({ loginResponse, loginStatus, LoginAction }) {

  let history = useHistory();

  useEffect(() => {

    if (loginResponse) {
      if (loginStatus === 200  && loginResponse.data) {
        const role = loginResponse.data.attributes.application_roles.filter( role => role.name == 'hbt-picking')[0].role[0]
        localStorage.setItem("token", loginResponse.data.id)
        localStorage.setItem("name", loginResponse.data.attributes.name)
        localStorage.setItem("email", loginResponse.data.attributes.email)
        localStorage.setItem('expiresAt', loginResponse.data.attributes.expires_at)
        localStorage.setItem('warehouse', loginResponse.data.attributes.warehouse)
        localStorage.setItem('role', role)
        if (!localStorage.getItem('module')){
          localStorage.setItem('module',"Admin");
        }
        toModule();
      }
    }
  }, [loginResponse]);
  
  // Callback for the login button
  // 
  // Read the params from the url
  // @params user_token, auth token from api
  // @params return_url, url to redirect to after login
  // Connects to the api with the token to retrieve user data
  // If the user is successfully logged in, redirect to the return_url
  // 
  // @returns [null]
  useEffect(() => {
    let params = window.location.href.split('?')
    if (params.length > 1) {
      let params_array_values = params[1].split('&')
      let user_token = params_array_values[0].split('=')[1];
      LoginAction({ "token": user_token })
    }})

  const toModule = () => {
    if (localStorage.getItem("module")) {
      switch (localStorage.getItem("module")) {
        case "Admin":
          history.push("/");
          break;
        case "Picking":
          history.push("/operator");
          break;

        case "Packing":
          history.push("/pendingPackingList");
          break;

        case "Delivery":
          history.push("/options");
          break;

        default:
          history.push("/");
      }
    }
  };

  return <div></div>;
}

/*Methodo*/
const mapStateToProps = (state, props) => {
  var status = 200;
  const loginResponse = state.loginReducers.LoginResponse.res;
  if (loginResponse && loginResponse.errors) {
    if(loginResponse.errors[0].title === "INVALID_CREDENTIALS")
      status = 404
    else
      status = 500
  }
  return {
    loginResponse: loginResponse,
    loginStatus: status,
  };
};

/*Export*/
export default connect(mapStateToProps, { LoginAction })(Login);
