export default {
  //--- login----
  login: "auth/token_auth",

  //-----admin
  DocumentList: "picking/pending_documents",
  pendingReceptionList: (document_type) => `entry_picking/pending_receptions?document_type=${document_type}`,
  TakenDocument: "picking/pending_documents/taken_documents",
  ReleaseTakenDocuments: "picking/pending_documents/release_taken_documents",
  unselectTakenDocuments: (id) =>
    `picking/pending_documents/${id}/unselect`,
  DocumentPrioritization: (id) =>
    `picking/pending_documents/${id}/change_priority`,
  PendingReceptionPrioritization: (id) =>
    `entry_picking/pending_receptions/${id}/change_priority`,  
  DocumentFilter: (startDate, endDate, filteredBy, deliveredBy) =>
    `picking/pending_documents?filter=${filteredBy}&delivery_type=${deliveredBy}&date_begin=${startDate}&date_end=${endDate}`, //for filtering in the administrator view
  DocumentCompleted: () => "delivering/order_preparations/completed",
  SyncStock: "picking/pending_documents/sync_stock",
  //--- picking ----

  DocumentPending: "picking/pending_documents/operator", //list of documents to pick (Operator)

  DocumentSelectedToPick: (id) => `picking/pending_documents/${id}`, // document  will be picked

  DocumentStatusCompleted: (id) =>
    `picking/pending_documents/${id}/complete_picking`, //for changing the document status from pickeado to completed

  DocumentStatusSelected: (id) =>
    `picking/pending_documents/${id}/select_document`, // document selected for the administrator to the operator

  SaveProduct: (id) => `picking/pending_documents/${id}/pick_product`, // save products and their stock according to the locations

  pendingDocumentSelected: (docId) =>
    `picking/pending_documents/${docId}/select`,

  //--packing------

  DocumentsPendingToPack: (warehouse) => `packing/order_preparations?warehouse=${warehouse}`, //document list for preparation(packing)
  DocumentToPack: (id) => `packing/order_preparations/${id}`, //document selected to pack
  AvailableLocations: () =>
    `packing/order_preparations/warehouse_locations`, // Get available Warehouses
  ChangePreparationLocation: (id) =>
    `packing/order_preparations/${id}/change_package_location`, // Save prepartion location for no package
  StorePreparationLocation: (id) =>
    `packing/order_preparations/${id}/pick_preparation_location`, // Save prepartion location for no package
  StorePackageLocation: (id) =>
    `packing/order_preparations/${id}/pick_package_location`,
  DocumentToDelivery: (id) =>
    `packing/order_preparations/${id}/complete_packing`, // document selected to deliver

  DocumentPackingList: (id) => `packing/order_preparations/${id}/packing_list`, // document selected to view its packing List
  DocumentPublicPackingList: (id) => `packing/order_preparations/${id}/public_packing_list`, // document selected to view its packing List
  SavePacking: (id) => `packing/order_preparations/${id}/add_product_package`, // save products packing
  EditPacking: (id) => `packing/order_preparations/${id}/edit_product_package`, // Move product from package
  RemovePackage: (id) => `packing/order_preparations/${id}/remove_package`,
  //----------------- delivery--------------------
  DocumentToDeliverySearch: (search) =>
    `delivering/order_preparations?search=${search}`, // searcher document to delivery, both for admin and operator
  PreparationTransferFilter: (search,filter,begin_date, end_date) =>
    `delivering/order_preparations?per_page=20&search=${search}&filter=${filter}&date_begin=${begin_date}&date_end=${end_date}`,
  DocumentToDeliveryFilter: (filter) =>
    `delivering/order_preparations?filter=${filter}`, // filter document to delivery by location
  DocumentToDeliverySearchAndFilter: (search,filter) =>
  `delivering/order_preparations?search=${search}&filter=${filter}`,
  documentDeliveryComplete: (id) =>
    `packing/order_preparations/${id}/complete_delivery`,
  //this options are not working yet-------
  DocumentPendingToLabel: "picking/pending_documents", // document list pending for labels

  DocumentToDeliveredWithNoLabels: (id) => `picking/pending_documents/${id}`, // document selected for the administrator to the operator

  DocumentLabel: (id) => `packing/order_preparations/${id}/labels`, // document selected for the administrator to the operator

  DocumentPendingToDelivery: "delivering/order_preparations?search=Provi", //document list pending for deliverying
  //-------------

  OperativeChargeReport: (email) => 
    `admin/applications/pending_documents/download_excel/?email=${email}`,
  
  PreparationMovements: (query, initial_location, final_location, date_filter) =>  
    `packing/preparation_movements?query=${query}&initial_location=${initial_location}&final_location=${final_location}&date_filter=${date_filter}`,

  packBuilderUploadList: (month) => `/picking/pack_uploads?month=${month}`,
  availableMonths: () => "picking/pack_uploads/available_months",
  packRequestsList: () => "picking/pack_requests",
  generatePackRequest: (id) => `/picking/pack_uploads/${id}/pack_request`,
  packBuilderFileUpload: "picking/pack_uploads/file_upload",
  packBuilderManualUpload: "/picking/pack_uploads/manual_upload",
  prioritizePackRequest: (id) => `/picking/pack_requests/${id}/pack_prioritize`,
  unselectPackRequest: (id, kind) => `/picking/pack_requests/${id}/unselect?kind=${kind}`,
  detailPackRequest: (id) => `/picking/pack_requests/${id}`
};
