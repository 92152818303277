import { call, put, takeEvery } from "redux-saga/effects";
import Constants from "./../../utils/Constants";
import LoginService from "./../../API/services/LoginService";

function* login(action) {
  try {
    const data = yield call(LoginService.login, action.payload);

    if (data.status !== 200) {
      return yield put({
        type: Constants.FailFetchLogin,
        payload: data,
      });
    }
    yield put({
      type: Constants.SuccessFetchLogin,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: Constants.FailFetchLogin,
      payload: e.message,
    });
  }
}

export function* loginSaga() {
  yield takeEvery(Constants.loginAction, login);
}
