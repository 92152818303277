import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DocumentPendingToPackAction,
  ChangeStatusToPackAction,
  ChangeStatusToDeliveryAction,
  DocumentsPendingToLabelAction,
  DocumentsPendingToDeliveryAction,
} from "../../redux/actions/documentPacking.actions";
import { DocumentPackingComplete, DocumentCompletedSearch } from "../../redux/actions/documentDelivery.actions";
import { Container, Row, Col } from "react-grid-system";
import magnifyingGlass from "../../img/search.svg";
import { useHistory } from "react-router-dom";
import logo from "../../img/logo.png";
import { getName, close, authenticateUser, validateToken } from "../../utils/Commons.js"
import Pagination from "@material-ui/lab/Pagination";
import Navbar from "../../components/navbar"
import moment from "moment";

/*Component o Page*/
function CompletedPackingList({
  DocumentPendingToPackAction,
  DocumentPackingComplete,
  response,
  ChangeStatusToPackAction,
  ChangeStatusToDeliveryAction,
  loadingScreen,
  DocumentCompletedSearch,
  DocumentsPendingToLabelAction,
  DocumentsPendingToDeliveryAction,
}) {
  const [documentId, setDocumentId] = useState("");
  const [selectedRow, setSelectedRow] = useState(-1);
  let history = useHistory();
  const [detailDocument, setDetailDocument] = useState([]);
  const [searcher, setSearcher] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);

  useEffect(() => {
    DocumentPackingComplete();
  }, []);

  // If user is already login in, verify if the user has permission in the Packing module.
  // If user is not loged in, validates its token and if user has permission in the Packing module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Packing')
    }else{
      validateToken(localStorage.getItem("token"),'Packing')
    }
  })

  useEffect(() => {
    if (!response) return;
    setDetailDocument(response.data);
    console.log(response)
    setMaxPages(+response.meta.max_pages)
  }, [response]);

  function handleClick(id, index) {
    setDocumentId(id);
    setSelectedRow(index);
  }

  const handleChange = (event, value) => {
    const data = {
      current_page: value
    };
    setCurrentPage(value)
    DocumentPackingComplete(data)
  }

  const toPack = (documentId) => {
    ChangeStatusToPackAction(documentId);
    history.push(`/documentPackingReadOnly/${documentId}`);
  };

  const toCompleted = () => {
    history.push(`/completedPackingList`);
  }

  const toPending = () => {
    history.push(`/pendingPackingList`)
  }

  const handleSearch = () => {
    const data = {
      search: searcher
    };
    DocumentCompletedSearch(data);
  };


  const toDelivery = (documentId) => {
    ChangeStatusToDeliveryAction(documentId);
    if (!detailDocument) return;
    setDetailDocument(
      detailDocument.filter((item) => item.attributes.id !== documentId)
    );
    setSelectedRow(-1);
  };

  const toLogin = () => {
    localStorage.setItem("module", "Packing");
    history.push("/login");
  };

  const parseDate = (value) => {
    return moment(value).format('YYYY/MM/DD HH:MM')
  }


  return (
    <div>
      {loadingScreen ?
        <div className='loading-modal'>
          <div className='loading-modal-logo'>
            <figure>
              <img className='rotation-zoom' src={logo} alt="hbt logo" />
				    </figure>
          </div>
        </div>
        :
        null
      }
      <Navbar module="Packing" title="Packing" navBarName= 'Preparaciones completadas'/>
      <Container className="body-height">
        <div className='row'>
          <div className='col-12'>
            <div className="headData">
              <label className="title">Preparaciones vigentes</label>
            </div>
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-12 text-center'>
            <button className="btn btn-outline-primary mr-4"
            onClick={() => {
              toPending();
            }}>
                Preparaciones Pendientes
              </button>

              <button
                className="btn btn-primary"
                onClick={() => {
                  toCompleted();
                }}> Preparaciones Completadas </button>
          </div>
        </div>
        
        
            <Row >
              <Col sm={4}>
              <div className="inputContainerSearch">
                  <input
                    className="inputSearch"
                    type="text"
                    placeholder="Buscar"
                    onKeyDown={(e) => (e.key === "Enter" ? handleSearch() : null)}
                    defaultValue={searcher}
                    onChange={(e) => {
                      setSearcher(e.target.value);
                    }}
                  />
                  <input
                    type="image"
                    className="inputImgSearch"
                    src={magnifyingGlass}
                    border="0"
                    alt="Search"
                    onClick={() => handleSearch()}
                  />
                </div>
              </Col>
              <Col sm={4} className="my-3">
                <Pagination count={maxPages} page={currentPage} onChange={handleChange} color="primary" className="justify-content-center"/>
              </Col>
              <Col sm={4} className='text-right'> 
                <button
                className="btn btn-primary col-3"
                disabled={!documentId}
                onClick={() => {
                  toPack(documentId);
                }}
              >
                Ver
              </button>
              </Col>
            </Row>
        <Row>
          <Col sm={12}>
            <div className="listData">
              <table className="table">
                <thead>
                  <tr>
                    <td>Nro.</td>
                    <td>Tipo Doc. Origen</td>
                    <td>Nro Doc. Origen</td>
                    <td>Cant. Líneas</td>
                    <td>Cant. Producto</td>
                    <td>Embultador</td>
                    <td>Fecha Bulto</td>
                    <td>Comuna</td>
                    <td>Tipo de Despacho</td>
                  </tr>
                </thead>
                {detailDocument
                  ? detailDocument.map((item, index) => {
                      return (
                        <tbody key={item + index}>
                          <tr
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() =>
                              handleClick(item.attributes.id, index)
                            }
                            className={
                              selectedRow === index ? "selectedTableRow" : ""
                            }
                          >
                            <td>{index + 1}</td>
                            <td>{item.attributes.document_type}</td>
                            <td>{item.attributes.document_number}</td>
                            <td>{item.attributes.total_lines}</td>
                            <td>{item.attributes.pending_products}</td>
                            <td>{item.attributes.packed_by}</td>
                            <td>{parseDate(item.attributes.packed_at)}</td>
                            <td>{item.attributes.comune}</td>
                            <td>{item.attributes.delivery_type}</td>
                          </tr>
                        </tbody>
                      );
                    })
                  : null}
              </table>
            </div>
          </Col>
        </Row>
        <div className='row mb-5'>
          <div className='col-12 text-right'>
            <button
                className="btn btn-primary col-3"
                disabled={!documentId}
                onClick={() => {
                  toPack(documentId);
                }}
              >
                Ver
              </button>

          </div>
        </div>
      </Container>
    </div>
  );
}

/*Methodo*/
const mapStateToProps = (state, props) => {
  return {
    response: state.documentDeliveryReducers.documentCompletedResponse.res,
    loadingScreen: state.documentDeliveryReducers.loadingScreen
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentPendingToPackAction,
  ChangeStatusToPackAction,
  DocumentPackingComplete,
  ChangeStatusToDeliveryAction,
  DocumentsPendingToLabelAction,
  DocumentsPendingToDeliveryAction,
  DocumentCompletedSearch,
})(CompletedPackingList);
