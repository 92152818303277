import React, { useEffect, useState } from "react";
import { Container } from "react-grid-system";
import { DocumentDeliveryCompleteAction } from "../../redux/actions/documentDelivery.actions";
import { DocumentToPackAction, DocumentPackingListAction } from "../../redux/actions/documentPacking.actions";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useLastLocation } from 'react-router-last-location'
import Navbar from "../../components/navbar"
import { getWarehouse, authenticateUser,validateToken } from "../../utils/Commons";
import { handlePrint } from "../../helpers/handlePrint";

function DocumentDetail({
  deliveryCompleteResponse,
  packingResponse,
  response,
  DocumentToPackAction,
  DocumentPackingListAction,
  DocumentDeliveryCompleteAction,
}) {
  
  const { id } = useParams();
  const [generalDetail, setgeneralDetail] = useState();
  const [detailDocument, setDetailDocument] = useState();
  const [total, setTotal] = useState([]);
  const [preparationState, setPreparationState] = useState('packed');
  let history = useHistory();
  let lastLocation = useLastLocation();

  useEffect(() => {
    DocumentPackingListAction({ id });
  }, []);

  // If user is already login in, verify if the user has permission in the Delivery module.
  // If user is not loged in, validates its token and if user has permission in the Delivery module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Delivery')
    }else{
      validateToken(localStorage.getItem("token"),'Delivery')
    }
  })

  useEffect(() => {
    if (!response || !response.attributes || !response.attributes.order_packages) {
      return;
    } else {
      setgeneralDetail(response.attributes);
      setDetailDocument(response.attributes.order_packages);
      setPreparationState(response.attributes.aasm_state);
    }
  }, [response]);

  useEffect(() => {
    if (!packingResponse || !packingResponse.attributes || !packingResponse.attributes.packages) {
      return;
    } else {
      setgeneralDetail(packingResponse.attributes);
      setTotal(packingResponse.attributes);
      setDetailDocument(packingResponse.attributes.packages);
    }
  }, [packingResponse]);
  
  useEffect(() => {
    if(preparationState === 'delivered' ){ history.push('/toDelivery')}
  }, [preparationState])

  useEffect(() => {
    if(!deliveryCompleteResponse || !deliveryCompleteResponse.attributes || (Number(id) !== deliveryCompleteResponse.attributes.id)) {return}
    else {
      setPreparationState(deliveryCompleteResponse.attributes.aasm_state)
    }
    
  }, [deliveryCompleteResponse])


  useEffect(() => {
    DocumentToPackAction({ id });
  }, []);

  const onBack = () => {
    const previousPath = lastLocation?.pathname + lastLocation?.search
    if (lastLocation?.pathname !== '/toDelivery' || !lastLocation?.search)  history.push(`/toDelivery`);
    history.push(previousPath)
  };

  const deliveryComplete = () => {
    DocumentDeliveryCompleteAction({id});
  };

  const deliveryPreparationButton = () => {
    return (
      <div className='col-12 col-md-3 offset-md-9'>
        <button className="btn btn-block btn-outline-primary" onClick={() => {deliveryComplete();}}>
          Entregar Preparación
        </button>
      </div>
    );
  };

  const packageProductTable = (pack) => {
    let tablePackage = <table className='table'>
      <thead>
        <tr>
          <th className="w-75">Código de Bulto</th>
          <th>Ubicación</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{pack.label_number}</td>
          <td>{pack.location}</td>
        </tr>
      </tbody>
    </table>
    let rows = pack.products.map((product) => {
      return(
        <tr>
          <td>{product.description}</td>
          <td>{product.amount}</td>
          
        </tr>
      )
    })
    let table = <table className="table">
    <thead>
      <tr>
        <th className="w-75">Producto</th>
        <th>Cantidad</th>
      </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
      </table>

      return (<div>
        <h2> Bulto {pack.package_number}</h2>
        {tablePackage}
        {table}
      </div>);
  };
  const OrderGeneralDetail = (details) => {
    let Detail = 
          <div className='row'style={{ margin: "20px" }}>
            <div className='col-6'>
              <div>Nro. De Documento: {total ? total.document_number : null}</div>
              <div>Tipo de Documento: {total ? total.document_type : null}</div>
              <div>Razón Social: {total ? total.business_reason : null}</div>
              <div>Tipo de Despacho: {total ? total.delivery_type : null} </div>
            </div>
            <div className='col-6'>
              <div>Nro. De Preparación: {total ? total.preparation_number : null}</div>
              <div>Tipo de Preparación: {total ? total.preparation_type : null}</div>
              <div>RUT: {total ? total.business_tax_ref : null}</div>
            </div>
          </div>
    return (<div>
      {Detail}
    </div>)
  };

  return (
    <div>
      <Navbar module="Delivery" title="Delivery" navBarName='Entrega'/>
      <Container className="body-height">
        <div className='hidden-print'>
            <div className='row py-4'>
              <div className='col-2'>
                <button className="btn btn-block btn-outline-primary" onClick={() => {onBack();}}>
                  Volver
                </button>
              </div>
              <div className="col-2">
                <button className="btn btn-block btn-primary"  onClick={(e) => {handlePrint(e)}}>
                  Imprimir
                </button>
              </div>
              <div className='col-4 text-center'>
                <label className="title">Entrega</label>
              </div>
              <div className='col-4'></div>
            </div>
            {generalDetail ? 
                  OrderGeneralDetail(generalDetail) : null}
            <div className="row mb-4">
              <div className='col-12'>
                {detailDocument
                  ? detailDocument.map((pack) => {
                    return (packageProductTable(pack));
                  }
                ) : null}
                  
                </div>
              {deliveryPreparationButton()}
            </div>
          </div>
          <div className="show-print printView">
            <div className="row">
              <div className="col-12">
                <div style={{ margin: "20px" }}>
                  <div>
                    Nro. De Documento: {total ? total.document_number : null}
                  </div>

                  <div>
                    Tipo de Documento: {total ? total.document_type : null}
                  </div>
                  <div>Razón Social: {total ? total.business_reason : null}</div>
                  <div>
                    Tipo de Despacho: {total ? total.delivery_type : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 divCenter">
                <div>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Bulto</td>
                        <td>Código de Barra</td>
                        <td>Código de Proveedor</td>
                        <td>Descripción</td>
                        <td>Cantidad</td>
                        <td>Peso</td>
                        <td>Volumen</td>
                      </tr>

                      {detailDocument
                        ? detailDocument.map((pack, index) => {
                            return pack.products.map((itemPack, indexPack) => {
                              return (
                                <tr key={indexPack}>
                                  <td>{pack.package_number}</td>
                                  <td>{itemPack.barcode}</td>
                                  <td>{itemPack.provider_code}</td>
                                  <td>{itemPack.description}</td>
                                  <td>{itemPack.amount}</td>
                                  <td>
                                    {parseFloat(itemPack.weight).toFixed(2)}
                                  </td>
                                  <td>
                                    {parseFloat(itemPack.volume).toFixed(2)}
                                  </td>
                                </tr>
                              );
                            });
                          })
                        : null}

                      {total
                        ? [total].map((item, indexTotal) => {
                            return (
                              <tr key={indexTotal}>
                                <td style={{ textAlign: "right" }} colSpan="4">
                                  TOTAL
                                </td>

                                <td>{item.total_products}</td>
                                <td>
                                  {parseFloat(item.total_weight).toFixed(2)}
                                </td>
                                <td>
                                  {parseFloat(item.total_volume).toFixed(2)}
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
            
          </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    response: state.documentPackingReducers.documentToPackResponse.res,
    packingResponse: state.documentPackingReducers.documentPackingListResponse.res,
    deliveryCompleteResponse: state.documentPackingReducers.documentDeliveryCompleteResponse.res
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentToPackAction,
  DocumentDeliveryCompleteAction,
  DocumentPackingListAction,
})(DocumentDetail);
