import Constants from "../../utils/Constants";

const documentState = {
  documentToDeliverySearchResponse: [],
  documentDeliveryCompleteResponse: [],
  documentCompletedResponse: [],
  loadingScreen: false,
  preparationMovementsResponse: [],
};

export default (state = documentState, action) => {
  switch (action.type) {
    // document list pending for preparing
    case Constants.showLoadingScreen:
      return { ...state, loadingScreen: action.payload };
    case Constants.SuccessFetchDocumentToDeliverySearchAction:
      return { ...state, documentToDeliverySearchResponse: action.payload };
    
    case Constants.SuccessFetchPreparationFilter:
      return { ...state, documentToDeliverySearchResponse: action.payload }
    
    case Constants.FailFetchPreparationFilter:
      return { ...state, documentToDeliverySearchResponse: action.payload }
      
    case Constants.SuccessFetchPreparationMovement:
      return { ...state, preparationMovementsResponse: action.payload };

    case Constants.SuccessFetchDocumentCompleted:
      return { ...state, documentCompletedResponse: action.payload };  

    case Constants.FailFetchDocumentToDeliverySearchAction:
      return { ...state, documentToDeliverySearchResponse: action.payload };

    case Constants.SuccessFetchDocumentDeliveryComplete:
      return { ...state, documentDeliveryCompleteResponse: action.payload };

    case Constants.FailFetchDocumentDeliveryComplete:
      return { ...state, documentDeliveryCompleteResponse: action.payload };
      
    default:
      return state;
  }
};
