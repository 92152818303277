/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import { DocumentToDeliverySearchAction } from "../../redux/actions/documentDelivery.actions";
import { DocumentLocationAction } from "../../redux/actions/documentPacking.actions";
import { connect } from "react-redux";
import { getParameterByName } from '../../helpers/getParamsUrl'
import magnifyingGlass from "../../img/search.svg";
import Moment from "moment";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Navbar from "../../components/navbar"
import Pagination from "@material-ui/lab/Pagination";
import { getWarehouse, authenticateUser, validateToken } from "../../utils/Commons"
import logo from "../../img/logo.png";
import { ToastContainer } from 'react-toastify';
import { useIdleTimer } from 'react-idle-timer'

function DocumentsPendingToDelivery({
  response,
  responseLocations,
  DocumentLocationAction,
  DocumentToDeliverySearchAction,
  loadingResponse,
  loadingResponseLocations
}) {
  const [searcher, setSearcher] = useState("");
  const [locations, setLocations] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [locationFilter, setLocationFilter] = useState("");
  const [maxPages, setMaxPages] = useState(1);
  const [deliveryDocuments, setDeliveryDocuments] = useState([])

  const { id } = useParams();
  let history = useHistory();
  let locationUrl = useLocation();

  useEffect(() => {
    console.log(locations);
    const page = getParameterByName('current_page', locationUrl.search)
    const search = getParameterByName('search', locationUrl.search)
    const location = getParameterByName('location', locationUrl.search)
    if (page) setCurrentPage(+page);
    if (search) setSearcher(search);
    if (location) setLocationFilter(location);
    DocumentToDeliverySearchAction({ search: searcher, filter: locationFilter, current_page: currentPage });
  }, [currentPage, searcher, locationFilter]);

  useEffect(() => {
    if (!response) return;
    setDeliveryDocuments(response.data);
    if (!response.meta) return;
    setMaxPages(+response.meta.max_pages)
  }, [response]);

  useEffect(() => {
    DocumentLocationAction({ id });
  }, [""]);

  // If user is already login in, verify if the user has permission in the Admin module.
  // If user is not loged in, validates its token and if user has permission in the Admin module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Admin')
    }else{
      validateToken(localStorage.getItem("token"),'Admin')
    }
  })

  useEffect(() => {
    if (!responseLocations || !responseLocations.attributes || !responseLocations.attributes.locations) {
      return;
    } else {
      setLocations(responseLocations.attributes.locations);
    }
  }, [responseLocations]);

  const handleChange = () => {
    const searchInput = document.querySelector('#search_input').value
    setSearcher(searchInput)
    setCurrentPage(1)
    history.push(`/toDelivery?search=${searchInput}&filter=${locationFilter}&current_page=1`);
  };

  const pageChange = (event, value) => {
    setCurrentPage(value)
    history.push(`/toDelivery?search=${searcher}&filter=${locationFilter}&current_page=${value}`);
  }

  const changeLocation = (event) => {
    setLocationFilter(event.target.value)
    const searchInput = document.querySelector('#search_input').value
    setSearcher(searchInput)
    setCurrentPage(1)
    history.push(`/toDelivery?search=${searchInput}&filter=${event.target.value}&current_page=1`);
  };

  const toDocument = (id) => {
    history.push(`/documentDelivery/${id}`);
  };

  const toAdmin = () => {
    history.push("/");
  };

  const toPreparationMovements = () => {
    history.push("/preparationMovements");
  };

  const handleOnIdle = event => {
    localStorage.clear();
    window.location.reload();
  }

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500
  })

  return (
    <div>
      <ToastContainer />
      {loadingResponse || loadingResponseLocations ?
        <div className='loading-modal'>
          <div className='loading-modal-logo'>
            <figure>
              <img className='rotation-zoom' src={logo} alt="hbt logo" />
            </figure>
          </div>
        </div>
        :
        null
      }
      <Navbar module="Admin" title="Admin" navBarName='Preparaciones sin entregar'/>
      <Container className="body-height">
        <div className='row pt-4'>
          <div className='col-4'></div>
          <div className='col-4 text-center'>
            <label className="title text-center">Preparaciones sin entregar </label><br />
            <label className="subtitle">Bodega {getWarehouse()}</label>
          </div>
          <div className='col-4'>
          </div>
          <div className='col-4 offset-md-8'>
          </div>
        </div>
        <Row>
          <Col sm={12}>
            <div className="searcherAdminContainer">
              <input
                className="searcherAdminInput"
                type="text"
                id="search_input"
                placeholder="Buscar  por nombre, rut, comuna, número de documento"
                onKeyDown={(e) => (e.key === "Enter" ? handleChange() : null)}
              />
              <input
                type="image"
                className="searcherAdminInputImg"
                src={magnifyingGlass}
                border="0"
                alt="Search"
                onClick={() => handleChange()}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <div className='col pr-1 pl-1'>
              <label className='mr-2'>Filtro por Ubicación</label>
              <select
                className="select"
                name="select-delivery_type"
                id="select-delivery_type"
                onChange={(e) => {
                  changeLocation(e)
                }}
              >
                <option value={''}>Seleccione una ubicación...</option>
                {locations ? (locations.map((location) => {
                  const option = <option value={location}>{location}</option>
                  return option;
                })) : null}
              </select>
            </div>
          </Col>
          <Col sm={4}>
            <Pagination count={maxPages} page={currentPage} onChange={pageChange} color="primary" />
          </Col>
        </Row>
        <div className="listData">
          <table className="tableList">
            <thead>
              <tr>
                <td>Ítem</td>
                <td>Nro. Documento Origen</td>
                <td>Nro. De Preparación</td>
                <td>Ubicación De Preparación</td>
                <td>Nombre Cliente</td>
                <td>Fecha</td>
                <td>Destino</td>
                <td>Tipo de Despacho</td>
                <td>Bulto</td>
              </tr>
            </thead>
            {deliveryDocuments && Number(deliveryDocuments.length > 0) ? (
              deliveryDocuments.map((item, index) => {
                return (
                  <tbody key={item + index}>
                    <tr key={index} onClick={() => toDocument(item.attributes.id)}>
                      <td>{(currentPage - 1) * 25 + index + 1}</td>
                      <td>{item.attributes.document_number}</td>
                      <td>{item.attributes.preparation_number}</td>
                      <td>{item.attributes.warehouse_location}</td>
                      <td>{item.attributes.customer_name}</td>
                      <td>{Moment(item.attributes.date).format("DD-MM-yyyy")}</td>
                      <td>{item.attributes.comune}</td>
                      <td>{item.attributes.delivery_type}</td>
                      <td>
                        {item.attributes.order_packages === "yes" ? "S" : "N"}
                      </td>
                    </tr>
                  </tbody>
                );
              })
            ) : null}
          </table>
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    response: state.documentDeliveryReducers.documentToDeliverySearchResponse.res,
    responseLocations: state.documentPackingReducers.documentLocationResponse.res,
    loadingResponse: state.documentDeliveryReducers.loadingScreen,
    loadingResponseLocations: state.documentPackingReducers.loadingScreen
  };
};

/*Export*/
export default connect(mapStateToProps, {
  DocumentToDeliverySearchAction,
  DocumentLocationAction,
})(DocumentsPendingToDelivery);
